import apiClient from "./apiClient";
import { sortProductImages } from "utils/utils";

export const getFavoriteProducts = async () => {
  let [data, validations, error, meta] = await apiClient.get(
    "/favorite-products"
  );
  if (data) {
    data = data.map((product) => {
      return sortProductImages(product);
    });
  }
  return [data, validations, error, meta];
};

export const removeFavouriteProduct = async (id) => {
  const response = await apiClient.delete("/favorite-products/" + id);
  return response;
};

export const addFavouriteProduct = async (id) => {
  const response = await apiClient.post("/favorite-products", {
    id_product: id,
  });
  return response;
};
