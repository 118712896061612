import { getProductDetails } from "clients/products";
import AppLayout from "components/layout/AppLayout";
import ProductDetailsHeader from "components/product-details-page/ProductDetailsHeader";
import Error404 from "pages/404";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Tabs,
  Tab,
  styled,
} from "@mui/material";
import ProductDetailsImageSlider from "components/product-details-page/ProductDetailsImageSlider";
import ProductDetailsEquipment from "components/product-details-page/ProductDetailsEquipment";
import ProductDetails from "components/product-details-page/ProductDetails";
import { getPageContent } from "clients/cms";
import OrderProductButton from "components/product-details-page/OrderProductButton";
import FlexBox from "components/FlexBox";
import ProductDetailsHeaderSkeleton from "components/product-details-page/skeletons/ProductDetailsHeaderSkeleton";
import ProductDetailsImageSliderSkeleton from "components/product-details-page/skeletons/ProductDetailsImageSliderSkeleton";
import ProductModelSpecification from "components/product-details-page/product-model-specification/ProductModelSpecification";
import ProductVehicleHistory from "components/product-details-page/ProductVehicleHistory";
import ProductInspection from "components/product-details-page/ProductInspection";
import PublicInspectionPreview from "components/public-inspection-preview/PublicInspectionPreview";
import OrderProductDialog from "components/product-details-page/OrderProductDialog";
import { useAppContext } from "contexts/app/AppContext";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginTop: 80,
  marginBottom: 24,
  minHeight: 0,
  "& .inner-tab": {
    fontWeight: 600,
    minHeight: 40,
    textTransform: "capitalize",
  },
}));

const ProductDetailPage = () => {
  const [productData, setProductData] = useState();
  const [pageContent, setPageContent] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [orderProductDialogOpen, setOrderProductDialogOpen] = useState(false);

  const { slug } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { state } = useAppContext();
  const { user } = state.user;

  useEffect(() => {
    getProductDetails(slug).then((response) => {
      const [data] = response;
      setProductData(data || new Error("404"));
    });
    getPageContent("product-details").then((response) => {
      const [data] = response;
      setPageContent(data);
    });
  }, [slug]);

  if (productData && productData instanceof Error) {
    return <Error404 />;
  }

  if (!productData || !pageContent) {
    return (
      <AppLayout navbar={null}>
        <Container
          maxWidth="md"
          sx={{
            my: "2rem",
          }}
        >
          <ProductDetailsHeaderSkeleton />
          <ProductDetailsImageSliderSkeleton />
        </Container>
      </AppLayout>
    );
  }

  return (
    <AppLayout navbar={null}>
      <Container
        maxWidth="md"
        sx={{
          my: "2rem",
        }}
      >
        <ProductDetailsHeader
          product={productData}
          setDialogOpen={() => {
            setOrderProductDialogOpen(true);
          }}
        />
        <ProductDetailsImageSlider product={productData} />
        <ProductDetails product={productData} pageContent={pageContent} />
        <ProductDetailsEquipment product={productData} />
        <StyledTabs
          variant="scrollable"
          value={selectedTab}
          onChange={(_, newValue) => {
            setSelectedTab(newValue);
          }}
        >
          <Tab className="inner-tab" label="Specyfikacja Modelu" />
          <Tab className="inner-tab" label="Historia Pojazdu" />
          <Tab className="inner-tab" label="Twoja Inspekcja" />
          <Tab
            className="inner-tab"
            label="Opublikowana Inspekcja"
            disabled={!productData.public_inspection}
          />
        </StyledTabs>

        {selectedTab === 0 && (
          <ProductModelSpecification product={productData} />
        )}
        {selectedTab === 1 && <ProductVehicleHistory slug={productData.slug} />}
        {selectedTab === 2 && (
          <ProductInspection product={productData} pageContent={pageContent} />
        )}
        {selectedTab === 3 && (
          <PublicInspectionPreview inspection={productData.public_inspection} />
        )}

        {isMobile && (
          <Box
            sx={{
              marginTop: 2,
              display: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <FlexBox
              sx={{
                width: "100%",
                flexDirection: "column",
              }}
            >
              <OrderProductButton
                product={productData}
                setDialogOpen={() => {
                  setOrderProductDialogOpen(true);
                }}
              />
            </FlexBox>
          </Box>
        )}
      </Container>
      {!!user && orderProductDialogOpen && (
        <OrderProductDialog
          open={orderProductDialogOpen}
          onClose={() => setOrderProductDialogOpen(false)}
          product={productData}
        />
      )}
    </AppLayout>
  );
};
export default ProductDetailPage;
