import AppStore from "components/AppStore";
import BazarIconButton from "components/BazarIconButton";
import Facebook from "components/icons/Facebook";
import Google from "components/icons/Google";
import Instagram from "components/icons/Instagram";
import Twitter from "components/icons/Twitter";
import Youtube from "components/icons/Youtube";
import { Paragraph, H1 } from "components/Typography";
import { Box, Container, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import FlexBox from "../FlexBox"; // styled component
import { getNavigationElements } from "components/about/Routes";
import { contact_mail, contact_phone } from "utils/constants";

/*global FB*/

const StyledLink = styled("a")(({ theme }) => ({
  position: "relative",
  display: "block",
  padding: "0.3rem 0rem",
  color: theme.palette.grey[500],
  cursor: "pointer",
  borderRadius: 4,
  "&:hover": {
    color: theme.palette.grey[100],
  },
}));

const Footer = () => {
  const aboutLinks = getNavigationElements();

  const MessengerRef = useRef();

  useEffect(() => {
    MessengerRef.current.setAttribute("page_id", "102458102652197");
    MessengerRef.current.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: "v15.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"; //https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js'
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <footer>
      <Box bgcolor="#0B3900">
        <Container
          sx={{
            p: "1rem",
            color: "white",
          }}
        >
          <Box pt={10} pb={1} overflow="hidden">
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Link to="/">
                  {/*<Image mb={2.5} src="/assets/images/logo.svg" alt="logo" />*/}
                  Carteon
                </Link>

                <Paragraph mb={2.5} color="white" sx={{ textAlign: "justify" }}>
                  Carteon to Full-service marketplace oparty na technologii
                  sztucznej inteligencji AITrueValue. Platforma została
                  stworzona dla międzynarodowego rynku bussines-to-business.
                  Naszym celem jest zwiększenie dystrybucji samochodów
                  hybrydowych i elektrycznych poprzez usprawnienie procesu
                  handlowego. Dążymy do większej popularyzacji ekologicznych
                  pojazdów elektrycznych i hybrydowych wśród przedsiębiorców.
                </Paragraph>

                <AppStore />
              </Grid>

              <Grid item lg={2} md={6} sm={6} xs={12}></Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box
                  fontSize="25px"
                  fontWeight="600"
                  mb={2.5}
                  lineHeight="1"
                  color="white"
                >
                  Informacje
                </Box>

                <div>
                  {aboutLinks.map((item, ind) => (
                    <StyledLink href={item.href} key={ind}>
                      {item.display_name}
                    </StyledLink>
                  ))}
                </div>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box
                  fontSize="25px"
                  fontWeight="600"
                  mb={2.5}
                  lineHeight="1"
                  color="white"
                >
                  Kontakt z nami
                </Box>
                <Box py={0.6} color="white">
                  ul. Prezydenta Gabriela Narutowicza 57 / 8, <br />
                  20-016 Lublin, Polska
                </Box>
                <Link to={`mailto:${contact_mail}`}>
                  <Box sx={{ cursor: "pointer" }} py={0.6} color="white">
                    Email: {contact_mail}
                  </Box>
                </Link>
                <Link to={`tel:${contact_phone.replace(" ", "")}`}>
                  <Box sx={{ cursor: "pointer" }} py={0.6} mb={2} color="white">
                    Telefon: {contact_phone}
                  </Box>
                </Link>

                <div id="fb-root"></div>
                <div
                  ref={MessengerRef}
                  id="fb-customer-chat"
                  className="fb-customerchat"
                ></div>
                {/* <FlexBox className="flex" mx={-0.625}>
                  {iconList.map((item, ind) => (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopenner"
                      key={ind}
                    >
                      <BazarIconButton
                        m={0.5}
                        bgcolor="rgba(0,0,0,0.2)"
                        fontSize="12px"
                        padding="10px"
                      >
                        <item.icon fontSize="inherit" />
                      </BazarIconButton>
                    </a>
                  ))}
                </FlexBox> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "64px",
        }}
      >
        <img
          style={{
            maxHeight: "150px",
            maxWidth: "80%",
          }}
          src="/assets/images/program_logos.jpeg"
          alt="program logos"
        />
      </Box>
    </footer>
  );
};
// const iconList = [
//   {
//     icon: Facebook,
//     url: "",
//   },
//   {
//     icon: Twitter,
//     url: "",
//   },
//   {
//     icon: Youtube,
//     url: "",
//   },
//   {
//     icon: Google,
//     url: "",
//   },
//   {
//     icon: Instagram,
//     url: "",
//   },
// ];
export default Footer;
