import { Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import { useAppContext } from "contexts/app/AppContext";
import { Fragment } from "react";
import OfferCard from "./OfferCard";

const OffersLayout = ({ offers, setOffers }) => {
  const { state } = useAppContext();
  const { user } = state;

  const offers_recieved = offers.filter(
    (offer) => offer.fk_seller === user.user.id
  );
  const offers_sent = offers.filter((offer) => offer.fk_buyer === user.user.id);

  return (
    <Fragment>
      {!!offers_recieved.length && (
        <Fragment>
          <Typography variant="h5" fontWeight={500}>
            Oferty Otrzymane
          </Typography>

          <FlexBox flexDirection="column" gap={1} mt={2}>
            {offers_recieved.map((offer) => (
              <OfferCard
                key={offer.id.toString()}
                offer={offer}
                showAcceptDecineButtons
                setOffers={setOffers}
              />
            ))}
          </FlexBox>
        </Fragment>
      )}
      {!!offers_sent.length && (
        <Fragment>
          <Typography sx={{ marginTop: 2 }} variant="h5" fontWeight={500}>
            Oferty Wysłane
          </Typography>
          <FlexBox flexDirection="column" gap={1} mt={2}>
            {offers_sent.map((offer) => (
              <OfferCard
                key={offer.id.toString()}
                offer={offer}
                setOffers={setOffers}
              />
            ))}
          </FlexBox>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OffersLayout;
