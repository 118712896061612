import Close from "@mui/icons-material/Close";
import { Dialog, IconButton, Tooltip, Button, Box } from "@mui/material";
import { unpublishProduct } from "clients/products";
import { Fragment, useState } from "react";
import FlexBox from "components/FlexBox";
import DeleteDialog from "./DeleteDialog";

const ProductSettingsDialog = ({ open, onClose, product, setProduct }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <Fragment>
      <Dialog open={open} fullWidth>
        <Box
          sx={{ padding: 1, display: "flex", flexDirection: "column", gap: 2 }}
        >
          <FlexBox>
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </FlexBox>
          <Tooltip
            title={product.is_publish ? "" : "Ogłoszenie nie jest opublikowane"}
          >
            <span>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                disabled={!product.is_publish}
                onClick={async () => {
                  let response = await unpublishProduct(product.id);
                  const [data] = response;
                  setProduct((product) => ({ ...product, ...data }));
                }}
              >
                Deaktywacja Oferty
              </Button>
            </span>
          </Tooltip>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={() => {
              setDeleteDialogOpen(true);
            }}
          >
            Usuń ofertę
          </Button>
        </Box>
      </Dialog>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        productId={product.id}
      />
    </Fragment>
  );
};
export default ProductSettingsDialog;
