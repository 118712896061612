import apiClient from "./apiClient";

export const getAvilableInspectors = async (date, product_id) => {
  return await apiClient.get(`/available-inspectors/${product_id}/${date}`);
};

export const orderInspection = async (data) => {
  return await apiClient.post("inspections", data);
};

export const getInspectionDetails = async (id) => {
  return await apiClient.get(`/inspections/${id}`);
};

export const getInspections = async () => {
  return await apiClient.get("/inspections");
};

export const determineInspectionStatus = (status) => {
  switch (status) {
    case 0: {
      return "Oczekujące na zatwierdzenie";
    }
    case 1: {
      return "Odrzucona";
    }
    case 2: {
      return "Zaakceptowana";
    }
    case 3: {
      return "Opłacona";
    }
    case 4: {
      return "Rozpoczęta";
    }
    case 5: {
      return "Ukończona";
    }
    default: {
      return "Błąd";
    }
  }
};

export const determineInspectionStatusColor = (status) => {
  switch (status) {
    case 0: {
      return "secondary.100";
    }
    case 1: {
      return "error.200";
    }
    case 2: {
      return "secondary.200";
    }
    case 3: {
      return "secondary.200";
    }
    case 4: {
      return "secondary.200";
    }
    case 5: {
      return "success.200";
    }
    default: {
      return "unset";
    }
  }
};

export const INSPECTION_WAITING = 0;
export const INSPECTION_REFUSED = 1;
export const INSPECTION_ACCEPTED = 2;
export const INSPECTION_PAID = 3;
export const INSPECTION_IN_PROGRESS = 4;
export const INSPECTION_FINISHED = 5;
