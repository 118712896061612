import { Box, Card, Grid, Typography } from "@mui/material";
import { H5 } from "components/Typography";
import FlexBox from "../FlexBox";

const CompanyDetails = ({ company }) => {
  return (
    <Box mb={4}>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card
            sx={{
              display: "flex",
              p: "14px 32px",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Box ml={1.5} flex="1 1 0">
              <FlexBox
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>
                  <H5 my="0px">{company.name} </H5>
                  <FlexBox alignItems="center">
                    <Typography ml={0.5} color="primary.main">
                      {company.company_phone_number}
                    </Typography>
                  </FlexBox>
                </div>
                <Typography ml={0.5} color="primary.main">
                  VAT: {company.vat_number}
                  <br />
                  REGON: {company.regon}
                </Typography>
              </FlexBox>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CompanyDetails;
