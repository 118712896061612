/* eslint-disable */
import { Fragment, useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FullscreenSingleImageViewer from "./SingleImageViewer";
import VideoViewer from "./VideoViewer";

const HandleQuestionType = (question) => {
  switch (question.step_type) {
    case "single-choice": {
      const [imageViewerOpen, setImageViewerOpen] = useState(false);
      const image = Object.keys(question.result).find((key) =>
        key.includes("image_for")
      );
      return (
        <Fragment>
          <TableCell component="th" scope="row">
            {question.name}
          </TableCell>
          <TableCell align="left" sx={{ display: "flex" }}>
            <Typography fontWeight="bold">
              {question.result[question.steps.field]}
            </Typography>
            {!!image && (
              <IconButton
                onClick={() => {
                  setImageViewerOpen(true);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            )}
          </TableCell>
          {!!image && (
            <FullscreenSingleImageViewer
              open={imageViewerOpen}
              onClose={() => {
                setImageViewerOpen(false);
              }}
              image={[question.result[image]]}
            />
          )}
        </Fragment>
      );
    }
    case "multi-choice": {
      return (
        <Fragment>
          <TableCell component="th" scope="row">
            {question.name}
          </TableCell>
          <TableCell align="left">
            <Typography fontWeight="bold">
              {question.result[question.steps.field].map((elem) => `${elem}, `)}
            </Typography>
          </TableCell>
        </Fragment>
      );
    }
    case "input": {
      return (
        <Fragment>
          <TableCell component="th" scope="row">
            {question.name}
          </TableCell>
          <TableCell align="left">
            {Object.entries(question.result).map(
              ([key, val]) =>
                question.steps.answers.find(
                  (answer) => answer.field == key
                ) && (
                  <Box key={key} sx={{ display: "flex" }}>
                    <Typography fontWeight="bold">
                      {
                        question.steps.answers.find(
                          (answer) => answer.field == key
                        ).label
                      }
                      :
                    </Typography>
                    <Typography ml={1}>{val}</Typography>
                  </Box>
                )
            )}
          </TableCell>
        </Fragment>
      );
    }
    case "image": {
      const [imageViewerOpen, setImageViewerOpen] = useState(false);
      const image = question.result[question.steps.field];
      return (
        <Fragment>
          <TableCell component="th" scope="row">
            {question.name}
          </TableCell>
          <TableCell align="left" sx={{ display: "flex" }}>
            {!!image && (
              <Fragment>
                <Typography fontWeight="bold">podgląd</Typography>
                <IconButton
                  onClick={() => {
                    setImageViewerOpen(true);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Fragment>
            )}
          </TableCell>
          {!!image && (
            <FullscreenSingleImageViewer
              open={imageViewerOpen}
              onClose={() => {
                setImageViewerOpen(false);
              }}
              image={image}
            />
          )}
        </Fragment>
      );
    }
    case "video": {
      const [videoViewerOpen, setVideoViewerOpen] = useState(false);
      const video = question.result[question.steps.field];
      return (
        <Fragment>
          <TableCell component="th" scope="row">
            {question.name}
          </TableCell>
          <TableCell align="left" sx={{ display: "flex" }}>
            {!!video && (
              <Fragment>
                <Typography fontWeight="bold">podgląd</Typography>
                <IconButton
                  onClick={() => {
                    setVideoViewerOpen(true);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Fragment>
            )}
          </TableCell>
          {!!video && (
            <VideoViewer
              open={videoViewerOpen}
              onClose={() => {
                setVideoViewerOpen(false);
              }}
              video={video}
            />
          )}
        </Fragment>
      );
    }
    default: {
      return "Błąd";
    }
  }
};

const QuestionHandler = ({ question }) => {
  return (
    <Fragment>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "th, td": { verticalAlign: "top" },
          button: { padding: 0, marginLeft: "5px" },
        }}
      >
        {HandleQuestionType(question)}
      </TableRow>
    </Fragment>
  );
};
export default QuestionHandler;
