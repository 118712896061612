import { Fragment } from "react";
import { TableRow, TableCell, Skeleton } from "@mui/material";

const ProductListSkeleton = () => {
  return (
    <Fragment>
      {[1, 2, 3].map((elem) => (
        <TableRow key={elem.toString()}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};
export default ProductListSkeleton;
