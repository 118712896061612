import apiClient from "./apiClient";

// returns id_customer and id_company from api call
export const getVerificationCustomer = async (token) => {
  let data = { token: token };
  let response = await apiClient.post(
    "/email-verification-customers",
    data //JSON.stringify(data)
  );
  if (response[0]) {
    return [
      response[0].id,
      response[0].attributes.fk_company,
      response[0].attributes.claim,
    ];
  }
  throw new Error("Token jest nieważny, lub wygasł");
};

// sends b64encoded file
export const sendVerificationFile = async (
  id_customer,
  id_company,
  b64file
) => {
  let data = {
    id_customer: id_customer,
    id_company: id_company,
    base64_document: b64file,
  };
  return await apiClient.post("/register-documents", data); //JSON.stringify(data));
};

export const sendInspectorPrices = async (values) => {
  return await apiClient.post(
    "/email-verification-inspectors",
    values //JSON.stringify(values)
  );
};
