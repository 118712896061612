/* eslint-disable react-hooks/exhaustive-deps */
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Card,
  MenuItem,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { debounce } from "@mui/material/utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getSearchSuggestions } from "clients/search-suggestions";
import { useNavigate } from "react-router-dom";

// also used in the GrocerySearchBox component
export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: 6,
}));

// also used in the GrocerySearchBox component
export const SearchResultCard = styled(Card)(() => ({
  position: "absolute",
  top: "100%",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  width: "100%",
  zIndex: 99,
}));

const SearchBox = () => {
  const [resultList, setResultList] = useState([]);
  const [searchString, setSearchString] = useState();
  const [showResults, setShowResults] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const parentRef = useRef();
  const navigate = useNavigate();

  const handleLinkClick = (url) => {
    navigate(url);
    setShowResults(false);
  };
  // on search phrase change re run search
  useEffect(() => {
    if (searchString) {
      setResultList([]);
      runSearch();
    }
  }, [searchString]);

  // make a search suggestion request
  const runSearch = () => {
    setIsFetching(true);
    let query = {};
    query["search"] = searchString;
    getSearchSuggestions(query)
      .then((response) => {
        if (response[0] !== null) {
          // build suggestions element
          let suggestions = response[0].attributes.products.map((elem) => ({
            type: "",
            name: `${elem.relationships?.model.relationships?.producer.attributes.name} ${elem.relationships?.model.attributes.name}`,
            slug: elem.attributes.slug,
            href: `/car/${elem.attributes.slug}`,
            price: elem.attributes.price,
          }));

          suggestions = [
            ...suggestions,
            ...response[0].attributes.producers.map((elem) => ({
              type: "Filtruj producent: ",
              name: elem.attributes.name,
              slug: elem.attributes.slug,
              href: `/cars?${new URLSearchParams({
                filter_producer: elem.attributes.slug,
              })}`,
            })),
          ];
          suggestions = [
            ...suggestions,
            ...response[0].attributes.models.map((elem) => ({
              type: "Filtruj model: ",
              name: elem.attributes.name,
              name_producer: elem.relationships?.producer.attributes.slug,
              slug: elem.attributes.slug,
              href: `/cars?${new URLSearchParams({
                filter_producer: elem.relationships?.producer.attributes.slug,
              })}&${new URLSearchParams({
                filter_model: elem.attributes.name,
              })}`,
            })),
          ];
          setResultList(suggestions);
          setIsFetching(false);
        }
      })
      .catch((error) => console.error(error));
  };

  // debounce searches to 500ms
  const search = debounce((e) => {
    const value = e.target?.value;
    if (!value) {
      setResultList([]);
      setShowResults(false);
    } else {
      setShowResults(true);
      setSearchString(value);
    }
  }, 500);

  const hanldeSearch = useCallback((event) => {
    event.persist();
    search(event);
  }, []);

  return (
    <Box
      position="relative"
      flex="1 1 0"
      maxWidth="670px"
      mx="auto"
      {...{
        ref: parentRef,
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Szukaj..."
        fullWidth
        onChange={hanldeSearch}
        onFocus={() => {
          if (searchString) {
            setShowResults(true);
          }
        }}
        onBlur={(e) => {
          if (e.relatedTarget?.getAttribute("ot") !== "suggestions") {
            setShowResults(false);
          }
        }}
        InputProps={{
          sx: {
            height: 44,
            borderRadius: 300,
            paddingRight: 0,
            color: "grey.700",
            overflow: "hidden",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          },
          startAdornment: <SearchOutlinedIcon fontSize="small" />,
        }}
      />
      <SearchResultCard
        id="test"
        sx={{
          display: showResults ? "block" : "none",
        }}
        elevation={2}
      >
        {!resultList.length && isFetching && (
          <MenuItem ot="suggestions">
            <Skeleton width="100%" variant="text" />
          </MenuItem>
        )}
        {!!searchString && !resultList.length && !isFetching && (
          <MenuItem ot="suggestions">
            <Typography color="grey.600">Brak wyników</Typography>
          </MenuItem>
        )}
        {!!resultList.length &&
          resultList.map((item) => (
            <MenuItem
              key={item.href}
              ot="suggestions"
              onClick={() => {
                handleLinkClick(item.href);
              }}
            >
              {item.type ? (
                <Typography mr={1} color="grey.600">
                  {item.type}
                </Typography>
              ) : null}
              {item.name}
              {item.name_producer ? (
                <Typography ml="auto" color="grey.600">
                  {item.name_producer}
                </Typography>
              ) : null}
              {item.price ? (
                <Typography ml="auto" color="grey.600">
                  {item.price} PLN
                </Typography>
              ) : null}
            </MenuItem>
          ))}
      </SearchResultCard>
    </Box>
  );
};
export default SearchBox;
