import { Fragment } from "react";
import TransportTracking from "../../components/orders/TransportTracking";
import Price from "../../components/Price";
import OrderPending from "../../components/icons/OrderPending";
import OrderProcessed from "../../components/icons/OrderProcessed";
import OrderPaid from "../../components/icons/OrderPaid";
import OrderTransported from "../../components/icons/OrderTransported";
import OrderFinished from "../../components/icons/OrderFinished";
import OrderStatus from "../../components/orders/OrderStatus";
import {
  Avatar,
  Card,
  Box,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import TableRow from "../../components/TableRow";
import { H5, H6, Paragraph } from "../../components/Typography";
import Done from "@mui/icons-material/Done";
import FlexBox from "components/FlexBox";

const StyledFlexbox = styled(FlexBox)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "2rem",
  marginBottom: "2rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  "& .line": {
    flex: "1 1 0",
    height: 4,
    minWidth: 50,
    [theme.breakpoints.down("sm")]: {
      flex: "unset",
      height: 50,
      minWidth: 4,
    },
  },
}));

const OrderDetails = ({ orderData }) => {
  const theme = useTheme();

  const orderStatus = orderData.status;
  const orderStatusList = [0, 1, 2, 3, 4];
  const stepIconList = [
    OrderPending,
    OrderProcessed,
    OrderPaid,
    OrderTransported,
    OrderFinished,
  ];
  const statusIndex = orderStatusList.indexOf(orderStatus);

  const deliveryAddress =
    orderData?.buyer?.company?.companyAddresses?.find(
      (address) => address.id === orderData?.productExpense?.fk_address_to
    ) || null;

  return (
    <Fragment>
      <Card
        sx={{
          p: "2rem 1.5rem",
          mb: "30px",
        }}
      >
        <StyledFlexbox>
          {stepIconList.map((Icon, ind) => (
            <Fragment key={ind}>
              <Box position="relative">
                <Avatar
                  sx={{
                    height: 64,
                    width: 64,
                    bgcolor: ind <= statusIndex ? "primary.main" : "grey.300",
                    color: ind <= statusIndex ? "grey.white" : "primary.main",
                  }}
                >
                  <Icon
                    color="inherit"
                    sx={{
                      fontSize: "32px",
                    }}
                  />
                </Avatar>

                {ind < statusIndex && (
                  <Box position="absolute" right="0" top="0">
                    <Avatar
                      sx={{
                        height: 22,
                        width: 22,
                        bgcolor: "grey.200",
                        color: "success.main",
                      }}
                    >
                      <Done
                        color="inherit"
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </Avatar>
                  </Box>
                )}
              </Box>
              {ind < stepIconList.length - 1 && (
                <Box
                  className="line"
                  bgcolor={ind < statusIndex ? "primary.main" : "grey.300"}
                />
              )}
            </Fragment>
          ))}
        </StyledFlexbox>

        <OrderStatus status={orderData.id} />
      </Card>

      <Card
        sx={{
          p: "0px",
          mb: "30px",
        }}
      >
        <TableRow
          sx={{
            bgcolor: "grey.200",
            p: "12px",
            boxShadow: "none",
            borderRadius: 0,
          }}
        >
          <FlexBox className="pre" m={0.75} alignItems="center">
            <Typography fontSize="14px" color="grey.600" mr={0.5}>
              Numer zamówienia:
            </Typography>
            <Typography fontSize="14px">{orderData.id}</Typography>
          </FlexBox>
          <FlexBox className="pre" m={0.75} alignItems="center">
            <Typography fontSize="14px" color="grey.600" mr={0.5}>
              Data zamówienia:
            </Typography>
            <Typography fontSize="14px">
              {new Date(orderData.order_date).toISOString().split("T")[0]}
            </Typography>
          </FlexBox>
          <FlexBox className="pre" m={0.75} alignItems="center">
            <Typography fontSize="14px" color="grey.600" mr={0.5}></Typography>
            <Typography fontSize="14px"></Typography>
          </FlexBox>
        </TableRow>

        <Box py={1}>
          <FlexBox px={2} py={1} flexWrap="wrap" alignItems="center">
            <FlexBox
              sx={{
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                  justifyContent: "center",
                  "& *": {
                    marginLeft: "0 !important",
                  },
                },
              }}
            >
              <Avatar
                src={
                  orderData.product.images[0]?.s || "/assets/images/img_404.png"
                }
                sx={{
                  height: 64,
                  width: 64,
                }}
              />

              <Box ml={2.5}>
                <H6 my="0px">{orderData.title}</H6>
                <h3 style={{ margin: 0 }}>
                  <a href={"/product/" + orderData.product.slug}>
                    {orderData.product.model.name}
                  </a>
                </h3>
                <FlexBox alignItems="center" mb={2}>
                  <Box>Producent:</Box>
                  <H6 ml={1}>{orderData.product.model.producer.name}</H6>
                </FlexBox>
              </Box>

              <Box ml={3.5}>
                <Box>
                  <Box component="span">Rok: </Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {orderData.product.year}
                  </Box>
                </Box>
                <Box>
                  <Box component="span">Przebieg: </Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {orderData.product.mileage} km
                  </Box>
                </Box>
              </Box>
              <Box ml={3.5}>
                <Box>
                  <Box component="span">Lokalizacja: </Box>
                </Box>
                <Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {orderData.product.address.place} /{" "}
                    {orderData.product.address.country}
                  </Box>
                </Box>
                <Box>
                  <Box component="span">Kod pocztowy: </Box>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {orderData.product.address.post_code}
                  </Box>
                </Box>
              </Box>
            </FlexBox>
          </FlexBox>
        </Box>
      </Card>

      <Grid container spacing={3}>
        {deliveryAddress && (
          <Grid item lg={6} md={6} xs={12}>
            <Card
              sx={{
                p: "20px 30px",
              }}
            >
              <H5 mt={0} mb={2}>
                Adres dostawy
              </H5>
              <Paragraph fontSize="14px" my="0px">
                {deliveryAddress.address1}
              </Paragraph>
              <Paragraph fontSize="14px" my="0px">
                {deliveryAddress.post_code}, {deliveryAddress.place} /{" "}
                {deliveryAddress.country}
              </Paragraph>
            </Card>
          </Grid>
        )}
        <Grid item lg={6} md={6} xs={12}>
          <Card
            sx={{
              p: "20px 30px",
            }}
          >
            <H5 mt={0} mb={2}>
              Podsumowanie
            </H5>
            <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
              <Typography fontSize="14px" color="grey.600">
                Cena pojazdu PLN:
              </Typography>
              <Price
                currency={"PLN"}
                value={orderData.price}
                fontWeight={"bold"}
              />
            </FlexBox>
            <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
              <Typography fontSize="14px" color="grey.600">
                Cena pojazdu EUR:
              </Typography>
              <Price currency={"EUR"} value={orderData.price_euro} />
            </FlexBox>

            {!!orderData.transport_included && (
              <FlexBox
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography fontSize="14px" color="grey.600">
                  Cena Transportu:
                </Typography>
                <Price
                  currency={"PLN"}
                  value={orderData.productExpense.transport_price}
                  fontWeight={"bold"}
                />
              </FlexBox>
            )}

            {!!orderData.expenses_included && (
              <FlexBox
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography fontSize="14px" color="grey.600">
                  Cena dostosowania pojazdu:
                </Typography>
                <Price
                  currency={"PLN"}
                  value={
                    orderData.productExpense.excise_price +
                    orderData.productExpense.duty_price +
                    orderData.productExpense.vat_price +
                    orderData.productExpense.inspection_price +
                    orderData.productExpense.adapt_price
                  }
                  fontWeight={"bold"}
                />
              </FlexBox>
            )}
          </Card>
        </Grid>
      </Grid>
      {!!orderData?.transport_included && (
        <TransportTracking id_order={orderData.id} />
      )}
    </Fragment>
  );
};
export default OrderDetails;
