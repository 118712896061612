import { useTheme } from "@emotion/react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import FlexBox from "components/FlexBox";
import cuid from "cuid";

const SpecificationKeyValue = ({ definition }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const makeBackgroundColor = (idx) => {
    if (isMobile) {
      return idx % 2 == 0 && "grey.200";
    }
    return (idx % 4 == 0 || (idx - 1) % 4 == 0) && "grey.200";
  };

  return (
    <Grid container spacing={2}>
      {definition.value.map((pair, idx) => (
        <Grid item xs={12} sm={6} key={cuid()}>
          <FlexBox
            sx={{
              justifyContent: "space-between",
              padding: 1,
              backgroundColor: makeBackgroundColor(idx),
            }}
          >
            <Typography>{pair.caption}</Typography>
            <Typography>{pair.value}</Typography>
          </FlexBox>
        </Grid>
      ))}
    </Grid>
  );
};
export default SpecificationKeyValue;
