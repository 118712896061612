import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Autocomplete,
  TextField,
  MenuItem,
  Typography,
  Slider,
  Divider,
  IconButton,
} from "@mui/material";
import { uniq, chunk } from "lodash-es";
import FlexBox from "components/FlexBox";
import { useState, useEffect, Fragment } from "react";
import { getModels } from "clients/producers";
import { getModelById } from "clients/models";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import OilBarrelOutlinedIcon from "@mui/icons-material/OilBarrelOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { getPowerPrices } from "clients/power-prices";

const SavingsCalculator = ({ producers, showPrint = true }) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [modelsInputData, setModelsInputData] = useState({
    id_model1: urlSearchParams.get("model1") || null,
    id_model2: urlSearchParams.get("model2") || null,
    producer1: null,
    producer2: null,
    models1: null,
    models2: null,
    selectedModelGroup1: null,
    selectedModelGroup2: null,
  });
  const [yearlyDistance, setYearlyDistance] = useState(
    parseInt(urlSearchParams.get("mileage")) || 10000
  );
  const [prices, setPrices] = useState(pricesFallbackData);
  const navigate = useNavigate();

  useEffect(() => {
    getPowerPrices().then((response) => {
      const [data] = response;
      setPrices(data);
    });
    if (modelsInputData.id_model1) {
      getModelById(modelsInputData.id_model1).then((response) => {
        const [data] = response;
        if (data) {
          setModelsInputData((modelsInputData) => ({
            ...modelsInputData,
            producer1: { label: data.producer.name, id: data.producer.id },
            selectedModelGroup1: data.name,
          }));
        }
      });
    }
    if (modelsInputData.id_model2) {
      getModelById(modelsInputData.id_model2).then((response) => {
        const [data] = response;
        if (data) {
          setModelsInputData((modelsInputData) => ({
            ...modelsInputData,
            producer2: { label: data.producer.name, id: data.producer.id },
            selectedModelGroup2: data.name,
          }));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (modelsInputData.producer1) {
      getModels(modelsInputData.producer1.id).then((response) => {
        const [data] = response;
        setModelsInputData((modelsInputData) => ({
          ...modelsInputData,
          models1: data,
        }));
      });
    } else {
      setModelsInputData((modelsInputData) => ({
        ...modelsInputData,
        models1: null,
      }));
    }
  }, [modelsInputData.producer1]);

  useEffect(() => {
    if (modelsInputData.producer2) {
      getModels(modelsInputData.producer2.id).then((response) => {
        const [data] = response;
        setModelsInputData((modelsInputData) => ({
          ...modelsInputData,
          models2: data,
        }));
      });
    } else {
      setModelsInputData((modelsInputData) => ({
        ...modelsInputData,
        models2: null,
      }));
    }
  }, [modelsInputData.producer2]);

  const producer_options = producers.map((producer) => ({
    label: producer.name,
    id: producer.id,
  }));

  const model_options_1 =
    uniq(modelsInputData.models1?.map((model) => model.name)) || [];

  const model_variants_1 =
    modelsInputData.models1?.filter(
      (elem) => elem.name === modelsInputData.selectedModelGroup1
    ) || [];

  const model_options_2 =
    uniq(modelsInputData.models2?.map((model) => model.name)) || [];

  const model_variants_2 =
    modelsInputData.models2?.filter(
      (elem) => elem.name === modelsInputData.selectedModelGroup2
    ) || [];

  const selectedModel1 =
    modelsInputData.models1?.find(
      (elem) => elem.id == modelsInputData.id_model1
    ) || null;
  const selectedModel2 =
    modelsInputData.models2?.find(
      (elem) => elem.id == modelsInputData.id_model2
    ) || null;

  const determineFeaturedModelAndOtherModel = () => {
    const yearlyPriceModel1 = calculateYearlyPrice(
      selectedModel1,
      yearlyDistance,
      prices
    );
    const yearlyPriceModel2 = calculateYearlyPrice(
      selectedModel2,
      yearlyDistance,
      prices
    );
    if (yearlyPriceModel1 > yearlyPriceModel2) {
      return [
        selectedModel2,
        selectedModel1,
        yearlyPriceModel2,
        yearlyPriceModel1,
      ];
    } else {
      return [
        selectedModel1,
        selectedModel2,
        yearlyPriceModel1,
        yearlyPriceModel2,
      ];
    }
  };

  const makeSaveWhenChoosingText = () => {
    try {
      const [model1, model2, priceModel1, priceModel2] =
        determineFeaturedModelAndOtherModel();
      const emissions1 = calculateYearlyEmissions(model1, yearlyDistance);
      const emissions2 = calculateYearlyEmissions(model2, yearlyDistance);

      return (
        <Typography mt={2} textAlign="center" variant="h5" color="primary">
          Wybierając{" "}
          <Typography
            variant="h5"
            color="primary"
            component="span"
            fontWeight={900}
          >
            {model1.producer.name} {model1.name}
          </Typography>{" "}
          zaoszczędzisz{" "}
          <Typography
            variant="h5"
            color="primary"
            component="span"
            fontWeight={900}
          >
            {Math.round((priceModel2 - priceModel1) * 100) / 100}
          </Typography>{" "}
          PLN Rocznie oraz wyemitujesz{" "}
          <Typography
            variant="h5"
            color="primary"
            component="span"
            fontWeight={900}
          >
            {formatWeight(Math.abs(emissions2 - emissions1))}
          </Typography>{" "}
          co2 {emissions2 - emissions1 > 0 ? "mniej" : "więcej"}
        </Typography>
      );
    } catch {
      return null;
    }
  };

  return (
    <Box>
      <FlexBox gap={1} flexWrap="wrap">
        <FlexBox minWidth={250} flexDirection="column" gap={1} flex={1}>
          <Autocomplete
            name="producer1"
            value={modelsInputData.producer1 || null}
            noOptionsText="Brak wyszukań"
            onChange={(e, new_value) => {
              setModelsInputData((modelsInputData) => ({
                ...modelsInputData,
                producer1: new_value,
                selectedModelGroup1: null,
                id_model1: null,
              }));
            }}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            options={producer_options}
            renderInput={(params) => (
              <TextField {...params} label="Producent" />
            )}
          />
          <Autocomplete
            name="selectedModelGroup1"
            value={modelsInputData.selectedModelGroup1 || null}
            noOptionsText="Brak wyszukań"
            disabled={!model_options_1.length}
            onChange={(e, new_value) => {
              setModelsInputData((modelsInputData) => ({
                ...modelsInputData,
                selectedModelGroup1: new_value,
                id_model1: null,
              }));
            }}
            options={model_options_1}
            renderInput={(params) => <TextField {...params} label="Model" />}
          />

          <TextField
            name="id_model1"
            label="Wariant"
            value={modelsInputData.id_model1 || ""}
            onChange={(e) => {
              urlSearchParams.set("model1", e.target.value);
              setUrlSearchParams(urlSearchParams, { replace: true });
              setModelsInputData((modelsInputData) => ({
                ...modelsInputData,
                id_model1: e.target.value,
              }));
            }}
            disabled={!model_variants_1.length}
            select
          >
            {model_variants_1.map((variant) => (
              <MenuItem
                key={variant.id.toString()}
                value={variant.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ width: "100%" }} fontWeight={600}>
                  {variant.name}
                </Typography>
                {productCompareAttributes(variant).map(
                  (elem) =>
                    elem.value && (
                      <Typography key={elem.label} sx={{ width: "100%" }}>
                        {elem.label}: {elem.value}
                      </Typography>
                    )
                )}
              </MenuItem>
            ))}
          </TextField>
          {selectedModel1 && !hasElectricEngine(selectedModel1) && (
            <OilBarrelOutlinedIcon
              color="secondary"
              sx={{
                width: "100%",
                height: "6ch",
              }}
            />
          )}
          {selectedModel1 && hasElectricEngine(selectedModel1) && (
            <EvStationOutlinedIcon
              color="primary"
              sx={{
                width: "100%",
                height: "6ch",
              }}
            />
          )}
        </FlexBox>
        <Divider flexItem={true} orientation="vertical" />
        <FlexBox minWidth={250} flexDirection="column" gap={1} flex={1}>
          <Autocomplete
            name="producer2"
            value={modelsInputData.producer2 || null}
            noOptionsText="Brak wyszukań"
            onChange={(e, new_value) => {
              setModelsInputData((modelsInputData) => ({
                ...modelsInputData,
                producer2: new_value,
                selectedModelGroup2: null,
                id_model2: null,
              }));
            }}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            options={producer_options}
            renderInput={(params) => (
              <TextField {...params} label="Producent" />
            )}
          />
          <Autocomplete
            name="selectedModelGroup2"
            value={modelsInputData.selectedModelGroup2 || null}
            noOptionsText="Brak wyszukań"
            disabled={!model_options_2.length}
            onChange={(e, new_value) => {
              setModelsInputData((modelsInputData) => ({
                ...modelsInputData,
                selectedModelGroup2: new_value,
                id_model2: null,
              }));
            }}
            options={model_options_2}
            renderInput={(params) => <TextField {...params} label="Model" />}
          />

          <TextField
            name="id_model2"
            label="Wariant"
            value={modelsInputData.id_model2 || ""}
            onChange={(e) => {
              urlSearchParams.set("model2", e.target.value);
              setUrlSearchParams(urlSearchParams, { replace: true });
              setModelsInputData((modelsInputData) => ({
                ...modelsInputData,
                id_model2: e.target.value,
              }));
            }}
            disabled={!model_variants_2.length}
            select
          >
            {model_variants_2.map((variant) => (
              <MenuItem
                key={variant.id.toString()}
                value={variant.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ width: "100%" }} fontWeight={600}>
                  {variant.name}
                </Typography>
                {productCompareAttributes(variant).map(
                  (elem) =>
                    elem.value && (
                      <Typography key={elem.label} sx={{ width: "100%" }}>
                        {elem.label}: {elem.value}
                      </Typography>
                    )
                )}
              </MenuItem>
            ))}
          </TextField>
          {selectedModel2 && !hasElectricEngine(selectedModel2) && (
            <OilBarrelOutlinedIcon
              color="secondary"
              sx={{
                width: "100%",
                height: "6ch",
              }}
            />
          )}
          {selectedModel2 && hasElectricEngine(selectedModel2) && (
            <EvStationOutlinedIcon
              color="primary"
              sx={{
                width: "100%",
                height: "6ch",
              }}
            />
          )}
        </FlexBox>
      </FlexBox>
      <Divider />
      <Typography
        variant="h5"
        fontWeight={500}
        color="primary"
        textAlign="center"
        mt={2}
      >
        Przy przejeździe {formatNumbers(yearlyDistance)} km rocznie
      </Typography>
      <Slider
        value={yearlyDistance}
        onChange={(e, new_value) => {
          setYearlyDistance(new_value);
        }}
        valueLabelDisplay="auto"
        step={10}
        min={100}
        max={50000}
      />
      <FlexBox>
        <Box
          sx={{
            flex: 1,
            textAlign: "center",
          }}
        >
          {selectedModel1 && (
            <Fragment>
              {makeEmissionsText(selectedModel1, yearlyDistance)}
              {makeConsumptionsText(selectedModel1, yearlyDistance)}
              {makePricesText(selectedModel1, yearlyDistance, prices)}
            </Fragment>
          )}
        </Box>
        <Divider flexItem={true} orientation="vertical" />
        <Box sx={{ flex: 1, textAlign: "center" }}>
          {selectedModel2 && (
            <Fragment>
              {makeEmissionsText(selectedModel2, yearlyDistance)}
              {makeConsumptionsText(selectedModel2, yearlyDistance)}
              {makePricesText(selectedModel2, yearlyDistance, prices)}
            </Fragment>
          )}
        </Box>
      </FlexBox>
      {makeSaveWhenChoosingText()}
      {showPrint && (
        <FlexBox justifyContent="end">
          <IconButton
            onClick={() => {
              urlSearchParams.set("mileage", yearlyDistance);
              navigate({ pathname: "print", search: `&${urlSearchParams}` });
            }}
          >
            <LocalPrintshopOutlinedIcon color="primary" />
          </IconButton>
        </FlexBox>
      )}
    </Box>
  );
};
const productCompareAttributes = (product) => [
  {
    label: "Typ Nadwozia",
    value: product.bodyType?.name,
  },
  {
    label: "Wariant",
    value: product.version,
  },
  {
    label: "Moc Silnika",
    value: `${product.engine_power} kW`,
  },
  {
    label: "Napęd",
    value: product.driveType?.name,
  },
];

const formatWeight = (weightInGrams) => {
  if (weightInGrams > 1000000) {
    return `${(weightInGrams / 1000000).toFixed(2)} ton`;
  } else if (weightInGrams > 1000) {
    return `${(weightInGrams / 1000).toFixed(2)} kg`;
  } else {
    return `${weightInGrams} gram`;
  }
};

const hasElectricEngine = (model) => {
  return model.driveType.engines.includes("ENGINE_ELECTRIC");
};
const hasPetrolEngine = (model) => {
  return model.driveType.engines.includes("ENGINE_PETROL");
};
const hasDieselEngine = (model) => {
  return model.driveType.engines.includes("ENGINE_DIESEL");
};

const calculateYearlyEmissions = (model, distance) => {
  return distance * model?.emission_co2;
};

const makeEmissionsText = (model, disance) => {
  return (
    <Typography color="primary" variant="subtitle1">
      Wyemitujesz{" "}
      <Typography component="span" fontWeight={900}>
        {formatWeight(calculateYearlyEmissions(model, disance))}
      </Typography>{" "}
      co2
    </Typography>
  );
};

const makeConsumptionsText = (model, distance) => {
  return (
    <Fragment>
      {(hasPetrolEngine(model) || hasDieselEngine(model)) && (
        <Typography color="primary" variant="subtitle1">
          Zużyjesz{" "}
          <Typography component="span" fontWeight={900}>
            {(model.fuel_consumption * (distance / 100)).toFixed(2)}
          </Typography>{" "}
          litrów {hasPetrolEngine(model) ? "benznyny" : "diesla"}
        </Typography>
      )}
      {hasElectricEngine(model) && (
        <Typography color="primary" variant="subtitle1">
          Zużyjesz{" "}
          <Typography component="span" fontWeight={900}>
            {(model.energy_consumption * (distance / 100)).toFixed(2)}
          </Typography>{" "}
          kW⋅h
        </Typography>
      )}
    </Fragment>
  );
};

const calculateYearlyPrice = (model, distance, prices) => {
  const price_petrol =
    !!hasPetrolEngine(model) *
    ((distance / 100) * model.fuel_consumption * prices.price_petrol);
  const price_diesel =
    !!hasDieselEngine(model) *
    ((distance / 100) * model.fuel_consumption * prices.price_diesel);
  const price_electric =
    !!hasElectricEngine(model) *
    ((distance / 100) * model.energy_consumption * prices.price_electricity);

  return price_petrol + price_diesel + price_electric;
};

const makePricesText = (model, distance, prices) => {
  return (
    <Typography color="primary" variant="subtitle1">
      Co kosztuje{" "}
      <Typography component="span" fontWeight={900}>
        {calculateYearlyPrice(model, distance, prices).toFixed(2)}
      </Typography>{" "}
      PLN
    </Typography>
  );
};

const formatNumbers = (number) => {
  return chunk(number.toString().split("").reverse(), 3)
    .map((elem) => elem.reverse().join(""))
    .reverse()
    .join(" ");
};

const pricesFallbackData = {
  price_electricity: 0.88,
  price_petrol: 6.88,
  price_diesel: 7.98,
};

export default SavingsCalculator;
