import DashboardLayout from "components/layout/CustomerDashboardLayout";
import React from "react";
import AddressEditor from "components/address/AddressEditor";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCompanyAddress } from "clients/company-addresses";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import Place from "@mui/icons-material/Place";
import { Link } from "react-router-dom";
import { Button, CircularProgress, Card } from "@mui/material";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";

const AddressEdit = () => {
  const [values, setValues] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getCompanyAddress(id)
        .then((response) => {
          const [data] = response;
          const {
            name,
            address1,
            address2,
            post_code,
            place,
            country,
            is_main,
          } = data.attributes;
          setValues({
            id: data.id,
            name,
            address1,
            address2,
            post_code,
            place,
            country,
            is_main,
          });
        })
        .catch((error) => console.error(error));
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={Place}
        title="Edytuj adres"
        button={
          <Link to="/address">
            <Button
              color="primary"
              sx={{ bgcolor: "primary.light", px: "2rem" }}
            >
              Powrót
            </Button>
          </Link>
        }
        navigation={<CustomerDashboardNavigation />}
      />
      {values ? (
        <AddressEditor values={values} />
      ) : (
        <Card
          sx={{
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
    </DashboardLayout>
  );
};

export default AddressEdit;
