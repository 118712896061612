import DashboardLayout from "components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import Place from "@mui/icons-material/Place";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import AddressEditor from "components/address/AddressEditor";

const AddressAddPage = () => {
  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={Place}
        title="Dodaj adres"
        button={
          <Link to="/address">
            <Button
              color="primary"
              sx={{ bgcolor: "primary.light", px: "2rem" }}
            >
              Powrót
            </Button>
          </Link>
        }
        navigation={<CustomerDashboardNavigation />}
      />
      <AddressEditor
        values={{
          name: "",
          address1: "",
          address2: "",
          post_code: "",
          place: "",
          country: "Polska",
        }}
      />
    </DashboardLayout>
  );
};

export default AddressAddPage;
