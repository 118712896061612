import React from "react";
import { Typography } from "@mui/material";

const Price = ({ currency, value, fontWeight }) => {
  return (
    <Typography m={0.75} textAlign="left" fontWeight={fontWeight}>
      {new Intl.NumberFormat("pl-PL", {
        style: "currency",
        currency: currency,
        maximumSignificantDigits: 16,
      }).format(value.toFixed(2))}
    </Typography>
  );
};

export default Price;
