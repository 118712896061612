import { Chip } from "@mui/material";
import EnergySavingsLeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import OilBarrelOutlinedIcon from "@mui/icons-material/OilBarrelOutlined";

const IsEcoChip = ({ is_eco }) => {
  return (
    <Chip
      icon={
        is_eco ? <EnergySavingsLeafOutlinedIcon /> : <OilBarrelOutlinedIcon />
      }
      color={is_eco ? "primary" : "error"}
      label={is_eco ? "Pojazd Ekologiczny" : "Pojazd Nieekologiczny"}
    />
  );
};
export default IsEcoChip;
