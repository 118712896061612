import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as chartTooltip,
  Legend,
} from "chart.js";
import { useTheme } from "@emotion/react";
import { Card } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  chartTooltip,
  Legend
);

const Chart = ({ data, xSuffix, ySuffix, xLabel, yLabel, label }) => {
  const theme = useTheme();

  const chart_options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: xLabel ? true : false,
          text: xLabel,
        },
      },
      y: {
        display: true,
        title: {
          display: yLabel ? true : false,
          text: yLabel,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return `${value}` + (ySuffix ?? "");
          },
        },
      },
    },
  };

  return (
    <Card
      sx={{
        padding: 1,
      }}
    >
      <Line
        options={chart_options}
        data={{
          labels: data.map((elem) => `${elem.x}` + (xSuffix ?? "")) || [],
          datasets: [
            {
              label: label,
              data: data.map((elem) => elem.y.toFixed(2)) || [],
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.primary[200],
            },
          ],
        }}
      />
    </Card>
  );
};
export default Chart;
