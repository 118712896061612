import FlexBox from "../FlexBox";
import { useNavigate } from "react-router-dom";
import {
  Card,
  IconButton,
  Skeleton,
  Typography,
  Button,
  useTheme,
  Chip,
} from "@mui/material";
import {
  acceptInspection,
  rejectInspection,
  getCurrentInspections,
} from "../../clients/inspector-inspections";
import { useState, useEffect, Fragment } from "react";
import { format } from "date-fns";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  determineInspectionStatus,
  determineInspectionStatusColor,
  INSPECTION_ACCEPTED,
  INSPECTION_FINISHED,
  INSPECTION_IN_PROGRESS,
  INSPECTION_PAID,
  INSPECTION_REFUSED,
  INSPECTION_WAITING,
} from "clients/inspections";
import StartUnpaidInspectionDialog from "./StartUnpaidInspectionDialog";

const InspectorInspectionList = ({ historical }) => {
  const [data, setData] = useState();
  const [openMap, setOpenMap] = useState({});
  const [startInspectionId, setStartInspectionId] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  // handle opening and closing of elements
  const handleCollapseElement = (id) => {
    setOpenMap((openMap) => ({ ...openMap, [id]: !openMap[id] }));
  };

  // get data from api and fetch data from api
  useEffect(() => {
    getCurrentInspections().then((response) => {
      const [data] = response;
      if (data) {
        if (historical) {
          setData(
            data.filter(
              (elem) =>
                elem.status === INSPECTION_FINISHED ||
                elem.status === INSPECTION_REFUSED
            )
          );
        } else {
          setData(
            data.filter(
              (elem) =>
                elem.status === INSPECTION_WAITING ||
                elem.status === INSPECTION_ACCEPTED ||
                elem.status === INSPECTION_PAID ||
                elem.status === INSPECTION_IN_PROGRESS
            )
          );
        }
      }
    });
  }, []);

  const handleStartInspection = (inspection) => {
    if (inspection.status >= INSPECTION_PAID) {
      navigate(`/make-inspection/${inspection.id}`);
    } else {
      setStartInspectionId(inspection.id);
    }
  };

  if (!data) {
    return (
      <Card
        sx={{
          display: "flex",
          padding: "10px",
          margin: "10px 0 10px 0",
        }}
      >
        <FlexBox
          sx={{
            flex: 1,
          }}
        >
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            <Skeleton variant="text" width="30ch" />
          </Typography>
        </FlexBox>
        <FlexBox>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" width="40ch" />
          </Typography>
        </FlexBox>
        <FlexBox>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" width="3ch" />
          </Typography>
        </FlexBox>
      </Card>
    );
  }

  if (data.length == 0) {
    return (
      <Fragment>
        <Card
          sx={{
            display: "flex",
            padding: "10px",
            margin: "10px 0 10px 0",
          }}
        >
          <FlexBox
            sx={{
              flex: 1,
            }}
          >
            <Typography whiteSpace="pre" m={0.75} textAlign="left">
              {!historical
                ? "Nie masz żadnych inspekcji do wykonania"
                : "Nie masz historii wykonanych inspekcji"}
            </Typography>
          </FlexBox>
        </Card>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {data.map((row) => (
        <Card
          key={row.id}
          sx={{
            display: "flex",
            padding: "10px",
            margin: "10px 0 10px 0",
            flexDirection: "column",
          }}
        >
          <FlexBox flexWrap="wrap">
            <FlexBox
              sx={{
                flex: 1,
              }}
            >
              <Typography whiteSpace="pre" m={0.75} textAlign="left">
                {row.relationships.product.relationships.model.relationships
                  .producer.attributes.name +
                  " / " +
                  row.relationships.product.relationships.model.attributes.name}
              </Typography>
            </FlexBox>
            <FlexBox>
              <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
                {format(new Date(row.attributes.scheduled_date), "dd.MM.yyyy") +
                  ", " +
                  row.relationships.product.relationships.address.attributes
                    .post_code +
                  " " +
                  row.relationships.product.relationships.address.attributes
                    .place}
              </Typography>
            </FlexBox>
            <FlexBox>
              <Chip
                label={determineInspectionStatus(row.status)}
                sx={{
                  backgroundColor: determineInspectionStatusColor(row.status),
                }}
              />
            </FlexBox>
            <FlexBox>
              <Typography whiteSpace="pre" textAlign="right" color="grey.600">
                <IconButton
                  onClick={() => {
                    handleCollapseElement(row.id.toString());
                  }}
                >
                  {!!openMap[row.id.toString()] ? (
                    <ExpandLessIcon fontSize="small" color="inherit" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" color="inherit" />
                  )}
                </IconButton>
              </Typography>
            </FlexBox>
          </FlexBox>

          {!!openMap[row.id.toString()] && (
            <Fragment>
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <Typography variant="h7" color="grey.600">
                  Pojazd
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      VIN: {row.relationships.product.attributes.vin}
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>Rocznik: {row.product.year}</Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>Przebieg: {row.product.mileage} km</Typography>
                  </Card>
                </Card>
              </FlexBox>
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <Typography variant="h7" color="grey.600">
                  Właściciel
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {row.relationships.customer.attributes.first_name}{" "}
                      {row.relationships.customer.attributes.last_name}
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <a
                      href={`tel:${row.relationships.customer.attributes.phone_number}`}
                    >
                      <Typography>
                        tel.:{" "}
                        {row.relationships.customer.attributes.phone_number}
                      </Typography>
                    </a>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <a
                      href={`mailto:${row.relationships.customer.attributes.email}`}
                    >
                      <Typography>
                        email: {row.relationships.customer.attributes.email}
                      </Typography>
                    </a>
                  </Card>
                </Card>
              </FlexBox>
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <Typography variant="h7" color="grey.600">
                  Adres
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {
                        row.relationships.product.relationships.address
                          .attributes.address1
                      }
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {
                        row.relationships.product.relationships.address
                          .attributes.post_code
                      }{" "}
                      {
                        row.relationships.product.relationships.address
                          .attributes.place
                      }
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      {
                        row.relationships.product.relationships.address
                          .attributes.country
                      }
                    </Typography>
                  </Card>
                </Card>
              </FlexBox>
              <FlexBox
                sx={{
                  flexDirection: "column",
                }}
              >
                <Typography variant="h7" color="grey.600">
                  Informacje
                </Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      Cena inspekcji: {row.price_inspection} PLN
                    </Typography>
                  </Card>
                  <Card elevation={1} sx={{ padding: "10px", flex: 1 }}>
                    <Typography>
                      Cena dojazdu: {row.price_transport} PLN
                    </Typography>
                  </Card>
                </Card>
              </FlexBox>
              {row.status === 0 && (
                <FlexBox gap={2} mt={1}>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={async () => {
                      const response = await acceptInspection(row.id);
                      const [data] = response;
                      if (data) {
                        setData((data) => [
                          ...data.filter((elem) => elem.id !== row.id),
                          {
                            ...row,
                            status: 2,
                            attributes: {
                              ...row.attributes,
                              status: 2,
                            },
                          },
                        ]);
                      }
                    }}
                    sx={{ flex: 1 }}
                  >
                    Akceptuj
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={async () => {
                      const response = await rejectInspection(row.id);
                      const [data] = response;
                      if (data) {
                        setData((data) => [
                          ...data.filter((elem) => elem.id !== row.id),
                        ]);
                      }
                    }}
                    sx={{ flex: 1 }}
                  >
                    Odrzuć
                  </Button>
                </FlexBox>
              )}
              {(row.status === INSPECTION_ACCEPTED ||
                row.status === INSPECTION_PAID ||
                row.status === INSPECTION_IN_PROGRESS ||
                row.status === INSPECTION_FINISHED) && (
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleStartInspection(row);
                  }}
                >
                  Inspekcja
                </Button>
              )}
            </Fragment>
          )}
        </Card>
      ))}
      <StartUnpaidInspectionDialog
        open={!!startInspectionId}
        onClose={() => {
          setStartInspectionId(null);
        }}
        IdInspection={startInspectionId}
      />
    </Fragment>
  );
};
export default InspectorInspectionList;
