import apiClient from "./apiClient";

export const getCustomerPaymentList = async () => {
  const response = await apiClient.get("/payments");
  return response;
};

export const getCustomerPaymentDetails = async (id) => {
  const response = await apiClient.get("/payments/" + id);
  return response;
};

export const getCustomerPaymentDocument = async (id) => {
  const response = await apiClient.get("/payments/" + id);
  return response;
};
