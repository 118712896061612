import { Box, Button, Typography } from "@mui/material";
import { useAppContext } from "../../contexts/app/AppContext";
import FlexBox from "../FlexBox";
import { Link } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import PublicInspectionPreview from "components/public-inspection-preview/PublicInspectionPreview";
import { INSPECTION_FINISHED } from "clients/inspections";

const ProductInspection = ({ product, pageContent }) => {
  const { state } = useAppContext();
  const { user } = state;

  const make_inspection_box = () => {
    if (product.hasOwnProperty("private_inspection")) {
      if (product.private_inspection.status === INSPECTION_FINISHED) {
        return (
          <Box>
            <Typography variant="h6" mb="10px">
              Inspekcja Ukończona
            </Typography>
            <PublicInspectionPreview inspection={product.private_inspection} />
          </Box>
        );
      }
      return (
        <Box>
          <Typography mb="10px">Inspekcja w trakcie realizacji</Typography>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography mb="10px">{pageContent.order_inspection}</Typography>
          <Link
            to={{
              pathname: "/inspections/order",
              search: `?${createSearchParams({
                product_slug: product.slug,
              })}`,
            }}
          >
            <Button variant="contained" color="primary">
              Zleć inspekcję
            </Button>
          </Link>
        </Box>
      );
    }
  };

  return (
    <Box>
      {user?.user && make_inspection_box()}
      {!user?.user && (
        <FlexBox>
          <Link to="/login">Zaloguj się</Link>
          <Typography>&nbsp;aby zlecić inspekcję</Typography>
        </FlexBox>
      )}
    </Box>
  );
};
export default ProductInspection;
