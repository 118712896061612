import { Fragment } from "react";
import SpecificationTypeHandler from "./types/SpecificationTypeHandler";
import cuid from "cuid";
import { Box, Divider, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";

const ProductModelSpecification = ({ product }) => {
  return (
    <Fragment>
      {product.model.specification.map((elem) => (
        <Fragment key={cuid()}>
          <SpecificationTypeHandler definition={elem} />
          <Divider />
        </Fragment>
      ))}
      {isEmpty(product.model.specification) && (
        <Box>
          <Typography>Brak specyfikacji dla modelu</Typography>
        </Box>
      )}
    </Fragment>
  );
};
export default ProductModelSpecification;
