import {
  Chip,
  Card,
  Box,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import {
  deleteWorkingDay,
  markAsWorkingDay,
} from "clients/inspector-availabilities";
import { padStart } from "lodash-es";
import { getAvailabilitiesCalendar } from "clients/inspector-availabilities";

const InspectorCalendar = ({ year, month }) => {
  const [renderData, setRenderData] = useState();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedDay, setSelectedDay] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const selectedDayObject = renderData?.find(
    (elem) => elem.label == selectedDay
  );

  useEffect(() => {
    setIsLoading(true);
    const calendarData = [];

    // month +1 to convert january = 1, not 0 etc
    getAvailabilitiesCalendar(year, month + 1)
      .then((response) => {
        const [data] = response;
        for (let day = 1; day <= daysInMonth(month, year); day++) {
          calendarData.push({
            label: day.toString(),
            status: makeStatus(data, new Date(`${year}-${month + 1}-${day}`)),
            // hack to convert 0 - sunday, to 7 - sunday
            column: new Date(year, month, day).getDay() || 7,
            disabled: new Date(year, month, day) < new Date(),
            id:
              data.find(
                (elem) =>
                  new Date(elem.day).getTime() ==
                  new Date(`${year}-${month + 1}-${day}`).getTime()
              )?.id || null,
          });
        }
        setRenderData(calendarData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [year, month]);

  const handleDateClick = (e) => {
    setSelectedDay(e.currentTarget.getAttribute("monthday"));
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMarkAsWorkingDay = async () => {
    setIsLoading(true);
    handleMenuClose();
    const [data] = await markAsWorkingDay(
      `${year}-${padStart(month + 1, 2, "0")}-${padStart(selectedDay, 2, "0")}`
    );
    if (data) {
      setRenderData((renderData) => {
        const currentElement = renderData.find(
          (elem) => elem.label === selectedDay
        );
        renderData[renderData.indexOf(currentElement)] = {
          ...currentElement,
          status: 1,
          id: data.id,
        };
        return [...renderData];
      });
    }
    setIsLoading(false);
  };

  const handleRemoveWorkingDay = async () => {
    setIsLoading(true);
    handleMenuClose();
    await deleteWorkingDay(selectedDayObject.id);
    setRenderData((renderData) => {
      const currentElement = renderData.find(
        (elem) => elem.label === selectedDay
      );
      renderData[renderData.indexOf(currentElement)] = {
        ...currentElement,
        status: 0,
      };
      return [...renderData];
    });
    setIsLoading(false);
  };

  return (
    <Card
      sx={{
        position: "relative",
        display: "inline-flex",
        padding: 2,
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          padding: "2px !important",
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gridTemplateRows: "min-content 1fr min-content",
          gap: "10px 10px",
          overflowX: "auto",
        }}
      >
        {["pon", "wt", "sr", "cz", "pt", "sob", "nd"].map((day) => (
          <Box
            key={day}
            sx={{
              justifySelf: "center",
            }}
          >
            <Typography key={day}>{day}</Typography>
          </Box>
        ))}
        {!!renderData &&
          renderData.map((elem) => (
            <Box
              key={elem.label}
              sx={{
                justifySelf: "center",
                gridColumn: elem.column,
              }}
            >
              <Chip
                label={elem.label}
                monthday={elem.label}
                disabled={isLoading || elem.disabled}
                onClick={handleDateClick}
                sx={{
                  backgroundColor: makeColor(elem.status),
                }}
              />
            </Box>
          ))}
      </Box>
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleMenuClose}>
        <MenuItem
          disabled={isLoading || selectedDayObject?.status != 0}
          onClick={() => {
            handleMarkAsWorkingDay();
          }}
        >
          Oznacz jako dostepny
        </MenuItem>
        <MenuItem
          onClick={handleRemoveWorkingDay}
          disabled={isLoading || selectedDayObject?.status != 1}
        >
          Oznacz jako niedostępny
        </MenuItem>
      </Menu>
      {isLoading && (
        <Box
          sx={{
            zIndex: 100,
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            top: 0,
            backgroundColor: "#0000001a",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
};
const daysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};
const makeStatus = (availabilities, date) => {
  const foundDate = availabilities.find(
    (elem) => new Date(elem.day).getTime() == date.getTime()
  );
  if (foundDate) {
    if (foundDate.inspection) {
      return 2;
    } else {
      return 1;
    }
  } else {
    return 0;
  }
};
const makeColor = (status) => {
  switch (status) {
    case 0: {
      return "";
    }
    case 1: {
      return "success.200";
    }
    case 2: {
      return "secondary.200";
    }
  }
};
export default InspectorCalendar;
