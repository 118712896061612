import {
  Autocomplete,
  Card,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
  Box,
  LinearProgress,
} from "@mui/material";
import { getModels } from "clients/producers";
import FlexBox from "components/FlexBox";
import { useFormik } from "formik";
import { omit, uniq } from "lodash-es";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getCompanyAddresses } from "clients/company-addresses";
import AddAddressDialog from "./AddAddressDialog";
import ImagesDropzone from "./ImagesDropzone";
import { addProductImage, createProduct } from "clients/products";
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "contexts/app/AppContext";
import { toBase64 } from "utils/utils";
import { useNavigate } from "react-router-dom";
import { chunk, isEmpty } from "lodash";
import cuid from "cuid";
import { errorMessages } from "../../utils/constants";

const AddProduct = ({ producers, productDirectories }) => {
  const [isLoading, setIsLoading] = useState();
  const [models, setModels] = useState();
  const [selectedModelGroup, setSelectedModelGroup] = useState();
  const [uneditableValues, setUneditableValues] = useState({});
  const [companyAddresses, setCompanyAddresses] = useState([]);
  const [addAddressDialogOpen, setAddAddressDialogOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [equipment, setEquipment] = useState({});
  const [sendProgress, setSendProgress] = useState(0);

  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    // Check for images
    if (files.length === 0) {
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: {
          text: "Proszę dodać co najmniej jedno zdjęcie",
          type: "error",
        },
      });
      setIsLoading(false);
      return;
    }
    // Check for equipment
    if (isEmpty(equipment)) {
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: {
          text: "Proszę wybrać co najmniej jeden element wyposażenia",
          type: "error",
        },
      });
      setIsLoading(false);
      return;
    }
    // Create new product
    setSendProgress(5);
    values = omit(values, ["fk_producer"]);
    values.equipment = equipment;
    const response = await createProduct(values);
    const [data, validations] = response;

    if (data) {
      // On success encode all images to b64
      let files_encoded = await Promise.all(
        files.map((elem) => toBase64(elem))
      );
      // send all images
      let idx = 1;
      for (const elem of files_encoded) {
        let image_data = {
          fk_product: data.id,
          image_order: idx,
          base64_image: elem,
        };
        await addProductImage(image_data);
        setSendProgress(
          (sendProgress) => sendProgress + (1 / files.length) * 100
        );
        idx++;
      }
      // await sending files and push to list view
      navigate(`/products/${data.id}`);
    }

    if (validations) {
      validations.forEach((validation) => {
        formik.setFieldError(validation.source, validation.title);
      });
    }

    setIsLoading(false);
    setSendProgress(0);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const fetchModels = (idProducer) => {
    getModels(idProducer).then((response) => {
      const [data] = response;
      setModels(data);
    });
  };

  const producer_options = producers.map((producer) => ({
    label: producer.name,
    id: producer.id,
  }));

  const model_options = uniq(models?.map((model) => model.name)) || [];

  const model_variants =
    models?.filter((elem) => elem.name === selectedModelGroup) || [];

  const hasCombustionEngine = () => {
    const driveType = productDirectories.drive_types.find(
      (type) => type.id === formik.values.fk_drive_type
    );
    return (
      driveType?.attributes.engines?.includes("ENGINE_PETROL") ||
      driveType?.attributes.engines?.includes("ENGINE_DIESEL")
    );
  };

  const hasElectricEngine = () => {
    return productDirectories.drive_types
      .find((type) => type.id === formik.values.fk_drive_type)
      ?.attributes.engines?.includes("ENGINE_ELECTRIC");
  };

  const handleAddAddressDialogClose = () => {
    setAddAddressDialogOpen(false);
    getCompanyAddresses().then((response) => {
      const [data] = response;
      setCompanyAddresses(data);
    });
  };

  useEffect(() => {
    if (formik.values.fk_producer) {
      fetchModels(formik.values.fk_producer.id);
    } else {
      setModels(null);
    }
    setSelectedModelGroup(null);
    formik.setFieldValue("fk_model", "");
  }, [formik.values.fk_producer]);

  useEffect(() => {
    if (formik.values.fk_model) {
      let modelProvidedValues = models.find(
        (model) => model.id === formik.values.fk_model
      );
      setUneditableValues(modelProvidedValues);
      let new_values = {};
      Object.keys(omit(initialValues, ["fk_producer", "fk_model"])).forEach(
        (key) => {
          if (modelProvidedValues.hasOwnProperty(key)) {
            new_values[key] = modelProvidedValues[key] || "";
          }
        }
      );
      formik.setValues((values) => ({ ...values, ...new_values }));
    } else {
      setUneditableValues({});
    }
  }, [formik.values.fk_model]);

  // Fetch company addresses
  useEffect(() => {
    getCompanyAddresses().then((response) => {
      const [data] = response;
      setCompanyAddresses(data || []);
    });
    if (hasElectricEngine()) {
      formik.setFieldValue("has_electric", true);
    }
    if (hasCombustionEngine()) {
      formik.setFieldValue("has_combustion", true);
    }
  }, []);

  return (
    <Card
      sx={{
        display: "flex",
        padding: 1,
        gap: 2,
        flexDirection: "column",
      }}
    >
      <Typography fontWeight="600">Dane Pojazdu</Typography>
      <FlexBox gap={2} flexWrap="wrap">
        <TextField
          name="vin"
          label="VIN"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.vin || ""}
          error={!!formik.touched.vin && !!formik.errors.vin}
          helperText={formik.touched.vin && formik.errors.vin}
          disabled={isLoading}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        />
        <TextField
          name="registration_number"
          label="Numer Rejestracyjny"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.registration_number || ""}
          error={
            !!formik.touched.registration_number &&
            !!formik.errors.registration_number
          }
          helperText={
            formik.touched.registration_number &&
            formik.errors.registration_number
          }
          disabled={isLoading}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        />
      </FlexBox>
      <FlexBox gap={2} flexWrap="wrap">
        <TextField
          name="mileage"
          label="Przebieg"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mileage || ""}
          error={!!formik.touched.mileage && !!formik.errors.mileage}
          helperText={formik.touched.mileage && formik.errors.mileage}
          disabled={isLoading}
          InputProps={{
            endAdornment: <InputAdornment position="end">km</InputAdornment>,
          }}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        />

        <TextField
          name="first_registration_date"
          label="Data Pierwszej rejestracji"
          onChange={formik.handleChange}
          onBlur={(e) => {
            e.target.type = "text";
            formik.handleBlur(e);
          }}
          onFocus={(e) => {
            e.target.type = "date";
          }}
          disabled={isLoading}
          value={formik.values.first_registration_date || ""}
          error={
            !!formik.touched.first_registration_date &&
            !!formik.errors.first_registration_date
          }
          helperText={
            formik.touched.first_registration_date &&
            formik.errors.first_registration_date
          }
          variant="outlined"
          size="small"
          placeholder=""
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        />
      </FlexBox>
      <TextField
        name="seller_price"
        label="Cena"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.seller_price || ""}
        error={!!formik.touched.seller_price && !!formik.errors.seller_price}
        helperText={formik.touched.seller_price && formik.errors.seller_price}
        disabled={isLoading}
        InputProps={{
          endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
        }}
      />
      <FlexBox gap={1} alignItems="center">
        <TextField
          name="fk_company_address"
          onChange={formik.handleChange}
          label="Adres Pojazdu"
          value={formik.values.fk_company_address || ""}
          onBlur={formik.handleBlur}
          variant="outlined"
          size="small"
          disabled={isLoading}
          error={
            !!formik.touched.fk_company_address &&
            !!formik.errors.fk_company_address
          }
          helperText={
            formik.touched.fk_company_address &&
            formik.errors.fk_company_address
          }
          select
          sx={{
            flex: 1,
          }}
        >
          {companyAddresses.map((address) => (
            <MenuItem key={address.id.toString()} value={address.id}>
              {address.name}
            </MenuItem>
          ))}
        </TextField>
        <Typography>lub</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setAddAddressDialogOpen(true);
          }}
        >
          Dodaj Adres
        </Button>
      </FlexBox>
      <Divider />
      <Typography fontWeight="600">Specyfikacja Pojazdu</Typography>
      <FlexBox gap={2} flexWrap="wrap">
        <Autocomplete
          name="fk_producer"
          value={formik.values.fk_producer || null}
          noOptionsText="Brak wyszukań"
          disabled={isLoading}
          onChange={(e, new_value) => {
            formik.setFieldValue("fk_producer", new_value);
          }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          options={producer_options}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Producent"
              onBlur={formik.handleBlur}
              error={
                !!formik.touched.fk_producer && !!formik.errors.fk_producer
              }
            />
          )}
        />
        <Autocomplete
          name="models"
          value={selectedModelGroup || null}
          noOptionsText="Brak wyszukań"
          disabled={isLoading || !model_options.length}
          onChange={(e, new_value) => {
            setSelectedModelGroup(new_value);
            formik.setFieldValue("fk_model", "");
          }}
          options={model_options}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Model"
              error={!!formik.touched.fk_model && !!formik.errors.fk_model}
            />
          )}
        />
      </FlexBox>

      <TextField
        name="fk_model"
        label="Wariant"
        value={formik.values.fk_model || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.fk_model && !!formik.errors.fk_model}
        helperText={!!formik.touched.fk_model && formik.errors.fk_model}
        disabled={isLoading || !model_variants.length}
        select
      >
        {model_variants.map((variant) => (
          <MenuItem
            key={variant.id.toString()}
            value={variant.id}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ width: "100%" }} fontWeight={600}>
              {variant.name}
            </Typography>
            {productCompareAttributes(variant).map(
              (elem) =>
                elem.value && (
                  <Typography key={elem.label} sx={{ width: "100%" }}>
                    {elem.label}: {elem.value}
                  </Typography>
                )
            )}
          </MenuItem>
        ))}
      </TextField>
      <FlexBox gap={2} flexWrap="wrap">
        <TextField
          name="year"
          label="Rok Produkcji"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.year || ""}
          error={!!formik.touched.year && !!formik.errors.year}
          helperText={formik.touched.year && formik.errors.year}
          disabled={isLoading}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        />

        <TextField
          name="fk_drive_type"
          label="Rodzaj silnika"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fk_drive_type || ""}
          error={
            !!formik.touched.fk_drive_type && !!formik.errors.fk_drive_type
          }
          helperText={
            formik.touched.fk_drive_type && formik.errors.fk_drive_type
          }
          disabled={isLoading || !!uneditableValues.fk_drive_type}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          select
        >
          {productDirectories.drive_types.map((type) => (
            <MenuItem key={type.id.toString()} value={type.id}>
              {type.attributes.name}
            </MenuItem>
          ))}
        </TextField>
      </FlexBox>

      <FlexBox gap={2} flexWrap="wrap">
        <TextField
          name="fk_transmission"
          label="Skrzynia biegów"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fk_transmission || ""}
          error={
            !!formik.touched.fk_transmission && !!formik.errors.fk_transmission
          }
          helperText={
            formik.touched.fk_transmission && formik.errors.fk_transmission
          }
          disabled={isLoading || !!uneditableValues.fk_transmission}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          select
        >
          {productDirectories.transmissions.map((type) => (
            <MenuItem key={type.id.toString()} value={type.id}>
              {type.attributes.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="fk_drivetrain"
          label="Typ Napędu"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fk_drivetrain || ""}
          error={
            !!formik.touched.fk_drivetrain && !!formik.errors.fk_drivetrain
          }
          helperText={
            formik.touched.fk_drivetrain && formik.errors.fk_drivetrain
          }
          disabled={isLoading || !!uneditableValues.fk_drivetrain}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          select
        >
          {productDirectories.drivetrains.map((type) => (
            <MenuItem key={type.id.toString()} value={type.id}>
              {type.attributes.name}
            </MenuItem>
          ))}
        </TextField>
      </FlexBox>
      <FlexBox gap={2} flexWrap="wrap">
        <TextField
          name="engine_power"
          label="Moc Silnika"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
          }}
          value={formik.values.engine_power || ""}
          error={!!formik.touched.engine_power && !!formik.errors.engine_power}
          helperText={formik.touched.engine_power && formik.errors.engine_power}
          disabled={isLoading || !!uneditableValues.engine_power}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        />
        <TextField
          name="emission_co2"
          label="Emisja CO2"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          InputProps={{
            endAdornment: <InputAdornment position="end">g/km</InputAdornment>,
          }}
          value={formik.values.emission_co2 || ""}
          error={!!formik.touched.emission_co2 && !!formik.errors.emission_co2}
          helperText={formik.touched.emission_co2 && formik.errors.emission_co2}
          disabled={isLoading || !!uneditableValues.emission_co2}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        />
      </FlexBox>

      <FlexBox gap={2} flexWrap="wrap">
        {hasCombustionEngine() && (
          <TextField
            name="engine_capacity"
            label="Pojemność Silnika"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm3</InputAdornment>,
            }}
            value={formik.values.engine_capacity || ""}
            error={
              !!formik.touched.engine_capacity &&
              !!formik.errors.engine_capacity
            }
            helperText={
              formik.touched.engine_capacity && formik.errors.engine_capacity
            }
            disabled={isLoading || !!uneditableValues.engine_capacity}
            sx={{
              flex: 1,
              minWidth: 200,
            }}
          />
        )}

        {hasElectricEngine() && (
          <TextField
            name="battery_capacity"
            label="Pojemność Baterii"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">kW⋅h</InputAdornment>
              ),
            }}
            value={formik.values.battery_capacity || ""}
            error={
              !!formik.touched.battery_capacity &&
              !!formik.errors.battery_capacity
            }
            helperText={
              formik.touched.battery_capacity && formik.errors.battery_capacity
            }
            disabled={isLoading || !!uneditableValues.battery_capacity}
            sx={{
              flex: 1,
              minWidth: 200,
            }}
          />
        )}
      </FlexBox>

      <FlexBox gap={2} flexWrap="wrap">
        <TextField
          name="is_eu_approved"
          label="Pojazd przystosowany do wymogów Unii Europejskiej"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.is_eu_approved}
          error={
            !!formik.touched.is_eu_approved && !!formik.errors.is_eu_approved
          }
          helperText={
            formik.touched.is_eu_approved && formik.errors.is_eu_approved
          }
          disabled={isLoading}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          select
        >
          <MenuItem value={true}>Tak</MenuItem>
          <MenuItem value={false}>Nie</MenuItem>
        </TextField>
        <TextField
          name="is_historical"
          label="Pojazd zabytkowy"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.is_historical}
          error={
            !!formik.touched.is_historical && !!formik.errors.is_historical
          }
          helperText={
            formik.touched.is_historical && formik.errors.is_historical
          }
          disabled={isLoading}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          select
        >
          <MenuItem value={true}>Tak</MenuItem>
          <MenuItem value={false}>Nie</MenuItem>
        </TextField>
      </FlexBox>

      <TextField
        name="seats"
        label="Ilość miejsc"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.seats || ""}
        error={!!formik.touched.seats && !!formik.errors.seats}
        helperText={formik.touched.seats && formik.errors.seats}
        disabled={isLoading || !!uneditableValues.seats}
        select
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => (
          <MenuItem key={n.toString()} value={n}>
            {n}
          </MenuItem>
        ))}
      </TextField>

      <Divider />

      <Typography fontWeight="600">Nadwozie</Typography>

      <TextField
        name="fk_body_type"
        label="Typ nadwozia"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fk_body_type || ""}
        error={!!formik.touched.fk_body_type && !!formik.errors.fk_body_type}
        helperText={formik.touched.fk_body_type && formik.errors.fk_body_type}
        disabled={isLoading || !!uneditableValues.fk_body_type}
        select
      >
        {productDirectories.body_types.map((type) => (
          <MenuItem key={type.id.toString()} value={type.id}>
            {type.attributes.name}
          </MenuItem>
        ))}
      </TextField>

      <FlexBox gap={2} flexWrap="wrap">
        <TextField
          name="fk_body_color"
          label="Kolor"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fk_body_color || ""}
          error={
            !!formik.touched.fk_body_color && !!formik.errors.fk_body_color
          }
          helperText={
            formik.touched.fk_body_color && formik.errors.fk_body_color
          }
          disabled={isLoading}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          select
        >
          {productDirectories.body_colors.map((type) => (
            <MenuItem key={type.id.toString()} value={type.id}>
              {type.attributes.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          name="fk_color_type"
          label="Typ Lakieru"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fk_color_type || ""}
          error={
            !!formik.touched.fk_color_type && !!formik.errors.fk_color_type
          }
          helperText={
            formik.touched.fk_color_type && formik.errors.fk_color_type
          }
          disabled={isLoading}
          sx={{
            flex: 1,
            minWidth: 200,
          }}
          select
        >
          {productDirectories.color_types.map((type) => (
            <MenuItem key={type.id.toString()} value={type.id}>
              {type.attributes.name}
            </MenuItem>
          ))}
        </TextField>
      </FlexBox>

      <AddAddressDialog
        open={addAddressDialogOpen}
        onClose={handleAddAddressDialogClose}
      />

      <Divider />

      <FlexBox flexDirection="column">
        <Typography fontWeight="600">Wyposażenie</Typography>
        {chunk(productDirectories.equipments, 2).map((pair) => (
          <FlexBox key={cuid()} flexWrap="wrap">
            {pair.map((category) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 200,
                  gap: 1,
                  flex: 1,
                }}
                key={category.name}
              >
                <Typography mt={2} fontWeight="600">
                  {category.name}
                </Typography>
                {category.definitions.map((definition) => (
                  <FormControlLabel
                    sx={{
                      alignItems: "start",
                    }}
                    key={cuid()}
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          !!equipment?.[category.name]?.includes(definition)
                        }
                        onChange={(e, state) => {
                          if (state) {
                            setEquipment((equipment) => ({
                              ...equipment,
                              [category.name]: [
                                definition,
                                ...(equipment[category.name] || []),
                              ],
                            }));
                          } else {
                            setEquipment((equipment) => ({
                              ...equipment,
                              [category.name]: equipment[category.name].filter(
                                (elem) => elem !== definition
                              ),
                            }));
                          }
                        }}
                        sx={{ padding: 0, paddingLeft: 2, paddingRight: 0.5 }}
                      />
                    }
                    label={definition}
                  />
                ))}
              </Box>
            ))}
          </FlexBox>
        ))}
      </FlexBox>

      <Divider />

      <Typography fontWeight="600">Zdjęcia</Typography>

      <ImagesDropzone files={files} setFiles={setFiles} />

      <Divider />

      {!!sendProgress && (
        <LinearProgress value={sendProgress} variant="determinate" />
      )}

      <LoadingButton
        color="primary"
        variant="contained"
        loading={isLoading}
        onClick={formik.handleSubmit}
      >
        Dodaj Pojazd
      </LoadingButton>
    </Card>
  );
};

const initialValues = {
  fk_producer: "",
  fk_model: "",
  fk_drive_type: "",
  fk_body_color: "",
  engine_power: "",
  fk_body_type: "",
  emission_co2: "",
  engine_capacity: "",
  battery_capacity: "",
  fk_transmission: "",
  fk_drivetrain: "",
  fk_color_type: "",
  year: "",
  fk_company_address: "",
  vin: "",
  mileage: "",
  registration_number: "",
  seats: "",
  seller_price: "",
  is_historical: false,
  is_eu_approved: true,
  // helper vars for validation
  has_electric: false,
  has_combustion: false,
};

const validationSchema = yup.object().shape({
  fk_producer: yup.object().required(errorMessages.required),
  fk_model: yup.number().required(errorMessages.required),
  fk_drive_type: yup.number().required(errorMessages.required),
  engine_power: yup
    .number()
    .required(errorMessages.required)
    .typeError(errorMessages.numeric),
  emission_co2: yup
    .number()
    .required(errorMessages.required)
    .typeError(errorMessages.numeric),
  fk_body_type: yup.number().required(errorMessages.required),
  engine_capacity: yup
    .number()
    .typeError(errorMessages.numeric)
    .when("has_combustion", {
      is: true,
      then: yup
        .number()
        .typeError(errorMessages.numeric)
        .required(errorMessages.required),
    }),
  battery_capacity: yup
    .number()
    .typeError(errorMessages.numeric)
    .when("has_electric", {
      is: true,
      then: yup
        .number()
        .typeError(errorMessages.numeric)
        .required(errorMessages.required),
    }),
  fk_transmission: yup.number().required(errorMessages.required),
  fk_drivetrain: yup.number().required(errorMessages.required),
  year: yup
    .number()
    .min(1900, "Proszę podać poprawny rok")
    .max(2100, "Proszę podać poprawny rok")
    .typeError(errorMessages.numeric)
    .required(errorMessages.required),
  fk_body_color: yup.number().required(errorMessages.required),
  fk_color_type: yup.number().required(errorMessages.required),
  fk_company_address: yup.number().required(errorMessages.required),
  vin: yup.string().required(errorMessages.required),
  first_registration_date: yup.date().required(errorMessages.required),
  mileage: yup
    .number()
    .typeError(errorMessages.numeric)
    .required(errorMessages.required),
  registration_number: yup.string().required(errorMessages.required),
  seats: yup
    .number()
    .typeError(errorMessages.numeric)
    .integer()
    .required(errorMessages.required),
  seller_price: yup
    .number()
    .typeError(errorMessages.numeric)
    .required(errorMessages.required),
  is_historical: yup.boolean().required(errorMessages.required),
  is_eu_approved: yup.boolean().required(errorMessages.required),
});

const productCompareAttributes = (product) => [
  {
    label: "Typ Nadwozia",
    value: product.bodyType?.name,
  },
  {
    label: "Wariant",
    value: product.version,
  },
  {
    label: "Moc Silnika",
    value: `${product.engine_power} kW`,
  },
  {
    label: "Napęd",
    value: product.driveType?.name,
  },
];
export default AddProduct;
