import {
  Dialog,
  Box,
  IconButton,
  DialogContent,
  AppBar,
  Toolbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Styles for img and button components
const imgStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
  height: "100%",
  objectFit: "contain",
};

const FullscreenSingleImageViewer = ({ open, onClose, image }) => {
  return (
    <Dialog
      sx={{
        maxWidth: "100%",
        zIndex: 2000,
      }}
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="end"
            sx={{
              color: "white",
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "@media only screen and (max-width: 1024px)": {
            padding: "5px",
            button: {
              transform: "translate(0, -50%) !important",
            },
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            maxHeight: "100%",
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={imgStyle} loading="lazy" src={image.xl} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default FullscreenSingleImageViewer;
