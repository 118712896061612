import { useParams } from "react-router-dom";
import { makeDisplayElement, routes } from "components/about/Routes";
import { CircularProgress } from "@mui/material";
import AppLayout from "components/layout/AppLayout";
import Navbar from "components/navbar/Navbar";
import FlexBox from "components/FlexBox";
import Error404 from "../404";
import { getPageContent } from "clients/cms";
import { useState, useEffect } from "react";
import { useAppContext } from "contexts/app/AppContext";

const AboutPages = () => {
  const { page } = useParams();
  const { dispatch } = useAppContext();
  const [pageContent, setPageContent] = useState();

  useEffect(() => {
    if (page) {
      setPageContent();
      getPageContent(page)
        .then((response) => {
          if (response[0] !== null) {
            setPageContent(response[0].attributes);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [page]);

  if (!page || !pageContent) {
    return (
      <AppLayout navbar={<Navbar />}>
        <FlexBox
          flexDirection="column"
          minHeight="60vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </FlexBox>
      </AppLayout>
    );
  }

  if (pageContent instanceof Error) {
    return <Error404 />;
  }

  return (
    <AppLayout title={routes[page].display_name} navbar={<Navbar />}>
      {makeDisplayElement(pageContent)}
    </AppLayout>
  );
};

export default AboutPages;
