import TableRow from "components/TableRow";
import { Typography } from "@mui/material";
import { Fragment } from "react";
import { Skeleton } from "@mui/material";
import { H5 } from "components/Typography";
import PaymentRow from "./PaymentRow";

const PaymentsList = ({ payments }) => {
  return (
    <Fragment>
      <TableRow
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          padding: "0px 18px",
          background: "none",
        }}
        elevation={0}
      >
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Typ
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Referencja
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Cena
        </H5>
        <H5 color="grey.600" my="0px" mx={0.75} textAlign="left">
          Czy zapłacono
        </H5>
        <H5
          flex="0 0 0 !important"
          color="grey.600"
          px={2.75}
          py={0.5}
          my={0}
        ></H5>
      </TableRow>
      {!payments && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography flex="0 0 190px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="0 0 200px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="0 0 200px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="0 0 150px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            <Skeleton variant="text" />
          </Typography>
        </TableRow>
      )}

      {payments &&
        payments.map((row) => <PaymentRow key={row.id} item={row} />)}
      {!payments && payments.length === 0 && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography flex="0 0 190px !important" m={0.75} textAlign="left">
            Brak płatności
          </Typography>
        </TableRow>
      )}
    </Fragment>
  );
};
export default PaymentsList;
