import FlexBox from "components/FlexBox";
import { H5, H6 } from "components/Typography";
import {
  Autocomplete,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { SearchOutlinedIcon } from "../search-box/SearchBox";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getModels } from "clients/producers";
import { uniq } from "lodash-es";

const ProductFilterCard = ({
  formik,
  onSubmitForm,
  filterRanges,
  producers,
  bodytypes,
  drivetypes,
  isLading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [models, setModels] = useState([]);

  useEffect(() => {
    const producer = producers.find(
      (producer) => producer.slug == searchParams.get("filter_producer")
    );
    formik.setFieldValue("producer", producer);
    if (searchParams.get("filter_model")) {
      formik.setFieldValue("model", {
        label: searchParams.get("filter_model"),
        name: searchParams.get("filter_model"),
      });
    }
    const bodyType = bodytypes.find(
      (type) => type.id == searchParams.get("filter_bodytype")
    );
    formik.setFieldValue("id_body_type", bodyType);
    const driveType = drivetypes.find(
      (type) => type.id == searchParams.get("filter_drivetype")
    );
    formik.setFieldValue("id_drive_type", driveType);
    onSubmitForm();
  }, [searchParams]);

  useEffect(() => {
    if (formik.values.producer) {
      getModels(formik.values.producer.id).then((response) => {
        const [data] = response;
        setModels(data || []);
      });
    }
  }, [formik.values.producer]);

  const handleUpdateQueryParams = (field, value) => {
    if (value) {
      searchParams.set(field, value);
    } else {
      searchParams.delete(field);
    }
    setSearchParams(searchParams);
  };

  const handleChangeProducer = (e, new_value) => {
    formik.setFieldValue("producer", new_value);
    formik.setFieldValue("model", null);
    handleUpdateQueryParams("filter_producer", new_value?.slug);
    handleUpdateQueryParams("filter_model", null);
  };

  const handleChangeModel = (e, new_value) => {
    formik.setFieldValue("model", new_value);
    handleUpdateQueryParams("filter_model", new_value?.name);
  };

  const handleChangeBodyType = (e, new_value) => {
    formik.setFieldValue("id_body_type", new_value);
    handleUpdateQueryParams("filter_bodytype", new_value?.id);
  };

  const handleChangeDriveType = (e, new_value) => {
    formik.setFieldValue("id_drive_type", new_value);
    handleUpdateQueryParams("filter_drivetype", new_value?.id);
  };

  const handleReset = () => {
    setSearchParams({});
    formik.handleReset();
  };

  const model_options_1 =
    uniq(
      models.map((model) => ({
        label: model.attributes.name,
        name: model.attributes.name,
        slug: model.attributes.slug,
      }))
    ) || [];

  return (
    <Card sx={{ p: "18px 27px", overflow: "auto" }} elevation={1}>
      <form onSubmit={onSubmitForm}>
        <LoadingButton
          loading={isLading}
          loadingPosition="start"
          type="submit"
          variant="outlined"
          color="primary"
          startIcon={<SearchOutlinedIcon />}
          fullWidth
        >
          Szukaj auta
        </LoadingButton>
        <Divider sx={{ my: "1.5rem" }} />
        <H6 mb={2}>Marka pojazdu</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Autocomplete
            name="producer"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={handleChangeProducer}
            value={formik.values.producer || null}
            isOptionEqualToValue={(option, value) => option.slug === value.slug}
            options={producers.map((producer) => ({
              label: producer.attributes.name,
              slug: producer.attributes.slug,
              id: producer.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Marka pojazdu"
                error={!!formik.touched.producer && !!formik.errors.producer}
              />
            )}
          />
        </FlexBox>
        <Divider sx={{ my: "1.5rem" }} />
        <H6 mb={2}>Model pojazdu</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Autocomplete
            name="model"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={handleChangeModel}
            disabled={!model_options_1.length || formik.values.producer == null}
            value={formik.values.model || null}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            options={model_options_1}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Model pojazdu"
                error={!!formik.touched.model && !!formik.errors.model}
              />
            )}
          />
        </FlexBox>
        <Divider sx={{ my: "1.5rem" }} />
        <H6 mb={2}>Typ nadwozia</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Autocomplete
            name="id_body_type"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={handleChangeBodyType}
            value={formik.values.id_body_type || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={bodytypes.map((id_body_type) => ({
              label: id_body_type.attributes.name,
              id: id_body_type.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Typ nadwozia"
                error={
                  !!formik.touched.id_body_type && !!formik.errors.id_body_type
                }
              />
            )}
          />
        </FlexBox>
        <Divider sx={{ my: "1.5rem" }} />
        <H6 mb={2}>Rodzaj paliwa</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Autocomplete
            name="id_drive_type"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={handleChangeDriveType}
            value={formik.values.id_drive_type || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={drivetypes.map((id_drive_type) => ({
              label: id_drive_type.attributes.name,
              id: id_drive_type.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Rodzaj paliwa"
                error={
                  !!formik.touched.id_drive_type &&
                  !!formik.errors.id_drive_type
                }
              />
            )}
          />
        </FlexBox>
        <Divider sx={{ my: "1.5rem" }} />
        <H6 mb={2}>Cena</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <TextField
            name="min_price"
            placeholder={filterRanges.min_price.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.min_price || ""}
          />
          <H5 color="grey.600" px={1}>
            -
          </H5>
          <TextField
            name="max_price"
            placeholder={filterRanges.max_price.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.max_price || ""}
          />
        </FlexBox>
        <Divider sx={{ my: "1.5rem" }} />
        <H6 mb={2}>Rok produkcji</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <TextField
            name="min_year"
            placeholder={filterRanges.min_year.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.min_year || ""}
          />
          <H5 color="grey.600" px={1}>
            -
          </H5>
          <TextField
            name="max_year"
            placeholder={filterRanges.max_year.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.max_year || ""}
          />
        </FlexBox>
        <Divider sx={{ my: "1.5rem" }} />
        <H6 mb={2}>Przebieg</H6>
        <FlexBox justifyContent="space-between" alignItems="center">
          <TextField
            name="min_mileage"
            placeholder={filterRanges.min_mileage.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.min_mileage || ""}
          />
          <H5 color="grey.600" px={1}>
            -
          </H5>
          <TextField
            name="max_mileage"
            placeholder={filterRanges.max_mileage.toString()}
            type="number"
            size="small"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.max_mileage || ""}
          />
        </FlexBox>
        <Divider sx={{ my: "1.5rem" }} />
        <List>
          <ListItem
            sx={{ px: "0" }}
            type="reset"
            onClick={handleReset}
            // onChange={handleReset}
            secondaryAction={
              <IconButton>
                <ClearIcon sx={{ width: "0.75em", height: "0.75" }} />
              </IconButton>
            }
          >
            <ListItemText sx={{}} primary={"Wyczyść filtr"} />
          </ListItem>
        </List>
      </form>
    </Card>
  );
};

export default ProductFilterCard;
