import "nprogress/nprogress.css";
import nProgress from "nprogress";
import MuiTheme from "theme/MuiTheme";
import "simplebar/dist/simplebar.min.css";
// import OpenGraphTags from "utils/OpenGraphTags";
import React, { Fragment, useEffect, useState } from "react";
// import GoogleAnalytics from "utils/GoogleAnalytics";
import { AppProvider } from "contexts/app/AppContext";
import createEmotionCache from "createEmotionCache.js";
import { CacheProvider } from "@emotion/react"; // Client-side cache, shared for the whole session of the user in the browser.
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CookiesInformation from "components/sessions/CookiesInformation";
import useLoaded from "utils/useLoaded";

const clientSideEmotionCache = createEmotionCache();

const isTouchDevice = () => {
  if ("ontouchstart" in window) {
    return true;
  }
  return false;
};

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

nProgress.configure({
  showSpinner: false,
});

const App = ({
  children,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const isLoaded = useLoaded();

  const [showCookieInformation, setShowCookieInformation] = useState(false);
  // On load set state var from localstorage
  useEffect(() => {
    setShowCookieInformation(!window.localStorage.getItem("cookies_accepted"));
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CacheProvider value={emotionCache}>
        <header>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="preconnect" href="https://rsms.me/" />
          <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          {/* <GoogleAnalytics /> */}
          {/* <OpenGraphTags /> */}
        </header>
        <AppProvider>
          <MuiTheme>
            <Fragment>
              {/* <Component {...pageProps} /> */}
              {isLoaded && showCookieInformation && (
                <CookiesInformation on_accept={setShowCookieInformation} />
              )}
            </Fragment>
            <DndProvider backend={backendForDND}>
              <Fragment>{children}</Fragment>
            </DndProvider>
          </MuiTheme>
        </AppProvider>
      </CacheProvider>
    </LocalizationProvider>
  );
};
export default App;
