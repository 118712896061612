import AddressList from "components/address/AddressList";
import DashboardLayout from "components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import Place from "@mui/icons-material/Place";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { getCompanyAddresses } from "clients/company-addresses";
import { Link } from "react-router-dom";

const AddressListPage = () => {
  const [data, setData] = useState();

  useEffect(() => {
    getCompanyAddresses()
      .then((response) => {
        const [data] = response;
        setData(data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        title="Moje adresy"
        icon={Place}
        button={
          <Link to="/address/add">
            <Button
              color="primary"
              sx={{ bgcolor: "primary.light", px: "2rem" }}
            >
              Dodaj adres
            </Button>
          </Link>
        }
        navigation={<CustomerDashboardNavigation />}
      />
      <AddressList addresses={data} />
    </DashboardLayout>
  );
};

export default AddressListPage;
