import { sortProductImages } from "utils/utils";
import apiClient from "./apiClient";

export const getOffers = async () => {
  let [data, validations, errors] = await apiClient.get("/offers");
  if (data) {
    data = data.map((offer) => {
      if (offer.products) {
        offer.products = offer.products.map((product) =>
          sortProductImages(product)
        );
      }
      return offer;
    });
  }

  return [data, validations, errors];
};

export const saveOffer = async (payload, idOffer) => {
  if (parseInt(idOffer)) {
    const response = await apiClient.patch("/offers/" + idOffer, payload);
    return response;
  }

  return await apiClient.post("/offers", payload);
};
