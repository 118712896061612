class DispatchService {
  constructor() {
    this._dispatch = null;
  }

  set dispatch(dispatch) {
    this._dispatch = dispatch;
  }

  get dispatch() {
    return this._dispatch;
  }
}

const dispatchService = new DispatchService();

export default dispatchService;
