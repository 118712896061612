import FlexBox from "components/FlexBox";
import AppLayout from "components/layout/AppLayout";
import Login from "components/sessions/Login";
import { useAppContext } from "contexts/app/AppContext";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const { state } = useAppContext();
  const { user } = state;
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    // If user is logged in go back 1 page
    if (!!user.user) {
      navigate(-1);
    }
  }, []);

  const handleAterSuccessfulLogin = () => {
    // if next query param is defined navigate to next
    if (searchParams.get("next")) {
      navigate(searchParams.get("next"));
    } else {
      navigate("/");
    }
  };

  return (
    <AppLayout>
      <FlexBox
        flexDirection="column"
        minHeight="40vh"
        alignItems="center"
        justifyContent="center"
        margin="40px 0 40px 0"
      >
        <Login on_success={handleAterSuccessfulLogin} />
      </FlexBox>
    </AppLayout>
  );
};

export default LoginPage;
