import apiClient from "./apiClient";

export const getNotifications = async () => {
  return await apiClient.get("notifications");
};

export const getNotification = async (id) => {
  return await apiClient.get(`notifications/${id}`);
};

export const deleteNotification = async (id) => {
  return await apiClient.delete(`notifications/${id}`);
};
