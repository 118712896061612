import CustomerDashboardLayout from "components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import CarIcon from "@mui/icons-material/DirectionsCar";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import { getSingleProduct } from "clients/products";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CircularProgress } from "@mui/material";
import FlexBox from "components/FlexBox";
import ProductImagesManagement from "components/product-management/ProductImagesManagement";
import PricePrediction from "components/product-management/PricePrediction";
import ProductDetailsSpecification from "components/product-details-page/ProductDetailsSpecification";
import ProductDetailsEquipment from "components/product-details-page/ProductDetailsEquipment";
import ProductManagement from "components/product-management/ProductManagement";

const ProductPage = () => {
  const { id } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    getSingleProduct(id).then((response) => {
      const [data] = response;
      setData(data);
    });
  }, []);

  const handleSetNewImages = (new_images) => {
    setData((data) => ({
      ...data,
      images: new_images,
      attributes: { ...data.attributes, images: new_images },
    }));
  };

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        title={
          data
            ? `${data.model.producer.name} ${data.model.name}`
            : "Widok pojazdu"
        }
        icon={CarIcon}
        navigation={<CustomerDashboardNavigation />}
      />
      {!data && (
        <Card
          sx={{
            minHeight: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
      {!!data && (
        <FlexBox gap="10px" flexDirection="column">
          <ProductImagesManagement
            product={data}
            setImages={handleSetNewImages}
          />
          <ProductManagement product={data} setProduct={setData} />
          <PricePrediction product={data} />
          <Card sx={{ padding: 2 }}>
            <ProductDetailsSpecification product={data} />
          </Card>
          <Card sx={{ padding: 2 }}>
            <ProductDetailsEquipment product={data} />
          </Card>
        </FlexBox>
      )}
    </CustomerDashboardLayout>
  );
};
export default ProductPage;
