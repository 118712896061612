import FlexBox from "components/FlexBox";
import Signup from "components/sessions/Signup";
import AppLayout from "components/layout/AppLayout";
import Navbar from "components/navbar/Navbar";
import React, { useEffect } from "react";
import { useTheme } from "@mui/system";
import { getPageContentWithFallback } from "clients/cms";
import CircularProgress from "@mui/material/CircularProgress";

const SignUpPage = () => {
  const { palette } = useTheme();

  const [pagecontent, set_pagecontent] = React.useState();
  const [checkbox_pagecontent, set_checkbox_pagecontent] = React.useState();
  // Get page content from page api
  useEffect(() => {
    getPageContentWithFallback("signup")
      .then((response) => {
        const [pagecontent] = response;
        set_pagecontent(pagecontent);
      })
      .catch((error) => {
        console.error(error);
      });
    getPageContentWithFallback("signup-checkboxes")
      .then((response) => {
        const [checkbox_pagecontent] = response;
        set_checkbox_pagecontent(checkbox_pagecontent);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (
    pagecontent == null ||
    pagecontent instanceof Error ||
    checkbox_pagecontent == null ||
    checkbox_pagecontent instanceof Error
  ) {
    return (
      <AppLayout navbar={<Navbar />}>
        <FlexBox
          flexDirection="column"
          minHeight="60vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </FlexBox>
      </AppLayout>
    );
  }

  // Encase signup flexbox in SaleLayout template
  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox
        flexDirection="column"
        minHeight="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <h2 style={{ color: palette.grey[700] }}>{pagecontent.title}</h2>
        <Signup
          pagecontent={pagecontent}
          checkbox_pagecontent={checkbox_pagecontent}
        />
      </FlexBox>
    </AppLayout>
  );
};

export default SignUpPage;
