import styled from "@emotion/styled";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import FullscreenImageViewer from "./FullscreenImageViewer";
import FavouriteBox from "components/product-cards/FavouriteBox";
import { useAppContext } from "contexts/app/AppContext";

// Styles for image slider
const imgStyle = {
  cursor: "pointer",
  width: "100%",
  minHeight: "200px",
  objectFit: "stretch",
  background:
    "url(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif) no-repeat center",
};

const ImageContainer = styled(Box)(() => ({
  maxHeight: "600px",
  marginBottom: "20px",
  display: "flex",
}));

const ButtonScroll = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  background: theme.palette.grey[600],
  borderRadius: 0,
  "&:hover": {
    background: theme.palette.grey[800],
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const ProductDetailsImageSlider = ({ product }) => {
  const [fullScreenImageViewerOpen, setFullScreenImageViewerOpen] =
    useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [swipeXstart, setSwipeXstart] = useState();
  const [swipeXend, setSwipeXend] = useState();

  const { state } = useAppContext();
  const { user } = state.user;

  const handleImageViewerClose = () => {
    setFullScreenImageViewerOpen(false);
  };

  function handleTouchStart(e) {
    setSwipeXstart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setSwipeXend(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (
      swipeXstart - swipeXend > 150 &&
      currentImage < product.images.length - 1
    ) {
      // do your stuff here for left swipe
      // moveSliderRight();
      setCurrentImage((currentImage) => currentImage + 1);
    }

    if (swipeXstart - swipeXend < -150 && currentImage > 0) {
      // do your stuff here for right swipe
      // moveSliderLeft();
      setCurrentImage((currentImage) => currentImage - 1);
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
        marginTop: 2,
      }}
    >
      <ButtonScroll
        onClick={(_) => {
          if (currentImage > 0) {
            setCurrentImage((currentImage) => currentImage - 1);
          }
        }}
      >
        <ArrowBackIosNewIcon />
      </ButtonScroll>
      <ButtonScroll
        onClick={(_) => {
          if (currentImage < product.images.length - 1) {
            setCurrentImage((currentImage) => currentImage + 1);
          }
        }}
        sx={{ right: 0, transform: "translate(50%, -50%) !important" }}
      >
        <ArrowForwardIosIcon />
      </ButtonScroll>
      <ImageContainer>
        {user && (
          <Box
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <FavouriteBox product_id={product.id} />
          </Box>
        )}
        {product.images.map(
          (image, idx) =>
            idx === currentImage && (
              <img
                key={idx.toString()}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                style={imgStyle}
                onClick={() => {
                  setFullScreenImageViewerOpen(true);
                }}
                loading="lazy"
                src={image.m}
              />
            )
        )}
        {/* <img
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={imgStyle}
          onClick={() => {
            setFullScreenImageViewerOpen(true);
          }}
          loading="lazy"
          src={product.images[currentImage].m}
        /> */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 2,
            borderRadius: 2,
            padding: 0.3,
            backgroundColor: "#0000005e",
          }}
        >
          <Typography
            sx={{
              color: "white",
            }}
          >
            {currentImage + 1}/{product.images.length}
          </Typography>
        </Box>
      </ImageContainer>

      <FullscreenImageViewer
        onClose={handleImageViewerClose}
        images={product.images}
        open={fullScreenImageViewerOpen}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
      />
    </Box>
  );
};
export default ProductDetailsImageSlider;
