import React from "react";
import { getFilterRanges } from "clients/search-product";
import { getProducers } from "clients/producers";
import { getBodyTypes } from "clients/body-types";
import { getDriveTypes } from "clients/drive-types";
import { useState, useEffect } from "react";
import { CircularProgress, Card } from "@mui/material";
import Search from "components/search/Search";
import NavbarLayout from "components/layout/NavbarLayout";

const CarsCatalogPage = () => {
  const [filterRanges, setFilterRanges] = useState();
  const [producers, setProducers] = useState();
  const [bodytypes, setBodytypes] = useState();
  const [drivetypes, setDrivetypes] = useState();

  useEffect(() => {
    getFilterRanges().then((response) => {
      const [data] = response;
      setFilterRanges(data?.attributes || fallback_filter_ranges);
    });
    getProducers()
      .then((response) => {
        const [data] = response;
        if (data !== null) {
          setProducers(
            data.map((producer) => ({
              ...producer,
              label: producer.name,
            }))
          );
        }
      })
      .catch((error) => console.error(error));
    getBodyTypes()
      .then((response) => {
        const [data] = response;
        if (data !== null) {
          setBodytypes(
            data.map((type) => ({
              ...type,
              label: type.name,
            }))
          );
        }
      })
      .catch((error) => console.error(error));
    getDriveTypes()
      .then((response) => {
        const [data] = response;
        if (data !== null) {
          setDrivetypes(
            data.map((type) => ({
              ...type,
              label: type.name,
            }))
          );
        }
      })
      .catch((error) => console.error(error));
  }, []);

  if (!filterRanges || !producers || !bodytypes || !drivetypes) {
    return (
      <NavbarLayout>
        <Card
          sx={{
            width: "100%",
            minHeight: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      </NavbarLayout>
    );
  }
  return (
    <NavbarLayout>
      <Search
        filterRanges={filterRanges}
        producers={producers}
        bodytypes={bodytypes}
        drivetypes={drivetypes}
      />
    </NavbarLayout>
  );
};
export const fallback_filter_ranges = {
  min_price: 0,
  max_price: 100000,
  min_year: 1950,
  max_year: 2022,
  min_mileage: 0,
  max_mileage: 1000000,
};

export default CarsCatalogPage;
