import { Close, ContactsOutlined } from "@mui/icons-material";
import { Dialog, IconButton, InputAdornment, TextField } from "@mui/material";
import FlexBox from "components/FlexBox";
import { DateTimePicker } from "@mui/x-date-pickers";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { errorMessages } from "utils/constants";
import { createAuction } from "clients/auctions";
import { format } from "date-fns";

const AuctionDialog = ({ open, onClose, productId }) => {
  const initialValues = {
    id_product: productId,
    price: "",
    valid_to: null,
  };

  const validationSchema = yup.object({
    price: yup
      .number()
      .required(errorMessages.required)
      .min(0, errorMessages.greater_then_0)
      .typeError(errorMessages.numeric),
    valid_to: yup
      .date()
      .required(errorMessages.required)
      .min(new Date(), errorMessages.min_tomorrow)
      .typeError(errorMessages.required),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.valid_to = format(values.valid_to, "yyyy-MM-dd HH:mm:ss");
      const [data] = await createAuction(values);
      onClose(data);
      formik.setSubmitting(false);
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      sx={{ zIndex: 1000 }}
      PaperProps={{ sx: { padding: 1 } }}
    >
      <FlexBox justifyContent="end">
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <Close />
        </IconButton>
      </FlexBox>
      <FlexBox flexDirection="column" gap={2}>
        <TextField
          name="price"
          label="Cena startowa"
          value={formik.values.price}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          InputProps={{
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
          error={!!formik.touched.price && !!formik.errors.price}
          helperText={!!formik.touched.price && formik.errors.price}
        />
        <DateTimePicker
          renderInput={(props) => (
            <TextField
              {...props}
              error={!!formik.touched.valid_to && !!formik.errors.valid_to}
              helperText={!!formik.touched.valid_to && formik.errors.valid_to}
            />
          )}
          label="Koniec Aucji (CET)"
          value={formik.values.valid_to}
          onChange={(newValue) => {
            formik.setFieldValue("valid_to", newValue);
          }}
          mask="__/__/____ __:__ _M"
          inputFormat="dd/MM/yyyy hh:mm a"
          minDate={new Date()}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
          loading={formik.isSubmitting}
        >
          Utwórz
        </LoadingButton>
      </FlexBox>
    </Dialog>
  );
};
export default AuctionDialog;
