import ProductCardList from "components/products/ProductCard1List";
import ProductFilterCard from "components/products/ProductFilterCard";
import ProductLoadingList from "components/products/ProductLoadingList";
import Sidenav from "components/sidenav/Sidenav";
import useWindowSize from "hooks/useWindowSize";
import FilterList from "@mui/icons-material/FilterList";
import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSearchProducts } from "clients/search-product";
import { useFormik } from "formik";
import { useParams, useSearchParams } from "react-router-dom";
import navigationService from "utils/navigationService";

const Search = (props) => {
  const { filterRanges, producers, bodytypes, drivetypes } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const width = useWindowSize();
  const isTablet = width < 1025;

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const { paramProducer } = useParams();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      search: null,
      producer: null,
      model: null,
      id_body_type: null,
      id_drive_type: null,
      min_price: null,
      max_price: null,
      min_year: null,
      max_year: null,
      min_mileage: null,
      max_mileage: null,
    },
    onSubmit: (values) => {
      setIsLoading(true);
      let new_values = { ...values };

      //producer
      let url_filter_producer = searchParams.get("filter_producer");
      if (url_filter_producer) {
        new_values.producer = url_filter_producer;
      } else {
        new_values.producer = null;
        formik.setFieldValue("producer", null);
      }

      //model
      let url_filter_model = searchParams.get("filter_model");
      if (url_filter_model) {
        new_values.model = url_filter_model;
      } else {
        new_values.model = null;
        formik.setFieldValue("model", null);
      }
      //bodytype
      let url_filter_bodytype = searchParams.get("filter_bodytype");
      if (url_filter_bodytype) {
        new_values.id_body_type = url_filter_bodytype;
      } else {
        new_values.id_body_type = null;
        formik.setFieldValue("id_body_type", null);
      }
      //drivetype
      let url_filter_drivetype = searchParams.get("filter_drivetype");
      if (url_filter_drivetype) {
        new_values.id_drive_type = url_filter_drivetype;
      } else {
        new_values.id_drive_type = null;
        formik.setFieldValue("id_drive_type", null);
      }
      //auction
      let is_auction = searchParams.get("auction");
      if (is_auction) {
        new_values.is_auction = is_auction;
      }
      getSearchProducts(new_values, currentPage)
        .then((response) => {
          const [data, validations, error, meta] = response;
          setData(data || []);
          setPagination(meta || []);
          setIsLoading(false);
        })
        .catch((error) => {});
    },
  });

  const onSubmitForm = (...params) => {
    setCurrentPage(1);
    return formik.handleSubmit(...params);
  };

  const changePage = (e, page) => {
    setCurrentPage(page);
    formik.submitForm();
  };

  useEffect(() => {
    if (paramProducer) {
      searchParams.set("filter_producer", paramProducer);
      setSearchParams(searchParams);
      navigationService.navigation(`/cars?${searchParams}`);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid
        item
        md={3}
        xs={12}
        sx={{
          "@media only screen and (max-width: 1024px)": { display: "none" },
        }}
      >
        <ProductFilterCard
          formik={formik}
          onSubmitForm={onSubmitForm}
          filterRanges={filterRanges}
          producers={producers}
          bodytypes={bodytypes}
          drivetypes={drivetypes}
          isLading={isLoading}
        />
      </Grid>

      <Grid item md={9} xs={12}>
        {isTablet && (
          <Sidenav
            position="left"
            handle={
              <IconButton
                sx={{
                  marginLeft: "auto",
                  display: "block",
                }}
              >
                <FilterList fontSize="small" />
              </IconButton>
            }
          >
            <ProductFilterCard
              formik={formik}
              onSubmitForm={onSubmitForm}
              filterRanges={filterRanges}
              producers={producers}
              bodytypes={bodytypes}
              drivetypes={drivetypes}
              isLading={isLoading}
            />
          </Sidenav>
        )}
        {isLoading && <ProductLoadingList />}
        {!isLoading && (
          <ProductCardList
            products={data}
            pagination={pagination}
            changePage={changePage}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Search;
