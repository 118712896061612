import { Skeleton } from "@mui/material";

const ProductDetailsImageSliderSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      height={500}
      width="100%"
      sx={{ marginTop: 2 }}
    />
  );
};
export default ProductDetailsImageSliderSkeleton;
