import { Container, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { getActiveBanner } from "clients/banner";
import "./bannerStyle.css";

const Banner = () => {
  const [dataBanner, setDataBanner] = useState();

  useEffect(() => {
    getActiveBanner()
      .then((response) => {
        const [data] = response;
        setDataBanner(data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      {!!dataBanner && (
        <div className="head-text">
          <div>
            <img
              style={{ width: "100%", height: "100%" }}
              src={dataBanner.attributes?.image}
              alt="background"
            />
          </div>
          <div className="text-on-image">
            {!!dataBanner.header_text && (
              <Typography variant="h2"> {dataBanner.header_text} </Typography>
            )}
            {!!dataBanner.button_label && (
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: dataBanner.color }}
                href={dataBanner.button_url}
                size="medium"
              >
                <Typography style={{ color: "white" }}>
                  {dataBanner.button_label}
                </Typography>
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
