import { Box } from "@mui/material";
import FlexBox from "components/FlexBox";

const SpecificationImage = ({ definition }) => {
  return (
    <FlexBox justifyContent="center" p={1}>
      <Box maxWidth={600}>
        <img
          style={{
            width: "100%",
          }}
          src={definition.value.image}
        />
      </Box>
    </FlexBox>
  );
};
export default SpecificationImage;
