import {
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Drawer,
  Button,
  Divider,
} from "@mui/material";
import NavLink from "components/nav-link/NavLink";
import { Fragment } from "react";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import navigationService from "utils/navigationService";
import CloseIcon from "@mui/icons-material/Close";

const HeaderMenu = () => {
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile) {
    return (
      <Fragment>
        <Box>
          <IconButton
            size="large"
            onClick={() => {
              setMenuDrawerOpen(true);
            }}
          >
            <MenuIcon sx={{ fontSize: "1.5em" }} />
          </IconButton>
        </Box>
        <Drawer
          anchor="left"
          open={menuDrawerOpen}
          onClose={() => {
            setMenuDrawerOpen(false);
          }}
          sx={{
            zIndex: 2500,
          }}
          PaperProps={{
            sx: {
              maxWidth: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={() => {
                setMenuDrawerOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {headermenu_navigations.map((elem) => (
            <Fragment key={elem.label}>
              <Divider />
              <Button
                size="large"
                sx={{ paddingRight: 20, paddingLeft: 20 }}
                onClick={() => {
                  navigationService.navigation(elem.href);
                }}
              >
                {elem.label}
              </Button>
              <Divider />
            </Fragment>
          ))}
        </Drawer>
      </Fragment>
    );
  } else {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {headermenu_navigations.map((elem) => (
          <Box sx={{ padding: 2 }} key={elem.label}>
            <NavLink style={{ fontWeight: 500 }} href={elem.href}>
              {elem.label}
            </NavLink>
          </Box>
        ))}
      </Box>
    );
  }
};
export default HeaderMenu;

const headermenu_navigations = [
  // {
  //   label: "Test1",
  //   href: "/other",
  // },
  {
    label: "Kalkulator Oszczędności",
    href: "/savings",
  },
];
