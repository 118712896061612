import apiClient from "./apiClient";

export const getCompanyAddresses = async () => {
  const response = await apiClient.get("/company-addresses");
  return response;
};

export const getCompanyAddress = async (idAddress) => {
  const response = await apiClient.get("/company-addresses/" + idAddress);
  return response;
};

export const saveCompanyAddress = async (payload, idAddress) => {
  if (parseInt(idAddress)) {
    const response = await apiClient.patch(
      "/company-addresses/" + idAddress,
      payload
    );
    return response;
  }

  const response = await apiClient.post("/company-addresses", payload);
  return response;
};
