import { Checkbox, FormControlLabel } from "@mui/material";

const CheckBoxOffer = ({ item, handleOnChange }) => {
  return (
    <FormControlLabel
      sx={{
        mt: 1,
      }}
      control={<Checkbox />}
      onChange={(e, new_value) => handleOnChange(item, new_value)}
      label="Dodaj do oferty"
    />
  );
};
export default CheckBoxOffer;
