import FlexBox from "components/FlexBox";
import { useAppContext } from "contexts/app/AppContext";
import {
  Grid,
  Box,
  Divider,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { notificationTypes } from "./NotificationTypes";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/system";
import { deleteNotification } from "clients/notifications";
import React, { useState } from "react";
import NotificationPopup from "./NotificationPopup";
import { getNotification } from "clients/notifications";

const NotificationSidePanel = ({ close_action }) => {
  const theme = useTheme();
  const { state, dispatch } = useAppContext();
  const [dataNotifications, setDataNotifications] = useState(
    state.user.messages
  );

  const notifications = state.user.messages;

  // make notification from api response data
  const MakeNotification = (notification) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [fontWeightt, changefontWeight] = useState(true);
    // get style from NotificationTypes
    let notificationStyle = notificationTypes[notification.attributes.type];
    // Default fallback
    if (!notificationStyle) {
      notificationStyle = notificationTypes.default;
    }

    const handleReadNotificationClick = async (idMessage) => {
      getNotification(idMessage)
        .then((response) => {
          const [data] = response;
          if (data !== null) {
            dispatch({
              type: "SHOW_SNACKBAR",
              payload: {
                text: "Powiadomienie zostało przeczytane",
                type: "success",
              },
            });
          }
          Object.keys(dataNotifications).map((key, index) => {
            const object = dataNotifications[key];
            if (object.data.id === notification.data.id) {
              dataNotifications[key].read_at = new Date();
              dispatch({
                type: "SET_NOTIFICATIONS",
                payload: dataNotifications,
              });
            }
          });
        })
        .catch((error) => console.error(error));
    };

    const handleBoldChange = () => {
      changefontWeight(!fontWeightt);
    };

    const handleNotificationClick = async () => {
      Object.keys(dataNotifications).map((key, index) => {
        const object = dataNotifications[key];
        if (object.data.id === notification.data.id) {
          delete dataNotifications[key];
        }
      });
      deleteNotification(notification.id);
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: dataNotifications,
      });
    };

    // show loading while data is being fetched
    if (!notifications) {
      return (
        <Box
          width="380px"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box
        key={notification.id.toString()}
        sx={{
          padding: "10px",
        }}
      >
        <Divider />

        <FlexBox
          color={notificationStyle.color}
          sx={{
            alignItems: "center",
          }}
        >
          {notificationStyle.icon}
          <Typography variant="h6">
            {notificationStyle.title ? notificationStyle.title : ""}
          </Typography>
          <IconButton
            sx={{
              marginLeft: "auto",
            }}
            onClick={handleNotificationClick}
          >
            <CloseIcon />
          </IconButton>
        </FlexBox>
        {!!notification.read_at ? (
          <Typography
            sx={{ fontWeight: "normal" }}
            align="justify"
            variant="body2"
          >
            {notification.data.message}
          </Typography>
        ) : (
          <Button
            onClick={() => {
              handleReadNotificationClick(notification.id);
              handleBoldChange();
            }}
            disabled={!fontWeightt}
          >
            <Typography
              sx={{ fontWeight: fontWeightt ? "bold" : "normal" }}
              align="justify"
              variant="body2"
            >
              {notification.data.message}
            </Typography>
          </Button>
        )}
        <Grid container spacing={5} alignItems="center">
          <Grid item>
            {!!notification.data.action && (
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "15px",
                  flexDirection: "row-reverse",
                }}
              >
                <a href={notification.data.action}>
                  <Button variant="outlined" color="primary" size="small">
                    Odwiedź stronę
                  </Button>
                </a>
              </Box>
            )}
          </Grid>

          <Grid item>
            {!!notification.data.detail && (
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "15px",
                  flexDirection: "row-reverse",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleOpen}
                >
                  Więcej szczegółów
                </Button>
              </Box>
            )}
          </Grid>

          {open && (
            <NotificationPopup idMessage={notification.id} status_open={open} />
          )}
        </Grid>
        <Divider />
      </Box>
    );
  };

  return (
    <Box width="100%">
      <Box overflow="auto">
        <FlexBox
          alignItems="center"
          m="0px 20px"
          height="74px"
          color="primary.main"
        >
          <NotificationsNoneIcon color="inherit" />
          <Box fontWeight={600} fontSize="16px" ml={1}>
            Powiadomienia - {Object.keys(notifications).length}
          </Box>
          <Box
            sx={{
              marginLeft: "auto",
              display: "none",
              [theme.breakpoints.down("md")]: {
                display: "block !important",
              },
            }}
          >
            <Button variant="outlined" color="primary" onClick={close_action}>
              Zamknij
            </Button>
          </Box>
        </FlexBox>

        <Divider />
        {Object.keys(notifications).map((key, index) => {
          return MakeNotification(notifications[key]);
        }, [])}
        {Object.keys(notifications).length === 0 && (
          <FlexBox
            sx={{
              flexDirection: "column",
              color: "grey.700",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <SentimentSatisfiedAltIcon fontSize="large" />
            <Typography>Brak nowych powiadomień</Typography>
          </FlexBox>
        )}
      </Box>
    </Box>
  );
};

NotificationSidePanel.defaultProps = {
  toggleSidenav: () => {},
};
export default NotificationSidePanel;
