import { useAppContext } from "contexts/app/AppContext";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import FlexBox from "components/FlexBox";
import { sendInspectorPrices } from "clients/email-verification";
import {
  Card,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import BazarButton from "components/BazarButton";
import { errorMessages } from "utils/constants";

// Style for container
const StyledCard = styled(({ children, ...rest }) => (
  <Card {...rest}>{children}</Card>
))(({ theme }) => ({
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  ".content": {
    padding: "3rem 3.75rem 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 1rem 0px",
    },
  },
}));

const VerifyInspector = (props) => {
  const { id_customer } = props;

  //const { dispatch } = useAppContext();
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = (values) => {
    setIsLoading(true);
    // set customer id from props
    values.id_customer = id_customer;

    sendInspectorPrices(values)
      .then((response) => {
        const [data, validations, errors] = response;
        dispatch({
          type: "USER_LOADED",
          payload: {},
        });

        if (validations !== null) {
          validations.forEach((error) => {
            dispatch({
              type: "SHOW_SNACKBAR",
              payload: { text: error.title, type: "error" },
            });
          });
        }

        if (data !== null) {
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: {
              text: "Weryfikacja pozytywna.",
              type: "success",
            },
          });
          navigate("/auth/welcome");
        }
      })
      .catch((err) => {
        console.warn(err);
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: { text: "Wystąpił błąd", type: "error" },
        });
      });
    setIsLoading(false);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: handleFormSubmit,
      validationSchema: formSchema,
    });

  if (state?.user?.loading) {
    return (
      <FlexBox
        flexDirection="column"
        minHeight="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </FlexBox>
    );
  }

  return (
    <StyledCard elevation={3}>
      <FlexBox
        className="content"
        sx={{
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          name="price_inspection"
          onChange={handleChange}
          label="Cena za wykonanie inspekcji"
          value={values.price_inspection || ""}
          onBlur={handleBlur}
          variant="outlined"
          size="small"
          error={!!touched.price_inspection && !!errors.price_inspection}
          helperText={touched.price_inspection && errors.price_inspection}
          InputProps={{
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
        />
        <TextField
          name="price_km"
          onChange={handleChange}
          label="Cena dojazdowa za kilometr"
          value={values.price_km || ""}
          onBlur={handleBlur}
          variant="outlined"
          size="small"
          error={!!touched.price_km && !!errors.price_km}
          helperText={touched.price_km && errors.price_km}
          InputProps={{
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
        />
        <Divider />
        <BazarButton
          variant="contained"
          color="primary"
          sx={{
            marginBottom: "40px",
          }}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Utwórz Konto
        </BazarButton>
      </FlexBox>
    </StyledCard>
  );
};

const initialValues = {
  id_customer: "",
  price_inspection: "",
  price_km: "",
};

const formSchema = yup.object().shape({
  price_inspection: yup
    .number()
    .required(errorMessages.required)
    .min(0, errorMessages.greater_then_0)
    .typeError(errorMessages.numeric),
  price_km: yup
    .number()
    .required(errorMessages.required)
    .min(0, errorMessages.greater_then_0)
    .typeError(errorMessages.numeric),
});
export default VerifyInspector;
