import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import MobileNavigationBar from "components/mobile-navigation/MobileNavigationBar";
import Sticky from "components/sticky/Sticky";
import Topbar from "components/topbar/Topbar";
import React, { Fragment, useCallback, useState } from "react";
import { Divider, Snackbar, Alert, Box } from "@mui/material";
import { useAppContext } from "contexts/app/AppContext";
import Navbar from "components/navbar/Navbar";

const AppLayout = ({ children, title = "Carteon", navbar = <Navbar /> }) => {
  const { state, dispatch } = useAppContext();
  const { snackbar } = state;
  const [isFixed, setIsFixed] = useState(false);

  const handleClose = () => {
    dispatch({
      type: "HIDE_SNACKBAR",
      payload: {},
    });
  };

  const toggleIsFixed = useCallback((fixed) => {
    setIsFixed(fixed);
  }, []);

  return (
    <Fragment>
      <header>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </header>

      {/*<Topbar />*/}

      <Snackbar
        sx={{
          zIndex: 10000,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar?.type}
          sx={{ width: "100%" }}
        >
          {snackbar?.text}
        </Alert>
      </Snackbar>

      <Header />

      <Divider />

      {navbar}

      <Sticky fixedOn={0} onSticky={toggleIsFixed}>
        {!isFixed ? <Box display="none" /> : <Header />}
      </Sticky>

      {children}

      <MobileNavigationBar />
      <Footer />
    </Fragment>
  );
};

export default AppLayout;
