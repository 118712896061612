import CustomerDashboardLayout from "components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useEffect, useState } from "react";
import { getProducers } from "clients/producers";
import { getProductDirectories } from "clients/products";
import AddProduct from "components/products/AddProduct";
import { Card, CircularProgress } from "@mui/material";

const AddProductPage = () => {
  const [productDirectories, setProductDirectories] = useState();
  const [producers, setProducers] = useState();

  const fetchProductDirectories = () => {
    getProductDirectories().then((response) => {
      const [data] = response;
      setProductDirectories(data);
    });
  };

  const fetchProducers = () => {
    getProducers().then((response) => {
      const [data] = response;
      setProducers(data);
    });
  };

  useEffect(() => {
    fetchProducers();
    fetchProductDirectories();
  }, []);

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        title="Dodaj Produkt"
        icon={AddCircleOutlineOutlinedIcon}
        navigation={<CustomerDashboardNavigation />}
      />
      {producers && productDirectories && (
        <AddProduct
          producers={producers}
          productDirectories={productDirectories}
        />
      )}
      {(!producers || !productDirectories) && (
        <Card
          sx={{
            minHeight: 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
    </CustomerDashboardLayout>
  );
};
export default AddProductPage;
