import { useAppContext } from "contexts/app/AppContext";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { Card } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { getVerificationCustomer } from "clients/email-verification";
import VerifyTrader from "./VerifyTrader";
import VerifyInspector from "./VerifyInspector";

const StyledCard = styled(({ children, ...rest }) => (
  <Card {...rest}>{children}</Card>
))(({ theme }) => ({
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const EmailVerification = (props) => {
  const { dispatch } = useAppContext();
  // token from /[token] url
  const { token } = props;

  const [id_customer, set_id_customer] = React.useState();
  const [id_company, set_id_company] = React.useState();
  const [claim, setClaim] = React.useState();
  const [token_err, set_token_err] = React.useState(false);

  useEffect(() => {
    if (token != null) {
      // send post request with token from url
      getVerificationCustomer(token)
        .then(([id_customer, id_company, claim]) => {
          set_id_customer(id_customer);
          set_id_company(id_company);
          setClaim(claim);
        })
        .catch((err) => {
          set_token_err(true);
          console.warn(err);
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: { text: "Wystąpił błąd", type: "error" },
          });
        });
    }
  }, [token]);

  // Show loading when token is null, id_customer is null or id_company is null,
  // this happens when data is still being fetched
  if (token === null || id_customer === undefined || id_company === undefined) {
    return (
      <StyledCard
        sx={{
          minHeight: "40vh",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
        elevation={3}
      >
        {token_err ? (
          <h3>Token jest nieważny lub wygasł</h3>
        ) : (
          <CircularProgress />
        )}
      </StyledCard>
    );
  }
  // Display verification for inspector or buyer/seller based on claims
  if (claim && claim.includes("inspector")) {
    return <VerifyInspector id_customer={id_customer} />;
  } else {
    return <VerifyTrader id_customer={id_customer} id_company={id_company} />;
  }
};
export default EmailVerification;
