import { getInspections } from "../../clients/inspections";
import { useEffect, useState, Fragment } from "react";
import {
  Card,
  Typography,
  IconButton,
  Skeleton,
  Box,
  Chip,
} from "@mui/material";
import FlexBox from "../FlexBox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import {
  determineInspectionStatusColor,
  determineInspectionStatus,
} from "../../clients/inspections";

const InspectionList = () => {
  const [data, setData] = useState();

  // get inspections list from api
  useEffect(() => {
    getInspections().then((response) => {
      const [data] = response;
      setData(data);
    });
  }, []);

  const makeInspectionRow = (row) => {
    return (
      <Card
        key={row.id}
        sx={{
          display: "flex",
          padding: "10px",
          margin: "10px 0 10px 0",
          flexDirection: "column",
        }}
      >
        <FlexBox>
          <FlexBox
            sx={{
              flex: 1,
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography whiteSpace="pre" m={0.75} textAlign="left">
              {row.relationships.product.relationships.model.relationships
                .producer.attributes.name +
                " " +
                row.relationships.product.relationships.model.attributes.name}
            </Typography>
            <Box>
              <Chip
                label={determineInspectionStatus(row.status)}
                sx={{
                  backgroundColor: determineInspectionStatusColor(row.status),
                }}
              />
              <Typography
                sx={{ float: "right" }}
                whiteSpace="pre"
                m={0.75}
                textAlign="left"
              >
                {format(new Date(row.attributes.scheduled_date), "dd.MM.yyyy")}
              </Typography>
            </Box>
          </FlexBox>
          <FlexBox>
            <Typography whiteSpace="pre" textAlign="right" color="grey.600">
              <Link to={`/inspections/${row.id}`}>
                <IconButton>
                  <ArrowForwardIosIcon fontSize="small" color="inherit" />
                </IconButton>
              </Link>
            </Typography>
          </FlexBox>
        </FlexBox>
      </Card>
    );
  };

  if (!data) {
    return (
      <Fragment>
        <Card
          sx={{
            display: "flex",
            padding: "10px",
            margin: "10px 0 10px 0",
          }}
        >
          <FlexBox
            sx={{
              flex: 1,
            }}
          >
            <Skeleton variant="text" width="100%" />
          </FlexBox>
        </Card>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Typography variant="h6">Rozszerzone</Typography>
      {data.filter((elem) => elem.attributes.fk_inspection_type === 2)
        .length === 0 && (
        <Fragment>
          <Card
            sx={{
              display: "flex",
              padding: "10px",
              margin: "10px 0 10px 0",
            }}
          >
            <FlexBox
              sx={{
                flex: 1,
              }}
            >
              <Typography whiteSpace="pre" m={0.75} textAlign="left">
                Brak zleconych inspekcji
              </Typography>
            </FlexBox>
          </Card>
        </Fragment>
      )}
      {data
        .filter((elem) => elem.attributes.fk_inspection_type === 2)
        .map((row) => makeInspectionRow(row))}
      <Typography variant="h6">Podstawowe</Typography>
      {data.filter((elem) => elem.attributes.fk_inspection_type === 1)
        .length === 0 && (
        <Fragment>
          <Card
            sx={{
              display: "flex",
              padding: "10px",
              margin: "10px 0 10px 0",
            }}
          >
            <FlexBox
              sx={{
                flex: 1,
              }}
            >
              <Typography whiteSpace="pre" m={0.75} textAlign="left">
                Brak inspekcji podstawowych
              </Typography>
            </FlexBox>
          </Card>
        </Fragment>
      )}
      {data
        .filter((elem) => elem.attributes.fk_inspection_type === 1)
        .map((row) => makeInspectionRow(row))}
    </Fragment>
  );
};
export default InspectionList;
