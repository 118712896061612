import { Typography, Box } from "@mui/material";
import { Fragment } from "react";
import { chunk } from "lodash-es";
import FlexBox from "components/FlexBox";
import cuid from "cuid";

const ProductDetailsEquipment = ({ product }) => {
  return (
    <Fragment>
      <Typography variant="h5" fontWeight={600} mt={2}>
        Wysposażenie
      </Typography>
      {chunk(Object.entries(product.equipment), 2).map((column) => (
        <FlexBox key={cuid()}>
          {column.map(([category, values]) => (
            <Box
              key={category}
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: 200,
                gap: 1,
                flex: 1,
              }}
            >
              <Typography variant="h6" mt={2}>
                {category}
              </Typography>
              {values.map((equipment) => (
                <Typography key={equipment} pl={1}>
                  ∙{equipment}
                </Typography>
              ))}
            </Box>
          ))}
        </FlexBox>
      ))}
    </Fragment>
  );
};
export default ProductDetailsEquipment;
