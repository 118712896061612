import apiClient from "./apiClient";
import axiosClient from "./axiosClient";

export const markAsWorkingDay = async (date) => {
  return await apiClient.post("/inspector-availabilities", {
    day: date,
  });
};

export const deleteWorkingDay = async (id) => {
  return await axiosClient.delete(`/inspector-availabilities/${id}`);
};

export const getAvailabilitiesCalendar = async (year, month) => {
  return await apiClient.get(`/inspector-availabilities/${year}/${month}`);
};
