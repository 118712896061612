import TableRow from "components/TableRow";
import East from "@mui/icons-material/East";
import { IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react"; // component props interface
import Price from "../Price";

const PaymentRow = ({ item }) => {
  const renderType = function (type, product) {
    switch (type) {
      case "order":
        return "Zamowienie: " + product;
      case "inspection":
        return "Inspekcja: " + product;
      default:
        return "Inny";
    }
  };

  const yesNo = function (value) {
    if (value === 0) return "Nie";
    else return "Tak";
  };

  const yesNoColor = function (value) {
    if (value === 0) return "red";
    else return "black";
  };

  return (
    <Link to={"/payments/" + item.id}>
      <TableRow
        sx={{
          my: "1rem",
          padding: "6px 18px",
        }}
      >
        <Typography
          textAlign="center"
          color="grey.600"
          sx={{
            flex: "0 0 0 !important",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        ></Typography>
        <Typography m={0.75} textAlign="left">
          {renderType(item.attributes.type, item.attributes.product_model)}
        </Typography>
        <Typography m={0.75} textAlign="left">
          {item.attributes.subject}
        </Typography>

        <Price currency={"PLN"} value={item.attributes.price} />

        <Typography color={yesNoColor(item.attributes.is_paid)}>
          {yesNo(item.attributes.is_paid)}
        </Typography>
        <Typography
          textAlign="center"
          color="grey.600"
          sx={{
            flex: "0 0 0 !important",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <IconButton>
            <East fontSize="small" color="inherit" />
          </IconButton>
        </Typography>
      </TableRow>
    </Link>
  );
};

export default PaymentRow;
