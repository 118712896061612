export const userInitialState = {
  user:
    typeof window !== "undefined" && window.localStorage.getItem("user")
      ? JSON.parse(window.localStorage.getItem("user"))
      : null,
  loading: false,
  showNotifications: false,
  messages: null,
  messagesInterval: null,
};
export const userReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return { ...state, user: action.payload, loading: false };
    case "LOGOUT_USER":
      return { ...state, user: null };
    case "USER_LOADING":
      return { ...state, loading: true };
    case "USER_LOADED":
      return { ...state, loading: false };
    case "SHOW_NOTIFICATIONS":
      return { ...state, showNotifications: true };
    case "HIDE_NOTIFICATIONS":
      return { ...state, showNotifications: false };
    case "SET_NOTIFICATIONS":
      return { ...state, messages: action.payload };
    case "SET_INTERVAL":
      return { ...state, messagesInterval: action.payload };
    case "SET_MENU_QUANTITIES":
      return { ...state, counters: action.payload };
    case "SET_MENU_INTERVAL":
      return { ...state, countersInterval: action.payload };
    case "SET_FAVOURITES":
      return {
        ...state,
        user: {
          ...state.user,
          attributes: {
            ...state.user.attributes,
            favorite_products: action.payload,
          },
        },
      };
    default:
      return { ...state };
  }
};
