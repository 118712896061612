import {
  Card,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Tooltip,
} from "@mui/material";
import {
  getPricePrediction,
  getPricePredictionChart,
} from "clients/price-prediction";
import { Fragment, useEffect, useState } from "react";
import Chart from "./Chart";
import { changePrice } from "clients/products";
import { LoadingButton } from "@mui/lab";
import FlexBox from "components/FlexBox";

const PricePrediction = ({ product }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priceAccepted, setPriceAccepted] = useState(false);
  const [pricePredictionData, setPricePredictionData] = useState();
  const [pricePredictionChartData, setPricePredictionChartData] = useState();
  const [showAdditionalData, setShowAdditionalData] = useState(false);

  useEffect(() => {
    getPricePrediction(product.id).then((response) => {
      const [data] = response;
      setPricePredictionData(data);
    });
    getPricePredictionChart(product.id).then((response) => {
      const [data] = response;
      setPricePredictionChartData(data);
    });
  }, []);

  const chartByYear = pricePredictionChartData?.charts?.chartByYear;
  const chartByMileage = pricePredictionChartData?.charts?.chartByMileage;
  const chartDemand = pricePredictionChartData?.charts?.chartDemand;
  const chartSale = pricePredictionChartData?.charts?.chartSale;
  const chartExchangeRateEUR =
    pricePredictionChartData?.charts?.chartExchangeRateEUR;

  return (
    <Fragment>
      {!!pricePredictionData && (
        <Fragment>
          {!!pricePredictionChartData && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FlexBox justifyContent="end">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      setShowAdditionalData(
                        (showAdditionalData) => !showAdditionalData
                      )
                    }
                  >
                    {showAdditionalData
                      ? "Ukryj dodatkowe dane"
                      : "Pokaż dodatkowe dane"}
                  </Button>
                </FlexBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <Chart
                  data={chartByYear.chart}
                  label={chartByYear.title}
                  xLabel={chartByYear.xLabel}
                  yLabel={chartByYear.yLabel}
                  xSuffix={" km"}
                  ySuffix={" zł"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Chart
                  data={chartByMileage.chart}
                  label={chartByMileage.title}
                  xLabel={chartByMileage.xLabel}
                  yLabel={chartByMileage.yLabel}
                  xSuffix={" lat"}
                  ySuffix={" zł"}
                />
              </Grid>
              {showAdditionalData && (
                <Fragment>
                  <Grid item xs={12} md={6}>
                    <Chart
                      data={chartDemand.chart}
                      label={chartDemand.title}
                      xLabel={chartDemand.xLabel}
                      yLabel={chartDemand.yLabel}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Chart
                      data={chartSale.chart}
                      label={chartSale.title}
                      xLabel={chartSale.xLabel}
                      yLabel={chartSale.yLabel}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Chart
                      data={chartExchangeRateEUR.chart}
                      label={chartExchangeRateEUR.title}
                      xLabel={chartExchangeRateEUR.xLabel}
                      yLabel={chartExchangeRateEUR.yLabel}
                      ySuffix={" zł"}
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
          )}
          <Card sx={{ padding: 1, display: "flex", gap: 1 }}>
            <TextField
              label="Proponowana Cena"
              color="primary"
              value={pricePredictionData.priceCorrect.toFixed(2)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">PLN</InputAdornment>
                ),
              }}
              sx={{
                flex: 1,
              }}
            />
            <Tooltip
              title={
                priceAccepted ||
                Math.abs(product.price - pricePredictionData.priceCorrect) < 1
                  ? "Cena Zaakceptowana"
                  : ""
              }
            >
              <span>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                  disabled={
                    priceAccepted ||
                    Math.abs(product.price - pricePredictionData.priceCorrect) <
                      1
                  }
                  onClick={async () => {
                    setIsLoading(true);
                    await changePrice(
                      product.id,
                      pricePredictionData.priceCorrect.toFixed(2) || 0
                    );
                    setPriceAccepted(true);
                    setIsLoading(false);
                  }}
                >
                  Akceptuj Cenę
                </LoadingButton>
              </span>
            </Tooltip>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};
export default PricePrediction;
