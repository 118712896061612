import { Card, Skeleton, Typography } from "@mui/material";
import { H5 } from "../../components/Typography";
import { useEffect } from "react";
import {
  getTrackingDetails,
  getWaypointsDetails,
  determineTransportStatus,
} from "../../clients/orders";
import { useState } from "react";
import FlexBox from "../../components/FlexBox";
import Map from "../../components/orders/TransportMap.jsx";

const TransportTracking = ({ id_order }) => {
  const [waypoints, setWaypoints] = useState();
  const [trackingData, setTrackingData] = useState();

  useEffect(() => {
    getTrackingDetails(id_order).then((response) => {
      const [data] = response;
      setTrackingData(data);
    });
    getWaypointsDetails(id_order).then((response) => {
      const [data] = response;
      setWaypoints(data);
    });
  }, []);

  if (!trackingData) {
    return (
      <Card
        sx={{
          width: "100%",
          mt: "30px",
          padding: "20px 30px",
        }}
      >
        <H5 mt={0} mb={2}>
          Śledzenie transportu
        </H5>
        <Skeleton variant="rectangular" height={150} />
      </Card>
    );
  }

  return (
    <Card
      sx={{
        width: "100%",
        mt: "30px",
        padding: "20px 30px",
      }}
    >
      <H5 mt={0} mb={2}>
        Śledzenie transportu
      </H5>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Status Transportu
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {determineTransportStatus(trackingData)}
        </Typography>
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Całkowita trasa transportu
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {trackingData.distance} km
        </Typography>
      </FlexBox>
      <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography fontSize="14px" color="grey.600">
          Przewidywany czas transportu
        </Typography>
        <Typography fontSize="14px" fontWeight="blod">
          {portionTime(trackingData.transport_duration * 3600)}
        </Typography>
      </FlexBox>
      {!!waypoints && (
        <Map
          markers={makeMarkers(trackingData)}
          paths={waypoints.ways}
          currentPosition={trackingData.transport_position}
        />
      )}
    </Card>
  );
};

const makeMarkers = (trackingData) => {
  let data = [
    {
      label: "Punkt Startowy",
      position: trackingData.geopoint_from,
    },
    {
      label: "Punkt Dostawy",
      position: trackingData.geopoint_to,
    },
  ];
  if (trackingData.geopoint_first_terminal) {
    data.push({
      label: "Terminal startowy",
      position: trackingData.geopoint_first_terminal,
    });
  }
  if (trackingData.geopoint_second_terminal) {
    data.push({
      label: "Terminal Końcowy",
      position: trackingData.geopoint_second_terminal,
    });
  }
  return data;
};

const portionTime = (durationInSeconds) => {
  let days = durationInSeconds / 3600 / 24;
  let hours = durationInSeconds / 3600;
  let mins = (durationInSeconds % 3600) / 60;
  let secs = (mins * 60) % 60;

  days = Math.trunc(days);
  hours = Math.trunc(hours - days * 24);
  mins = Math.trunc(mins);

  if (!days && !hours && !mins && !secs) {
    return "None";
  }

  if (days) {
    return `${days} dni ${hours} hr`;
  } else if (hours) {
    if (mins) {
      return secs ? `${hours} hr ${mins} min` : `${hours} hr & ${mins} min`;
    } else {
      return secs ? `${hours} hr` : `${hours} hr`;
    }
  } else {
    if (mins) {
      return secs ? `${mins} min` : `${mins} min`;
    } else {
      return secs ? `${secs} sec` : `None`;
    }
  }
};

export default TransportTracking;
