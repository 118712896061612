import FlexBox from "components/FlexBox";
import AppLayout from "components/layout/AppLayout";
import Navbar from "components/navbar/Navbar";
import React from "react";
import EmailVerification from "components/email-verification/EmailVerification";
import { useTheme } from "@mui/system";
import { useParams } from "react-router-dom";

const EmailVerificationPage = () => {
  const { palette } = useTheme();
  const { token } = useParams();
  // Encase signup flexbox in SaleLayout template
  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox
        flexDirection="column"
        minHeight="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <h2
          style={{
            color: palette.grey[700],
            margin: 5,
            textAlign: "center",
            maxWidth: 500,
          }}
        >
          Do ukończenia rejestracji potrzebne jest jeszcze kilka informacji
        </h2>
        <EmailVerification token={token} />
      </FlexBox>
      ;
    </AppLayout>
  );
};

export default EmailVerificationPage;
