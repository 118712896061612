import { Paper, Button, Box, Typography, Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { getPageContentWithFallback } from "clients/cms";
import { useAppContext } from "contexts/app/AppContext";
import { useState } from "react";

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  zIndex: 2000,
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "30px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column !important",
  },
  ".content": {
    [theme.breakpoints.down("md")]: {
      textAlign: "justify",
    },
  },
  ".content_container": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  ".btn_container": {
    marginRight: "20px",
    marginLeft: "20px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flex: 1,
      marginTop: "20px",
    },
  },
  ".btn_container button": {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const CookiesInformation = (props) => {
  const { on_accept } = props;
  const { dispatch } = useAppContext();
  const [pageContent, setPageContent] = useState();
  useEffect(() => {
    getPageContentWithFallback("parent-page")
      .then((response) => {
        if (response[0] !== null)
          setPageContent(response[0].attributes.cookie_information);
      })
      .catch((error) => {
        console.warn(error);
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: { text: "Wystąpił błąd", type: "error" },
        });
      });
  }, []);
  return (
    <StyledPaper elevation={5}>
      <Box className="content_container">
        <Typography variant="h6">Informacja o plikach Cookie</Typography>
        <Box className="content">
          {pageContent !== undefined ? (
            pageContent
          ) : (
            <Skeleton variant="text" />
          )}
        </Box>
      </Box>
      <Box className="btn_container">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            on_accept(false);
            window.localStorage.setItem("cookies_accepted", true);
          }}
        >
          OK
        </Button>
      </Box>
    </StyledPaper>
  );
};
export default CookiesInformation;
