import apiClient from "./apiClient";
import axiosClient from "./axiosClient";

export const getInspectionStepDetails = async (id) => {
  return await apiClient.get(`/inspection-results/steps/${id}`);
};

export const postInspectionResults = async (data) => {
  return await apiClient.post("/inspection-results/steps", data);
};

export const postQuestionResults = async (
  id_inspection,
  id_inspection_step,
  answers,
  image = null
) => {
  let data = {
    id_inspection: id_inspection,
    id_inspection_step: id_inspection_step,
    result: { ...answers },
  };
  if (image) {
    data.result = {
      ...data.result,
      [image.field]: image.data,
    };
  }
  return await apiClient.post("/inspection-results", data);
};

export const finishInspection = async (inspection_id) => {
  return await apiClient.post("/inspection-results/finish", {
    id_inspection: inspection_id,
  });
};

export const postQuestionVideo = async (
  id_inspection,
  id_inspection_step,
  field,
  video
) => {
  const formData = new FormData();
  formData.append("id_inspection", id_inspection);
  formData.append("id_inspection_step", id_inspection_step);
  formData.append(field, video);
  return await axiosClient.post("/inspection-results", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
