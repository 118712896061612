import { Box, Typography } from "@mui/material";
import { Grid } from "@mui/material";

const ProductDetailsSpecification = ({ product }) => {
  const specification = [
    ["Producent", product.model.producer.name],
    ["Model", product.model.name],
    ["Rok produkcji", product.year],
    ["Przebieg", product.mileage, "km"],
    ["Silnik", product.driveType.name],
    ["Moc silnika", product.engine_power, "kW"],
    ["Emisja co2", product.emission_co2, "g/km"],
    ["Pojemność silnika", product.engine_capacity, "cm3"],
    ["Pojemność baterii", product.battery_capacity, "kW⋅h"],
    ["Napęd", product.drivetrain.name],
    ["Skrzynia biegów", product.transmission.name],
    ["Typ nadwozia", product.model.bodyType.name],
  ];

  return (
    <Box sx={{ flex: 1, minWidth: 300 }}>
      <Typography variant="h5" fontWeight={600} mb={2}>
        Specyfikacja
      </Typography>
      <Grid container spacing={2}>
        {specification.map(
          (spec) =>
            !!spec?.[1] && (
              <Grid item key={spec[0]} xs={6} md={4}>
                <Box>
                  <Typography variant="subtitle1">{spec[0]}</Typography>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {spec[1]}
                    {!!spec[2] && ` ${spec[2]}`}
                  </Typography>
                </Box>
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
};
export default ProductDetailsSpecification;
