import { Box, Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SimplifiedProductCard = ({ product, elevation = 2 }) => {
  return (
    <Link to={`/car/${product.slug}`} target="_blank">
      <Card
        elevation={elevation}
        sx={{
          width: 200,
        }}
      >
        <Box
          sx={{
            width: 200,
            height: 120,
          }}
        >
          <img
            src={product.images[0].s}
            alt="product_photo"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box sx={{ padding: 1 }}>
          <Typography>
            {product.model.producer.name} {product.model.name}
          </Typography>
          <Typography>{product.year}</Typography>
        </Box>
      </Card>
    </Link>
  );
};
export default SimplifiedProductCard;
