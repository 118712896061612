import FlexBox from "components/FlexBox";
import { Fragment } from "react";
import ProductDetailsSpecification from "./ProductDetailsSpecification";
import ProductOfferBox from "./ProductOfferBox";
import { useNavigate } from "react-router-dom";
import EnergySavingsLeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import { Button } from "@mui/material";
import IsEcoChip from "components/product-cards/IsEcoChip";

const ProductDetails = ({ product, pageContent }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <FlexBox justifyContent="space-between" mb={1}>
        {product.hasOwnProperty("is_eco") && (
          <IsEcoChip is_eco={product.is_eco} />
        )}
        <Button
          startIcon={<EnergySavingsLeafOutlinedIcon />}
          variant="outlined"
          color="primary"
          onClick={() => {
            const searchParams = new URLSearchParams();
            searchParams.set("model1", product.model.id);
            navigate({
              pathname: "/savings",
              search: `&${searchParams}`,
            });
          }}
        >
          Eko porównanie
        </Button>
      </FlexBox>
      <FlexBox gap={4} flexWrap="wrap">
        <ProductDetailsSpecification product={product} />
        <ProductOfferBox product={product} pageContent={pageContent} />
      </FlexBox>
    </Fragment>
  );
};
export default ProductDetails;
