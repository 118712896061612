import BazarTextField from "components/BazarTextField";
import FlexBox from "components/FlexBox";
import { H3, H6 } from "components/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Card, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { getAccessToken } from "clients/access-tokens";
import { useAppContext } from "contexts/app/AppContext";
import { errorMessages } from "utils/constants";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";

const fbStyle = {
  background: "#3B5998",
  color: "white",
};
const googleStyle = {
  background: "#4285F4",
  color: "white",
};
const StyledCard = styled(({ children, passwordVisibility, ...rest }) => (
  <Card {...rest}>{children}</Card>
))(({ theme, passwordVisibility }) => ({
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  ".content": {
    textAlign: "center",
    padding: "3rem 3.75rem 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 1rem 0px",
    },
  },
  ".passwordEye": {
    color: passwordVisibility
      ? theme.palette.grey[600]
      : theme.palette.grey[400],
  },
  ".facebookButton": {
    marginBottom: 10,
    "&:hover": fbStyle,
    ...fbStyle,
  },
  ".googleButton": {
    "&:hover": googleStyle,
    ...googleStyle,
  },
  ".agreement": {
    marginTop: 12,
    marginBottom: 24,
  },
}));

const Login = ({ on_success }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useAppContext();

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const [data, validations, _] = await getAccessToken(values);
    if (data) {
      Cookies.set("userAuth", data?.attributes?.token, {
        expires: 7,
        sameSite: "strict",
      });
      window.localStorage.setItem("user", JSON.stringify({ ...data }));
      dispatch({
        type: "LOGIN_USER",
        payload: { ...data },
      });
      dispatch({
        type: "SHOW_SNACKBAR",
        payload: {
          text: "Witaj, " + data.attributes.first_name,
          type: "success",
        },
      });
      if (typeof on_success === "function") {
        on_success();
      }
    }
    setIsLoading(false);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      onSubmit: handleFormSubmit,
      initialValues,
      validationSchema: formSchema,
    });
  return (
    <StyledCard elevation={3} passwordVisibility={passwordVisibility}>
      <form className="content" onSubmit={handleSubmit}>
        <H3 textAlign="center" mb={1}>
          Logowanie
        </H3>

        <BazarTextField
          mb={1.5}
          name="email"
          label="Email"
          placeholder="exmple@mail.com"
          variant="outlined"
          size="small"
          type="email"
          fullWidth
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email || ""}
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
        />

        <BazarTextField
          mb={2}
          name="password"
          label="Hasło"
          placeholder="*********"
          autoComplete="on"
          type={passwordVisibility ? "text" : "password"}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                type="button"
                onClick={togglePasswordVisibility}
              >
                {passwordVisibility ? (
                  <Visibility className="passwordEye" fontSize="small" />
                ) : (
                  <VisibilityOff className="passwordEye" fontSize="small" />
                )}
              </IconButton>
            ),
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password || ""}
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password}
        />

        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={isLoading}
          fullWidth
          sx={{
            mb: "1.65rem",
            height: 44,
          }}
        >
          Zaloguj się
        </LoadingButton>

        <FlexBox justifyContent="center" alignItems="center" my="1.25rem">
          <Box>Nie posiadasz konta?</Box>
          <Link to="/signup">
            <H6 ml={1} borderBottom="1px solid" borderColor="grey.900">
              Zarejestruj się
            </H6>
          </Link>
        </FlexBox>
      </form>
      <FlexBox justifyContent="center" bgcolor="grey.200" py={2.5}>
        Zapomniałeś hasła?
        <Link to="/password-reset">
          <H6 ml={1} borderBottom="1px solid" borderColor="grey.900">
            Resetowanie hasła
          </H6>
        </Link>
      </FlexBox>
    </StyledCard>
  );
};

const initialValues = {
  email: "",
  password: "",
};
const formSchema = yup.object().shape({
  email: yup
    .string()
    .email(errorMessages.invalid_email)
    .required(errorMessages.required),
  password: yup.string().required(errorMessages.required),
});
export default Login;
