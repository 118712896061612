import { Box, Typography, Skeleton } from "@mui/material";
import FlexBox from "components/FlexBox";

const ProductDetailsHeaderSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h5" fontWeight={700} minWidth="40ch">
          <Skeleton variant="text" />
        </Typography>
        <Typography fontWeight={600}>
          <Skeleton variant="text" />
        </Typography>
      </Box>
      <FlexBox>
        <Box>
          <Typography
            variant="h5"
            fontWeight={700}
            minWidth="10ch"
            color="primary"
          >
            <Skeleton variant="text" />
          </Typography>
        </Box>
      </FlexBox>
    </Box>
  );
};
export default ProductDetailsHeaderSkeleton;
