import { Card, CircularProgress, Container } from "@mui/material";
import { getProducers } from "clients/producers";
import AppLayout from "components/layout/AppLayout";
import SavingsCalculator from "components/savings/SavingsCalculator";
import { useEffect, useState } from "react";

const SavingsPage = () => {
  const [producers, setProducers] = useState();

  useEffect(() => {
    getProducers().then((response) => {
      const [data] = response;
      setProducers(data);
    });
  }, []);

  return (
    <AppLayout>
      <Container
        maxWidth="md"
        sx={{
          mt: 2,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!producers && (
          <Card
            sx={{
              minHeight: "40vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Card>
        )}
        {!!producers && (
          <Card
            sx={{
              padding: 3,
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <SavingsCalculator producers={producers} />
          </Card>
        )}
      </Container>
    </AppLayout>
  );
};

export default SavingsPage;
