import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import React from "react";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { OrderInspection } from "../../components/inspections/OrderInspection";
import { useSearchParams } from "react-router-dom";

const OrderInspectionPage = () => {
  const [searchParams, _] = useSearchParams();
  const product_slug = searchParams.get("product_slug");

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={FactCheckOutlinedIcon}
        title="Zleć inspeckję"
        navigation={<CustomerDashboardNavigation />}
      />
      <OrderInspection product_slug={product_slug} />
    </DashboardLayout>
  );
};

export default OrderInspectionPage;
