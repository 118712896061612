import { Dialog } from "@mui/material";
import AddressEditor from "components/address/AddressEditor";

const AddAddressDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <AddressEditor
        values={{
          name: "",
          address1: "",
          address2: "",
          post_code: "",
          place: "",
          country: "Polska",
        }}
        onCloseHandler={onClose}
      />
    </Dialog>
  );
};
export default AddAddressDialog;
