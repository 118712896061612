import BazarCard from "components/BazarCard";
import navbarNavigations from "data/navbarNavigations";
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react"; // component props interface
import { useState } from "react";
import NavbarColumn from "./NavbarColumn";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { getMenusItems } from "clients/search-menus";
import navigationService from "utils/navigationService";

// const common css style
const navLinkStyle = {
  cursor: "pointer",
  marginRight: "2rem",
  transition: "color 150ms ease-in-out",
  "&:hover": {
    color: "primary.main",
  },
  "&:last-child": {
    marginRight: "0",
  },
}; // style components

const NavBarWrapper = styled(BazarCard)(({ theme }) => ({
  display: "block",
  position: "relative",
  height: "60px",
  borderRadius: "0px",
}));
const InnerContainer = styled(Container)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  overflowX: "auto",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const Navbar = () => {
  const theme = useTheme();
  const [openMap, setOpenMap] = useState({});
  const [menuData, setMenuData] = useState();

  useEffect(() => {
    // todo fetch menu data
    // setMenuData(navbarNavigations);
    getMenusItems()
      .then((response) => {
        const [data] = response;
        if (data !== null) {
          setMenuData(data);
        }
      })
      .catch((error) => {});
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const makeColumns = (data) => {
    const columns = [];
    let temp_column = [];
    data.forEach((entry, idx) => {
      temp_column.push(entry);
      if ((idx + 1) % 5 === 0) {
        columns.push(temp_column);
        temp_column = [];
      }
    });
    if (!!temp_column.length) {
      columns.push(temp_column);
    }

    // if mobile show all data as one column
    if (isMobile) {
      let combined_column = [];
      data.forEach((entry) => {
        combined_column.push(entry);
      });
      return [combined_column];
    }

    return columns;
  };

  return (
    <NavBarWrapper
      elevation={2}
      hoverEffect={false}
      onMouseLeave={() => {
        if (!isMobile) {
          setOpenMap({});
        }
      }}
    >
      <InnerContainer>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "20px",
          }}
        >
          {menuData &&
            menuData.map((elem) => (
              <Button
                key={elem.title}
                onMouseEnter={() => {
                  {
                    elem.submenu1 &&
                      setOpenMap({
                        [elem.title]: true,
                      });
                  }
                }}
                onClick={() => {
                  {
                    if (elem.href) {
                      navigationService.navigation(elem.href);
                    } else {
                      elem.submenu1 &&
                        setOpenMap({
                          [elem.title]: true,
                        });
                    }
                  }
                }}
                sx={{
                  flexGrow: 1,
                  minWidth: "10ch",
                }}
              >
                {elem.title}
              </Button>
            ))}
        </Box>
      </InnerContainer>
      {menuData &&
        menuData.map((elem) => (
          <Card
            key={elem.title}
            elevation={3}
            sx={{
              position: "absolute",
              left: 0,
              width: "100%",
              display: !!openMap[elem.title] ? "flex" : "none",
              flexDirection: isMobile ? "column" : "row",
              zIndex: 2500,
              padding: 2,
              flexWrap: "wrap",
            }}
          >
            {isMobile && (
              <Box>
                <IconButton
                  onClick={() => {
                    setOpenMap({});
                  }}
                  sx={{ float: "right" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            {elem.submenu1 &&
              makeColumns(elem.submenu1).map((sublist, idx) => (
                <NavbarColumn
                  key={idx.toString()}
                  links={sublist}
                  title={!idx && elem.submenu1_title}
                  isMobile={isMobile}
                />
              ))}
            {elem.submenu2 &&
              makeColumns(elem.submenu2).map((sublist, idx) => (
                <NavbarColumn
                  key={idx.toString()}
                  links={sublist}
                  title={!idx && elem.submenu2_title}
                />
              ))}
          </Card>
        ))}
    </NavBarWrapper>
  );
};

export default Navbar;
