import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Divider,
} from "@mui/material";
import { getCompanyAddresses } from "clients/company-addresses";
import FlexBox from "components/FlexBox";
import { Fragment, useEffect, useState } from "react";
import cuid from "cuid";
import { useFormik } from "formik";
import * as yup from "yup";
import { postProductExpenses } from "clients/product-expenses";
import { getImportCountries } from "clients/countries";
import { placeOrder } from "clients/orders";
import navigationService from "utils/navigationService";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { isEmpty } from "lodash-es";
import { errorMessages } from "utils/constants";
import { format } from "date-fns";

const OrderProductDialog = ({ open, onClose, product }) => {
  const [expensesData, setExpensesData] = useState();
  const [addresses, setAddresses] = useState([]);
  const [importCountries, setImportCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const productExpensesInitialValues = {
    id_product: product.id,
    id_company_address: "",
    id_country: "",
    transport_start: new Date(Date.now() + 12960e5),
  };

  const orderInitialValues = {
    fk_product: product.id,
    transport_included: true,
    expenses_included: true,
  };

  const productExpensesFormik = useFormik({
    initialValues: productExpensesInitialValues,
    validationSchema: productExpensesValidationSchema,
  });

  const orderFormik = useFormik({
    initialValues: orderInitialValues,
    validationSchema: orderValidationSchema,
    onSubmit: async (values) => {
      orderFormik.setSubmitting(true);
      if (
        (values.transport_included || values.expenses_included) &&
        !!expensesData
      ) {
        values.fk_product_expense = expensesData.id;
      } else {
        values.transport_included = false;
        values.expenses_included = false;
      }
      const [data] = await placeOrder(values);
      if (data) {
        navigationService.navigation(`/orders/${data.id}`);
      }
      orderFormik.setSubmitting(false);
    },
  });

  useEffect(() => {
    getCompanyAddresses().then((response) => {
      const [data] = response;
      setAddresses(data || []);
      if (data) {
        productExpensesFormik.setFieldValue("id_company_address", data[0].id);
      }
    });
    getImportCountries().then((response) => {
      const [data] = response;
      setImportCountries(data);
    });
  }, []);

  const fetchProductExpenses = async () => {
    setIsLoading(true);
    const requestData = {
      id_product: product.id,
      id_company_address: productExpensesFormik.values.id_company_address,
      transport_start: format(
        productExpensesFormik.values.transport_start,
        "yyyy-MM-dd"
      ),
    };
    if (productExpensesFormik.values.id_country) {
      requestData["id_country"] = productExpensesFormik.values.id_country;
    }
    const [data] = await postProductExpenses(requestData);
    setExpensesData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    productExpensesFormik.validateForm().then((validation) => {
      if (isEmpty(validation)) {
        if (!product.is_eu) {
          if (productExpensesFormik.values.id_country) {
            fetchProductExpenses();
          }
        } else {
          fetchProductExpenses();
        }
      }
    });
  }, [
    productExpensesFormik.values.id_company_address,
    productExpensesFormik.values.id_country,
    productExpensesFormik.values.transport_start,
  ]);

  const product_price = product.offer?.price || product.attributes.price;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: "50vw",
        },
      }}
    >
      <FlexBox justifyContent="end">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </FlexBox>
      <FlexBox
        sx={{
          padding: 2,
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h5" fontWeight={600} color="primary">
          Zamów Pojazd
        </Typography>

        <TextField
          label="Cena pojazdu"
          value={product_price.toFixed(2) || ""}
          disabled={isLoading}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={orderFormik.values.transport_included}
              disabled={isLoading || orderFormik.isSubmitting}
              onClick={(e) => {
                orderFormik.setFieldValue(
                  "transport_included",
                  e.target.checked
                );
                if (!e.target.checked) {
                  orderFormik.setFieldValue("expenses_included", false);
                }
              }}
            />
          }
          label="Zamów transport pojazdu"
        />

        {orderFormik.values.transport_included && (
          <Fragment>
            <TextField
              name="id_company_address"
              label="Adres Dostawy"
              value={productExpensesFormik.values.id_company_address || ""}
              onChange={productExpensesFormik.handleChange}
              error={
                !!productExpensesFormik.touched.id_company_address &&
                !!productExpensesFormik.errors.id_company_address
              }
              helperText={
                !!productExpensesFormik.touched.id_company_address &&
                productExpensesFormik.errors.id_company_address
              }
              disabled={isLoading || orderFormik.isSubmitting}
              select
            >
              {addresses.map((address) => (
                <MenuItem value={address.id} key={cuid()}>
                  {address.name}
                </MenuItem>
              ))}
            </TextField>
            {!product.is_eu && (
              <TextField
                name="id_country"
                label="Kraj importu"
                value={productExpensesFormik.values.id_country}
                onChange={productExpensesFormik.handleChange}
                disabled={isLoading || orderFormik.isSubmitting}
                select
              >
                {importCountries.map((country) => (
                  <MenuItem value={country.id} key={cuid()}>
                    <FlexBox justifyContent="space-between" width="100%">
                      {country.name}
                      <Typography color="grey.600">
                        VAT: {country.vat}%
                      </Typography>
                    </FlexBox>
                  </MenuItem>
                ))}
              </TextField>
            )}

            <DatePicker
              label="Data rozpoczęcia transportu"
              mask="__/__/____"
              inputFormat="dd/MM/yyyy"
              value={productExpensesFormik.values.transport_start || null}
              onChange={(newValue) => {
                productExpensesFormik.setFieldValue(
                  "transport_start",
                  newValue
                );
              }}
              renderInput={(params) => (
                <TextField
                  name="transport_start"
                  onBlur={productExpensesFormik.handleBlur}
                  helperText={
                    !!productExpensesFormik.touched.transport_start &&
                    productExpensesFormik.errors.transport_start
                  }
                  {...params}
                />
              )}
              error={
                !!productExpensesFormik.errors.transport_start &&
                !!productExpensesFormik.touched.transport_start
              }
              minDate={new Date(Date.now() + 12096e5)}
              disabled={isLoading || orderFormik.isSubmitting}
            />

            <TextField
              label="Cena transportu"
              value={expensesData?.transport_price?.toFixed(2) || ""}
              disabled={isLoading || orderFormik.isSubmitting}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">PLN</InputAdornment>
                ),
              }}
            />
          </Fragment>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={orderFormik.values.expenses_included && !!expensesData}
              disabled={
                !expensesData ||
                !orderFormik.values.transport_included ||
                orderFormik.isSubmitting ||
                isLoading
              }
              onClick={(e) => {
                orderFormik.setFieldValue(
                  "expenses_included",
                  e.target.checked
                );
              }}
            />
          }
          label="Opłać podatki i dostosuj pojazd przy pomocy serwisu Carteon"
        />

        {orderFormik.values.expenses_included &&
          expensesData &&
          getWantedAttributes(expensesData).map(
            (elem) =>
              !!elem.value && (
                <TextField
                  key={elem.label}
                  label={elem.label}
                  value={elem.value.toFixed(2)}
                  disabled={isLoading}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">PLN</InputAdornment>
                    ),
                  }}
                />
              )
          )}

        <Divider />

        <TextField
          label="Cena"
          value={calculatePrice(
            expensesData,
            product_price,
            orderFormik.values.expenses_included,
            orderFormik.values.transport_included
          ).toFixed(2)}
          disabled={isLoading}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
        />

        <LoadingButton
          variant="contained"
          color="primary"
          disabled={isLoading}
          loading={orderFormik.isSubmitting}
          onClick={orderFormik.handleSubmit}
        >
          Zamów Produkt
        </LoadingButton>
      </FlexBox>
    </Dialog>
  );
};
const productExpensesValidationSchema = yup.object({
  id_company_address: yup.number().required(),
  transport_start: yup
    .date()
    .required(errorMessages.required)
    .min(new Date(Date.now() + 12096e5), errorMessages.min_2_weeks_ahead)
    .typeError(errorMessages.invalid_date),
});
const orderValidationSchema = yup.object({
  transport_included: yup.boolean().required(),
  expenses_included: yup.boolean().required(),
});

const calculatePrice = (
  expenses,
  product_price,
  expenses_included,
  orderTransport
) => {
  return (
    product_price +
    (expenses?.transport_price || 0) * orderTransport +
    ((expenses?.excise_price || 0) +
      (expenses?.duty_price || 0) +
      (expenses?.vat_price || 0) +
      (expenses?.inspection_price || 0) +
      (expenses?.adapt_price || 0)) *
      expenses_included
  );
};

const getWantedAttributes = (expenses) => {
  return [
    { label: "Akcyza", value: expenses.excise_price },
    { label: "Opłacy celne", value: expenses.duty_price },
    { label: "Podatek VAT", value: expenses.vat_price },
    { label: "Cena pierwszego przeglądu", value: expenses.inspection_price },
    {
      label: "Cena przystosowania pojazdu do wymagań EU",
      value: expenses.adapt_price,
    },
  ];
};
export default OrderProductDialog;
