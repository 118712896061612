import { Typography, Box } from "@mui/material";
import { Fragment } from "react";
import FlexBox from "components/FlexBox";

const SpecificationChargingType = ({ definition }) => {
  return (
    <Fragment>
      <Typography variant="h6" fontWeight={700} component="span">
        Typ Ładowania{" "}
        <Typography variant="h6" component="span">
          {definition.name}
        </Typography>
      </Typography>
      <FlexBox justifyContent="center" p={1}>
        <Box maxWidth={600}>
          <img
            style={{
              width: "100%",
            }}
            src={definition.image}
          />
        </Box>
      </FlexBox>
    </Fragment>
  );
};
export default SpecificationChargingType;
