import Image from "components/BazarImage";
import FlexBox from "components/FlexBox";
import Login from "components/sessions/Login";
import { useAppContext } from "contexts/app/AppContext";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutline from "@mui/icons-material/PersonOutline";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { layoutConstant } from "utils/constants";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SearchBox from "../search-box/SearchBox"; // component props interface
import HeaderMenu from "./HeaderMenu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationSidePanel from "components/notifications/NotificationSidePanel";
import { getNotifications } from "clients/notifications";

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  height: layoutConstant.headerHeight,
  background: theme.palette.background.paper,
  transition: "height 250ms ease-in-out",
  [theme.breakpoints.down("sm")]: {
    height: layoutConstant.mobileHeaderHeight,
  },
}));

const Header = ({ className }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const { state, dispatch } = useAppContext();
  const { user } = state;
  const sidenavOpen = user.showNotifications;
  const navigate = useNavigate();
  const [countMesseges, setCountMesseges] = useState(0);
  const [countLoading, setCountLoading] = useState(false);

  const handleAccountClick = () => {
    user?.user ? navigate("/profile") : setDialogOpen(true);
  };

  const toggleSidenav = () => {
    if (user.showNotifications) {
      dispatch({
        type: "HIDE_NOTIFICATIONS",
        payload: {},
      });
    } else {
      dispatch({
        type: "SHOW_NOTIFICATIONS",
        payload: {},
      });
    }
  };

  const getMessages = () => {
    getNotifications()
      .then((response) => {
        const [data] = response;
        setCountLoading(true);
        if (data) {
          setCountMesseges(0);
          Object.keys(data).map((key, index) => {
            const object = data[key];
            if (object.read_at === null) {
              setCountMesseges((previousValue) => ++previousValue);
            } else {
              if (countMesseges > 0)
                setCountMesseges((previousValue) => --previousValue);
            }
          }, []);
          dispatch({
            type: "SET_NOTIFICATIONS",
            payload: { ...data },
          });
        }
        setCountLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (user?.user) {
      // do first fetch only if messages are null
      if (!user.messages) {
        getMessages();
      }

      // Set interval if not already set
      if (!user.messagesInterval) {
        // fetch messages every 3 minutes
        const interval = setInterval(getMessages, 1000 * 60 * 1);
        // set interval id to user, used for cleanup later
        dispatch({
          type: "SET_INTERVAL",
          payload: interval,
        });
      }
    } else {
      // if user is not logged in try clearing the message fetching interval
      clearInterval(user?.messagesInterval);
      dispatch({
        type: "SET_INTERVAL",
        payload: null,
      });
    }
  }, [user.user, countMesseges]);

  return (
    <HeaderWrapper className={clsx(className)}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
          maxWidth: "unset !important",
          [theme.breakpoints.down("md")]: {
            justifyContent: "center !important",
          },
        }}
      >
        <FlexBox
          alignItems="center"
          mr={2}
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Link to="/">
            <Image
              height={70}
              mb={0.5}
              src="/assets/images/carteon_logo.svg"
              alt="logo"
            />
          </Link>
        </FlexBox>

        <FlexBox justifyContent="flex-start" flex="1 1 0" maxWidth="500px">
          <SearchBox />
        </FlexBox>

        <HeaderMenu />

        <FlexBox
          alignItems="center"
          sx={{
            justifyContent: "flex-end",
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Box
            component={IconButton}
            ml={2}
            p={1.25}
            bgcolor="grey.200"
            onClick={handleAccountClick}
          >
            {!!user?.user ? <PersonIcon /> : <PersonOutline />}
          </Box>
          {!countLoading && !!user.user && (
            <Badge
              badgeContent={countMesseges || 0}
              color="primary"
              overlap="circular"
            >
              <Box
                component={IconButton}
                ml={2}
                p={1.25}
                bgcolor="grey.200"
                onClick={toggleSidenav}
              >
                <NotificationsNoneIcon />
              </Box>
            </Badge>
          )}
        </FlexBox>

        <Dialog
          open={dialogOpen}
          fullWidth={isMobile}
          scroll="body"
          onClose={toggleDialog}
        >
          <Login
            on_success={() => {
              setDialogOpen(false);
            }}
          />
        </Dialog>

        <Drawer
          open={sidenavOpen}
          anchor="right"
          onClose={toggleSidenav}
          PaperProps={{
            sx: { width: "380px", paddingRight: "5px" },
          }}
          sx={{ zIndex: 2500 }}
        >
          <NotificationSidePanel close_action={toggleSidenav} />
        </Drawer>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
