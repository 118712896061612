import TableRow from "components/TableRow";
import Edit from "@mui/icons-material/Edit";
import { IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

const AddressList = ({ addresses }) => {
  return (
    <Fragment>
      {!addresses && (
        <TableRow sx={{ my: "1rem", padding: "6px 18px" }}>
          <Typography whiteSpace="pre" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>
          <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
            <Skeleton variant="text" />
          </Typography>

          <Typography whiteSpace="pre" textAlign="right" color="grey.600">
            <Skeleton variant="text" />
          </Typography>
        </TableRow>
      )}

      {addresses &&
        addresses.map((row) => (
          <TableRow sx={{ my: "1rem", padding: "6px 18px" }} key={row.id}>
            <Typography whiteSpace="pre" m={0.75} textAlign="left">
              {row.name}
            </Typography>
            <Typography flex="1 1 260px !important" m={0.75} textAlign="left">
              {row.address1} {row.address2}, {row.post_code} {row.place}{" "}
              {row.country}
            </Typography>

            <Typography whiteSpace="pre" textAlign="right" color="grey.600">
              <Link to={`/address/${row.id}`}>
                <IconButton>
                  <Edit fontSize="small" color="inherit" />
                </IconButton>
              </Link>
            </Typography>
          </TableRow>
        ))}
    </Fragment>
  );
};
export default AddressList;
