import QuestionRoot from "../QuestionRoot";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import {
  CircularProgress,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
  MenuItem,
  Typography,
  Card,
  IconButton,
} from "@mui/material";
import { useEffect } from "react";
import { postInspectionResults } from "../../../clients/inspection-results";
import FlexBox from "../../FlexBox";
import { uniq } from "lodash-es";
import { getProducers, getModels } from "clients/producers";
import { getModelById } from "clients/models";
import PledgeDialog from "../PledgeDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const QuestionConfirmDetails = ({
  inspection_id,
  handle_next,
  result_data,
  product_id,
}) => {
  const disabled = !!result_data;

  const [isLoading, setIsLoading] = useState(false);
  const [producers, setProducers] = useState();
  const [models, setModels] = useState([]);
  const [selectedModelGroup, setSelectedModelGroup] = useState();
  const [pledgeDialogOpen, setPledgeDialogOpen] = useState(false);

  const formik = useFormik({
    // if data is already present - aka this step was already done
    // set initial values to show data
    initialValues: (result_data && {
      ...result_data,
      is_pawned: !result_data.is_pawned,
      is_stolen: !result_data.is_stolen,
      fk_producer: null,
    }) || {
      id_inspection: inspection_id,
      id_machine_model: null,
      vin: "",
      registration_number: "",
      first_registration_date: "",
      is_pawned: false,
      is_stolen: false,
      is_confirmed: false,
      fk_producer: null,
    },
    onSubmit: (values) => {
      let new_values = {
        ...values,
        is_pawned: !values.is_pawned,
        is_stolen: !values.is_stolen,
      };
      // if disabled - this step was already done navigate to next
      if (disabled) {
        handle_next();
        return;
      }
      setIsLoading(true);
      postInspectionResults(new_values).then((response) => {
        const [data] = response;
        if (data) {
          setIsLoading(false);
          handle_next(true);
        }
      });
    },
    validationSchema: !disabled && validationSchema,
  });

  const fetchModels = (idProducer) => {
    getModels(idProducer).then((response) => {
      const [data] = response;
      setModels(data);
    });
  };

  useEffect(() => {
    getProducers().then((response) => {
      const [producersData] = response;
      setProducers(producersData);
      if (disabled) {
        getModelById(result_data.id_machine_model).then((response) => {
          const [data] = response;
          formik.setFieldValue("fk_producer", {
            label: data.producer.name,
            id: data.producer.id,
          });
          setSelectedModelGroup(data.name);
          formik.setFieldValue("id_machine_model", data.id);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (formik.values.fk_producer) {
      fetchModels(formik.values.fk_producer.id);
    } else {
      setModels(null);
    }
    if (!disabled) {
      setSelectedModelGroup(null);
      formik.setFieldValue("id_machine_model", "");
    }
  }, [formik.values.fk_producer]);

  const handlePledgeDialogClose = () => {
    setPledgeDialogOpen(false);
  };

  if (!producers) {
    return (
      <Card
        sx={{
          minHeight: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }

  const producer_options = producers.map((producer) => ({
    label: producer.name,
    id: producer.id,
  }));

  const model_options = uniq(models?.map((model) => model.name)) || [];

  const model_variants =
    models?.filter((elem) => elem.name === selectedModelGroup) || [];

  return (
    <QuestionRoot
      question="Potwierdzenie danych pojazdu"
      handle_next={formik.handleSubmit}
      isLoading={isLoading}
    >
      <Autocomplete
        name="fk_producer"
        value={formik.values.fk_producer || null}
        noOptionsText="Brak wyszukań"
        disabled={isLoading || disabled}
        onChange={(e, new_value) => {
          formik.setFieldValue("fk_producer", new_value);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        options={producer_options}
        sx={{
          flex: 1,
          minWidth: 200,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Producent"
            onBlur={formik.handleBlur}
            error={!!formik.touched.fk_producer && !!formik.errors.fk_producer}
          />
        )}
      />
      <Autocomplete
        name="models"
        value={selectedModelGroup || null}
        noOptionsText="Brak wyszukań"
        disabled={isLoading || !model_options.length || disabled}
        onChange={(e, new_value) => {
          setSelectedModelGroup(new_value);
          formik.setFieldValue("id_machine_model", "");
        }}
        options={model_options}
        sx={{
          flex: 1,
          minWidth: 200,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Model"
            error={
              !!formik.touched.id_machine_model &&
              !!formik.errors.id_machine_model
            }
          />
        )}
      />

      <TextField
        name="id_machine_model"
        label="Wariant"
        value={formik.values.id_machine_model || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          !!formik.touched.id_machine_model && !!formik.errors.id_machine_model
        }
        helperText={
          !!formik.touched.id_machine_model && formik.errors.id_machine_model
        }
        disabled={isLoading || !model_variants.length || disabled}
        select
      >
        {model_variants.map((variant) => (
          <MenuItem
            key={variant.id.toString()}
            value={variant.id}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ width: "100%" }} fontWeight={600}>
              {variant.name}
            </Typography>
            {productCompareAttributes(variant).map(
              (elem) =>
                elem.value && (
                  <Typography key={elem.label} sx={{ width: "100%" }}>
                    {elem.label}: {elem.value}
                  </Typography>
                )
            )}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        name="vin"
        onChange={formik.handleChange}
        label="VIN"
        value={formik.values.vin || ""}
        onBlur={formik.handleBlur}
        variant="outlined"
        disabled={disabled || isLoading}
        size="small"
        error={!!formik.touched.vin && !!formik.errors.vin}
        helperText={formik.touched.vin && formik.errors.vin}
      />
      <TextField
        name="registration_number"
        onChange={formik.handleChange}
        label="Numer Rejestracyjny"
        value={formik.values.registration_number || ""}
        onBlur={formik.handleBlur}
        variant="outlined"
        disabled={disabled || isLoading}
        size="small"
        error={
          !!formik.touched.registration_number &&
          !!formik.errors.registration_number
        }
        helperText={
          formik.touched.registration_number &&
          formik.errors.registration_number
        }
      />
      <TextField
        name="first_registration_date"
        label="Data Pierwszej rejestracji"
        onChange={formik.handleChange}
        onBlur={(e) => {
          e.target.type = "text";
          formik.handleBlur(e);
        }}
        onFocus={(e) => {
          e.target.type = "date";
        }}
        disabled={disabled || isLoading}
        value={formik.values.first_registration_date || ""}
        error={
          !!formik.touched.first_registration_date &&
          !!formik.errors.first_registration_date
        }
        helperText={
          formik.touched.first_registration_date &&
          formik.errors.first_registration_date
        }
        variant="outlined"
        size="small"
        placeholder=""
      />
      <FlexBox>
        <FormControlLabel
          disabled={disabled || isLoading}
          control={
            <Switch
              name="is_pawned"
              value={formik.values.is_pawned}
              checked={!!formik.values.is_pawned}
              onChange={formik.handleChange}
            />
          }
          label="Pojazd nie jest zadłużony"
        />
        <IconButton
          onClick={() => {
            setPledgeDialogOpen(true);
          }}
        >
          <HelpOutlineIcon />
        </IconButton>
      </FlexBox>
      <FormControlLabel
        disabled={disabled || isLoading}
        control={
          <Switch
            name="is_stolen"
            value={formik.values.is_stolen}
            checked={!!formik.values.is_stolen}
            onChange={formik.handleChange}
          />
        }
        label="Pojazd nie jest kradziony"
      />
      <FormControlLabel
        disabled={disabled || isLoading}
        control={
          <Switch
            name="is_confirmed"
            value={formik.values.is_confirmed}
            checked={!!formik.values.is_confirmed}
            onChange={formik.handleChange}
          />
        }
        label="Potwierdzam dane pojazdu"
      />
      <PledgeDialog
        open={pledgeDialogOpen}
        on_close={handlePledgeDialogClose}
        product_id={product_id}
      />
    </QuestionRoot>
  );
};
// make all fields required
const validationSchema = Yup.object().shape({
  id_machine_model: Yup.number().required(),
  vin: Yup.string().required("Pole wymagane"),
  registration_number: Yup.string().required("Pole wymagane"),
  first_registration_date: Yup.string().required("Pole wymagane"),
});

const productCompareAttributes = (product) => [
  {
    label: "Typ Nadwozia",
    value: product.bodyType?.name,
  },
  {
    label: "Wariant",
    value: product.version,
  },
  {
    label: "Moc Silnika",
    value: product.engine_power,
  },
  {
    label: "Napęd",
    value: product.driveType?.name,
  },
];
export default QuestionConfirmDetails;
