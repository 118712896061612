import DashboardLayout from "components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InspectorAvailabilitiesForm from "components/inspector-availabilities/InspectorAvailabilitiesForm";

const InspectorAvailabilitiesPage = () => {
  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={CalendarMonthOutlinedIcon}
        title="Edycja czasu pracy"
        navigation={<CustomerDashboardNavigation />}
      />
      <InspectorAvailabilitiesForm />
    </DashboardLayout>
  );
};
export default InspectorAvailabilitiesPage;
