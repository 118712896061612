import apiClient from "./apiClient";

export const placeOrder = async (data) => {
  return await apiClient.post("/orders", data);
};

export const getOrdersList = async () => {
  return await apiClient.get("/orders");
};

export const getOrderDetails = async (id) => {
  return await apiClient.get(`/orders/${id}`);
};

export const getTrackingDetails = async (id) => {
  return await apiClient.get(`/orders/${id}/track`);
};

export const getWaypointsDetails = async (id) => {
  return await apiClient.get(`/orders/${id}/way`);
};

export const determineTransportStatus = (trackingData) => {
  switch (trackingData.transport_status) {
    case 0: {
      return "Oczekujący na transport";
    }
    case 1: {
      return "W transporcie do terminalu";
    }
    case 2: {
      return "Transport morski";
    }
    case 3: {
      return "W drodze do dostarczenia";
    }
    case 4: {
      return "Ukończony";
    }
  }
};
