import DashboardLayout from "components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import ShoppingBag from "@mui/icons-material/ShoppingBag";
import { useEffect, useState } from "react";
import { getCustomerPaymentDetails } from "clients/payments";
import PaymentsDetail from "components/payments/PaymentsDetail";
import { useParams } from "react-router-dom";
import { Card, CircularProgress } from "@mui/material";

const PaymentsDetailPage = () => {
  const [data, setData] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getCustomerPaymentDetails(id)
        .then((response) => {
          const [data] = response;
          if (data !== null) {
            setData(data);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        title="Szczegóły płatności"
        icon={ShoppingBag}
        navigation={<CustomerDashboardNavigation />}
      />
      {data ? (
        <PaymentsDetail paymentData={data} />
      ) : (
        <Card
          sx={{
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
    </DashboardLayout>
  );
};

export default PaymentsDetailPage;
