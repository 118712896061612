import { Card, CircularProgress, Container } from "@mui/material";
import { getProducers } from "clients/producers";
import SavingsCalculator from "components/savings/SavingsCalculator";
import { useEffect, useState } from "react";
import Image from "components/BazarImage";

const SavingsPrintPage = () => {
  const [producers, setProducers] = useState();

  useEffect(() => {
    getProducers().then((response) => {
      const [data] = response;
      setProducers(data);
    });
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 2,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Image
        height={70}
        mb={0.5}
        src="/assets/images/carteon_logo.svg"
        alt="logo"
      />
      {!producers && (
        <Card
          sx={{
            minHeight: "40vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
      {!!producers && (
        <Card
          sx={{
            padding: 3,
          }}
        >
          <SavingsCalculator producers={producers} showPrint={false} />
        </Card>
      )}
    </Container>
  );
};

export default SavingsPrintPage;
