import { getProducts } from "clients/products";
import { useEffect, useState } from "react";
import CustomerDashboardLayout from "components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Card,
  Table,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import ProductListSkeleton from "components/products/ProductListSkeletion";
import ProductList from "components/products/ProductList";

const ProductsListPage = () => {
  const [products, setProducts] = useState();

  useEffect(() => {
    getProducts().then((response) => {
      const [data] = response;
      setProducts(data);
    });
  }, []);

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        title="Wystawione produkty"
        icon={DirectionsCarOutlinedIcon}
        navigation={<CustomerDashboardNavigation />}
      />

      <TableContainer component={Card}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Producent</TableCell>
              <TableCell align="right">Model</TableCell>
              <TableCell align="right">Rocznik</TableCell>
              <TableCell align="right">Przebieg</TableCell>
              <TableCell align="right">Cena</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Akcja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!products && <ProductListSkeleton />}
            {products && <ProductList products={products} />}
          </TableBody>
        </Table>
      </TableContainer>
      {products && products.length === 0 && (
        <Card
          sx={{
            padding: "10px",
            marginTop: 4,
          }}
        >
          <h2>Nie masz jeszce żadnego dodanego produktu</h2>
          <Link to="/products/add">
            <Button variant="outlined" color="primary">
              Dodaj Produkt
            </Button>
          </Link>
        </Card>
      )}
    </CustomerDashboardLayout>
  );
};
export default ProductsListPage;
