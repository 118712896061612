class NavigationService {
  constructor() {
    this._navigation = null;
    this._location = null;
  }

  set navigation(nav) {
    this._navigation = nav;
  }

  get navigation() {
    return this._navigation;
  }

  set location(nav) {
    this._location = nav;
  }

  get location() {
    return this._location;
  }
}

const navigationService = new NavigationService();

export default navigationService;
