import apiClient from "./apiClient";

export const getCurrentInspections = async () => {
  return await apiClient.get("/inspector-inspections");
};

export const getInspectorInspectionDetails = async (id) => {
  return await apiClient.get(`/inspector-inspections/${id}`);
};

export const acceptInspection = async (idInspection) => {
  return await apiClient.patch(`/inspector-inspections/${idInspection}`, {
    status: 2,
  });
};

export const rejectInspection = async (idInspection) => {
  return await apiClient.patch(`/inspector-inspections/${idInspection}`, {
    status: 1,
  });
};
