import { Divider, InputAdornment, TextField, Typography } from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import { Fragment } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { errorMessages } from "utils/constants";
import { postAuctionOffer } from "clients/auctions";
import { useState } from "react";
import { formatToHumanReadableNumber } from "utils/utils";

const ProductAuction = ({ product }) => {
  const [localPice, setLocalPice] = useState(
    product.auction.highest_bid || product.auction.price
  );
  const initialValues = {
    id_product: product.id,
    price: "",
  };
  const validationSchema = yup.object({
    price: yup
      .number(errorMessages.numeric)
      .required(errorMessages.required)
      .min(localPice + 1, errorMessages.auction_min)
      .typeError(errorMessages.numeric),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.price = parseFloat(values.price).toFixed(2);
      postAuctionOffer(values).then((response) => {
        const [data] = response;
        if (data) {
          setLocalPice(data.price);
          formik.setFieldValue("price", "");
          formik.setFieldTouched("price", "false");
          formik.setSubmitting(false);
        }
      });
    },
  });
  return (
    <Fragment>
      <Divider />
      <Typography
        variant="h6"
        mt={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <GavelIcon color="primary" />
        Aukcja Pojazdu
      </Typography>
      <Typography mt={1}>
        Trwa do:{" "}
        <Typography component="span" fontWeight={600}>
          {" "}
          {product.auction.valid_to} CET
        </Typography>
      </Typography>
      <Typography>
        Aktualna Cena:{" "}
        <Typography component="span" fontWeight={600}>
          {formatToHumanReadableNumber(localPice)} PLN
        </Typography>
      </Typography>
      <TextField
        name="price"
        label="Cena"
        value={formik.values.price}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        variant="standard"
        error={!!formik.touched.price && !!formik.errors.price}
        helperText={!!formik.touched.price && formik.errors.price}
        InputProps={{
          endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
        }}
      />
      <LoadingButton
        variant="contained"
        color="primary"
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
        sx={{ mt: 2 }}
      >
        Licytuj
      </LoadingButton>
    </Fragment>
  );
};
export default ProductAuction;
