import FlexBox from "components/FlexBox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Person from "@mui/icons-material/Person";
import Place from "@mui/icons-material/Place";
import ShoppingBagOutlined from "@mui/icons-material/ShoppingBagOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import { Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import {
  DashboardNavigationWrapper,
  StyledDashboardNav,
} from "./DashboardStyle";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useAppContext } from "contexts/app/AppContext";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { getMenuQuantities } from "clients/menu-quantities";
import useLoaded from "utils/useLoaded";

const CustomerDashboardNavigation = () => {
  const { pathname } = useLocation();
  const { state, dispatch } = useAppContext();
  const { user } = state;
  const isLoaded = useLoaded();

  const getMenuCounter = () => {
    getMenuQuantities()
      .then((response) => {
        if (response[0] !== null) {
          dispatch({
            type: "SET_MENU_QUANTITIES",
            payload: { ...response[0] },
          });
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (user?.user) {
      if (!user.user.counters) {
        getMenuCounter();
      }
      // Set interval if not already set
      if (!user.user.countersInterval) {
        // fetch messages every 3 minutes
        const interval = setInterval(getMenuQuantities, 1000 * 60 * 1);
        // set interval id to user, used for cleanup later
        dispatch({
          type: "SET_MENU_INTERVAL",
          payload: interval,
        });
        // on component unmount clear interval
        return () => {
          clearInterval(interval);
        };
      }
    } else {
      // if user is not logged in try clearing the message fetching interval
      clearInterval(user?.user?.countersInterval);
    }
  }, [user.user]);

  const counters = {
    "/wish-list": user?.user?.favorite_products?.length || 0,
    "/offers": user?.counters?.offers || 0,
    "/orders": user?.counters?.orders || 0,
    "/payments": user?.counters?.payments || 0,
    "/inspections": user?.counters?.inspections || 0,
    "/products": user?.counters?.products || 0,
  };

  return (
    <DashboardNavigationWrapper
      sx={{
        px: "0px",
        pb: "1.5rem",
        color: "grey.900",
      }}
    >
      {isLoaded &&
        user?.user &&
        state.user.counters &&
        makeLinkList(user.user).map((item) => (
          <Fragment key={item.title}>
            <Typography p="26px 30px 1rem" color="grey.600" fontSize="12px">
              {item.title}
            </Typography>
            {item.list.map((item) => (
              <StyledDashboardNav
                isCurrentPath={pathname === item.href}
                href={item.href}
                key={item.title}
              >
                <FlexBox alignItems="center">
                  <item.icon
                    className="nav-icon"
                    fontSize="small"
                    color="inherit"
                    sx={{
                      mr: "10px",
                    }}
                  />

                  <span>{item.title}</span>
                </FlexBox>
                <span>{counters[item.href]}</span>
              </StyledDashboardNav>
            ))}
          </Fragment>
        ))}
    </DashboardNavigationWrapper>
  );
};

const makeLinkList = (user) => {
  const isInspector = !!user?.claim?.includes("inspector");
  const isTrader = !!user?.claim?.includes("trader");
  // console.log(user.user.counters);
  let linkList = [
    {
      title: "Konto",
      list: [
        {
          href: "/profile",
          title: "Profil",
          icon: Person,
        },
        {
          href: "/address",
          title: "Adresy",
          icon: Place,
        },
        {
          href: "/payments",
          title: "Płatności",
          icon: PaymentIcon,
        },
        {
          href: "/logout",
          title: "Wyloguj",
          icon: LogoutIcon,
        },
      ],
    },
  ];
  if (isInspector) {
    linkList.push({
      title: "Inspekcje",
      list: [
        {
          href: "/inspector-availabilities",
          title: "Edycja czasu pracy",
          icon: CalendarMonthOutlinedIcon,
        },
        {
          href: "/inspector-inspections",
          title: "Inspekcje do wykonania",
          icon: FactCheckOutlinedIcon,
        },
        {
          href: "/inspector-inspections/history",
          title: "Historia inspekcji",
          icon: HistoryOutlinedIcon,
        },
      ],
    });
  }
  if (isTrader) {
    linkList.push({
      title: "Produkty",
      list: [
        {
          href: "/wish-list",
          title: "Ulubione",
          icon: FavoriteBorder,
        },
        {
          href: "/orders",
          title: "Zamówienia",
          icon: ShoppingBagOutlined,
        },
        {
          href: "/products",
          title: "Wystawione Pojazdy",
          icon: DirectionsCarFilledOutlinedIcon,
        },
        {
          href: "/products/add",
          title: "Dodaj Produkt",
          icon: AddCircleOutlineOutlinedIcon,
        },
        {
          href: "/inspections",
          title: "Inspekcje",
          icon: FactCheckOutlinedIcon,
        },
        {
          href: "/offers",
          title: "Oferty",
          icon: PercentIcon,
        },
      ],
    });
  }
  return linkList;
};
export default CustomerDashboardNavigation;
