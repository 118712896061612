import { Button } from "@mui/material";
import { useAppContext } from "contexts/app/AppContext";
import { Fragment } from "react";

const OrderProductButton = ({ setDialogOpen, product }) => {
  const { state } = useAppContext();
  const { user } = state.user;
  return (
    <Fragment>
      {user && product.status === 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={setDialogOpen}
          disabled={!user}
        >
          Zamów
        </Button>
      )}
    </Fragment>
  );
};
export default OrderProductButton;
