import FlexBox from "components/FlexBox";
import AppLayout from "components/layout/AppLayout";
import Navbar from "components/navbar/Navbar";
import ProductCard14 from "components/product-cards/ProductCard14";
import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getFeaturedProducts } from "clients/search-product";
import ProductCardSkeleton from "components/products/ProductCardSkeleton";
import Banner from "components/header/Banner";
import { useAppContext } from "contexts/app/AppContext";

const SalePage1 = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { dispatch } = useAppContext();
  // Fetch products
  useEffect(() => {
    setIsLoading(true);

    getFeaturedProducts()
      .then((response) => {
        const [data, validations, error] = response;
        if (data !== null) {
          setData(data);
          setIsLoading(false);
        }
        if (error !== null) {
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: { text: "Błąd przy odczycie produktów", type: "error" },
          });
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <AppLayout navbar={<Navbar />}>
      <Banner />
      <Container
        sx={{
          mt: "2rem",
        }}
      >
        <Grid container spacing={3} minHeight={500}>
          {isLoading &&
            [1, 2, 3].map((item, ind) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={ind}>
                <ProductCardSkeleton />
              </Grid>
            ))}

          {!isLoading &&
            data.map((item, ind) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={ind}>
                <ProductCard14 item={item} />
              </Grid>
            ))}
        </Grid>

        <FlexBox
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          my="4rem"
        ></FlexBox>
      </Container>
    </AppLayout>
  );
};

export default SalePage1;
