import SpecificationChargingType from "./SpecificationChargingType";
import SpecificationHeader from "./SpecificationHeader";
import SpecificationImage from "./SpecificationImage";
import SpecificationKeyValue from "./SpecificationKeyValue";
import SpecificationTable from "./SpecificationTable";
import SpecificationText from "./SpecificationText";

const SpecificationTypeHandler = ({ definition }) => {
  const RenderElement = types[definition.type];

  return <RenderElement definition={definition} />;
};

const types = {
  header: SpecificationHeader,
  table: SpecificationTable,
  text: SpecificationText,
  image: SpecificationImage,
  charging_system: SpecificationChargingType,
  key_value: SpecificationKeyValue,
};
export default SpecificationTypeHandler;
