import BazarButton from "components/BazarButton";
import FlexBox from "components/FlexBox";
import { H6 } from "components/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useCallback, useState } from "react";
import * as yup from "yup";
import { omit } from "lodash-es";
import { useAppContext } from "contexts/app/AppContext";
import { useTheme } from "@mui/system";
import apiClient from "clients/apiClient";

const StyledCard = styled(({ children, passwordVisibility, ...rest }) => (
  <Card {...rest}>{children}</Card>
))(({ theme, passwordVisibility }) => ({
  width: 800,
  marginBottom: 20,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  ".content": {
    padding: "3rem 3.75rem 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 1rem 0px",
    },
  },
  ".passwordEye": {
    color: passwordVisibility
      ? theme.palette.grey[600]
      : theme.palette.grey[400],
  },
  ".agreement": {
    marginTop: 12,
  },
}));

const Signup = ({ pagecontent, checkbox_pagecontent }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();
  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);
  const { palette } = useTheme();
  const [agreements, setAgreements] = useState([]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
    vat_number: "",
    trader: true,
    inspector: false,
    checkbox_required: false,
  };

  // Form verification
  const formSchema = yup.object().shape({
    first_name: yup.string().required("Imię jest wymagane"),
    last_name: yup.string().required("Nazwisko jest wymagane"),
    email: yup
      .string()
      .email("Niepoprawny adres email")
      .required("Email jest wymagany"),
    password: yup
      .string()
      .required("Hasło jest wymagane")
      .min(8, "Hasło nie może mieć mniej niż 8 znaków"),
    re_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Hasła nie są identyczne")
      .required("Proszę powtórzyć hasło"),
    // Additionally matched against only numbers regex
    vat_number: yup
      .string()
      .required("Numer VAT jest wymagany")
      .matches("^[0-9]+$", "Proszę podać poprawny numer VAT")
      .length(10, "Numer VAT musi mieć 10 znaków"),
    trader: yup.boolean(),
    inspector: yup.boolean(),
    checkbox_required: yup.boolean().oneOf([true], "Zgoda wymagana"),
  });

  // function for handling agreements checkboxes
  // on change add or remove agreement from agreements array
  const handleAgreementsChange = (e) => {
    if (e.target.checked) {
      if (!agreements.includes(e.target.value)) {
        setAgreements((agreements) => [...agreements, e.target.value]);
      }
    } else {
      setAgreements((agreements) =>
        agreements.filter((elem) => elem !== e.target.value)
      );
    }
  };

  const handleFormSubmit = async (values) => {
    dispatch({
      type: "USER_LOADING",
      payload: {},
    });
    const { re_password, trader, inspector } = values;
    let checkboxValue = { trader, inspector };
    // Done to ensure backwards compability with previous implementation
    const claimArray = Object.keys(checkboxValue).filter(
      (k) => checkboxValue[k]
    );
    const parsedValues = omit(values, [
      "re_password",
      "trader",
      "inspector",
      "checkbox_required",
    ]);

    const data = {
      "password-confirm": re_password,
      claim: claimArray,
      agreements: agreements,
      ...parsedValues,
    };

    apiClient
      .post("/register-customers", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const [data, validations, errors] = response;
        dispatch({
          type: "USER_LOADED",
          payload: {},
        });

        // if response data contains any: validations, errors
        // creation of user was unsuccessful
        // handleApiResponse(validations, dispatch, {
        //   error_prefix: "Błąd przy tworzeniu użytkownika",
        //   success_message: "Utworzono użytkownika, sprawdź skrzynkę email",
        //   on_success: () => {
        //     navigate("/");
        //   },
        // });
        if (validations !== null) {
          validations.forEach((error) => {
            dispatch({
              type: "SHOW_SNACKBAR",
              payload: { text: error.title, type: "error" },
            });
          });
        }

        if (data !== null) {
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: {
              text: "Utworzono użytkownika, sprawdź skrzynkę email",
              type: "success",
            },
          });
          navigate("/");
        }
      })
      .catch((error) => {
        console.warn(error);
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: { text: "Wystąpił błąd", type: "error" },
        });
        //.catch((error) => console.error(error));
      });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema,
  });

  if (state?.user?.loading) {
    return (
      <FlexBox
        flexDirection="column"
        minHeight="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </FlexBox>
    );
  }

  return (
    <StyledCard elevation={3} passwordVisibility={passwordVisibility}>
      <form className="content" onSubmit={handleSubmit}>
        <FlexBox
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap="10px"
          fullWidth
        >
          <TextField
            name="first_name"
            label="Imię"
            placeholder="Ralph"
            variant="outlined"
            size="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.first_name || ""}
            error={!!touched.first_name && !!errors.first_name}
            helperText={touched.first_name && errors.first_name}
            sx={{
              flexGrow: 1,
              marginBottom: 1.5,
            }}
          />
          <TextField
            name="last_name"
            label="Nazwisko"
            placeholder="Adwards"
            variant="outlined"
            size="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.last_name || ""}
            error={!!touched.last_name && !!errors.last_name}
            helperText={touched.last_name && errors.last_name}
            sx={{
              flexGrow: 1,
              marginBottom: 1.5,
            }}
          />
        </FlexBox>

        <TextField
          name="email"
          label="Email"
          placeholder="exmple@mail.com"
          variant="outlined"
          size="normal"
          type="email"
          fullWidth
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email || ""}
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          sx={{
            marginBottom: 1.5,
          }}
        />

        <FlexBox
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap="10px"
          fullWidth
        >
          <TextField
            name="password"
            label="Hasło"
            placeholder="*********"
            autoComplete="on"
            type={passwordVisibility ? "text" : "password"}
            variant="outlined"
            size="normal"
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisibility ? (
                    <Visibility className="passwordEye" fontSize="small" />
                  ) : (
                    <VisibilityOff className="passwordEye" fontSize="small" />
                  )}
                </IconButton>
              ),
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password || ""}
            error={!!touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            sx={{
              flexGrow: 1,
              marginBottom: 1.5,
            }}
          />

          <TextField
            name="re_password"
            label="Powtórz hasło"
            placeholder="*********"
            autoComplete="on"
            type={passwordVisibility ? "text" : "password"}
            variant="outlined"
            size="normal"
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisibility ? (
                    <Visibility className="passwordEye" fontSize="small" />
                  ) : (
                    <VisibilityOff className="passwordEye" fontSize="small" />
                  )}
                </IconButton>
              ),
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.re_password || ""}
            error={!!touched.re_password && !!errors.re_password}
            helperText={touched.re_password && errors.re_password}
            sx={{
              flexGrow: 1,
              marginBottom: 1.5,
            }}
          />
        </FlexBox>

        <TextField
          name="vat_number"
          label="VAT"
          placeholder="8522581111"
          variant="outlined"
          size="normal"
          fullWidth
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.vat_number || ""}
          error={!!touched.vat_number && !!errors.vat_number}
          helperText={touched.vat_number && errors.vat_number}
          sx={{
            marginBottom: 1.5,
          }}
        />

        <FormControlLabel
          className="agreement"
          name="trader"
          value="trader"
          onChange={(e) => {
            setFieldValue("inspector", values.trader);
            handleChange(e);
          }}
          control={
            <Checkbox
              size="small"
              color="secondary"
              checked={values.trader || false}
            />
          }
          label={
            <FlexBox
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              Sprzedawca / Kupujący
            </FlexBox>
          }
        />

        <FormControlLabel
          className="agreement"
          name="inspector"
          onChange={(e) => {
            setFieldValue("trader", values.inspector);
            handleChange(e);
          }}
          value="inspector"
          control={
            <Checkbox
              size="small"
              color="secondary"
              checked={values.inspector || false}
            />
          }
          label={
            <FlexBox
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              Inspektor
            </FlexBox>
          }
        />

        {/* Display content fetched from page api */}
        <Typography color={palette.grey[700]} variant="body2">
          {pagecontent.registration_mesage}
        </Typography>

        <Typography variant="subtitle1" fontWeight={500} mt={2}>
          Zgoda na przetwarzanie danych
        </Typography>

        <Divider />

        <FormControl fullWidth sx={{ mt: 1 }}>
          <FormControlLabel
            name="checkbox_required"
            onChange={(e) => {
              handleChange(e);
              handleAgreementsChange(e);
            }}
            value={Object.keys(checkbox_pagecontent.attributes)[0]}
            control={
              <Checkbox
                size="small"
                color="secondary"
                checked={values.checkbox_required || false}
              />
            }
            label={
              <FlexBox
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Typography color={palette.grey[700]} variant="body2">
                  {Object.values(checkbox_pagecontent.attributes)[0]}
                </Typography>
              </FlexBox>
            }
          />
          <FormHelperText error>
            {errors.checkbox_required
              ? touched.checkbox_required && errors.checkbox_required
              : ""}
          </FormHelperText>
        </FormControl>

        <Typography variant="subtitle1" fontWeight={500} mt={2}>
          Zgoda na otrzymywanie informacji handlowych
        </Typography>

        <Divider />

        {Object.entries(checkbox_pagecontent.attributes)
          .slice(1)
          .map(([key, val]) => (
            <FormControl fullWidth key={key} sx={{ mt: 1 }}>
              <FormControlLabel
                onChange={(e) => {
                  handleAgreementsChange(e);
                }}
                value={key}
                control={<Checkbox size="small" color="secondary" />}
                label={
                  <FlexBox
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Typography color={palette.grey[700]} variant="body2">
                      {val}
                    </Typography>
                  </FlexBox>
                }
              />
            </FormControl>
          ))}

        <BazarButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{
            height: 44,
            mt: 2,
          }}
        >
          Utwórz konto
        </BazarButton>

        <Box mb={2} mt={3.3}>
          <Box width="200px" mx="auto">
            <Divider />
          </Box>

          <FlexBox justifyContent="center" mt={-1.625}>
            <Box color="grey.600" bgcolor="background.paper" px={2}>
              lub
            </Box>
          </FlexBox>
        </Box>

        <FlexBox justifyContent="center" alignItems="center" my="1.25rem">
          <Box>Już masz konto?</Box>
          <Link to="/login">
            <H6 ml={1} borderBottom="1px solid" borderColor="grey.900">
              Zaloguj się
            </H6>
          </Link>
        </FlexBox>
      </form>
    </StyledCard>
  );
};

export default Signup;
