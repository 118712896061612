import { Box } from "@mui/material";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import ImageList from "./DndImageList";
import cuid from "cuid";
import update from "immutability-helper";

// Styles for image preview
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "10px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function ImagesDropzone({ files, setFiles }) {
  // on image dragged update
  const moveImage = (dragIndex, hoverIndex) => {
    // Get the dragged element
    const draggedImage = files[dragIndex];
    /*
        - copy the dragged image before hovered element (i.e., [hoverIndex, 0, draggedImage])
        - remove the previous reference of dragged element (i.e., [dragIndex, 1])
        - here we are using this update helper method from immutability-helper package
      */
    let new_images_order = update(files, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedImage],
      ],
    });
    setFiles(new_images_order);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/*": [".jpeg", ".jpg", ".png"],
      },

      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              uri: URL.createObjectURL(file),
              id: cuid(),
            })
          )
        );
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = (
    <ImageList
      images={files}
      moveImage={moveImage}
      setImages={setFiles}
      onDragEnd={() => {}}
      makeImageOverride={(image, isDragging, ref) => {
        return (
          <Box
            className="file-item"
            ref={ref}
            key={image.id?.toString()}
            sx={{
              position: "relative",
              opacity: isDragging ? 0 : 1,
            }}
          >
            <div style={thumb} key={cuid()}>
              <div style={thumbInner}>
                <img
                  alt={`img - ${image.id}`}
                  className="file-img"
                  style={img}
                  src={image.uri}
                />
              </div>
            </div>
          </Box>
        );
      }}
    />
  );

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Kliknij lub przeciągnij tutaj zdjęcia</p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}
