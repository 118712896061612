import {
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import FlexBox from "../FlexBox";
import { Link } from "react-router-dom";
import {
  determineInspectionStatus,
  INSPECTION_FINISHED,
} from "clients/inspections";
import PublicInspectionPreview from "components/public-inspection-preview/PublicInspectionPreview";
import { Fragment, useEffect, useState } from "react";
import { getInspectionStepDetails } from "clients/inspection-results";

export const InspectionDetails = ({ inspection }) => {
  const [inspectionStepData, setInspectionStepData] = useState();

  useEffect(() => {
    if (inspection.status === INSPECTION_FINISHED) {
      getInspectionStepDetails(inspection.id).then((response) => {
        const [data] = response;
        setInspectionStepData(data);
      });
    }
  }, [inspection.id]);

  return (
    <Fragment>
      <Card>
        <FlexBox sx={{ flexDirection: "column", gap: "10px" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {inspection_wanted_attributes(inspection).map(
                  (elem) =>
                    elem[0] !== null && (
                      <TableRow
                        key={elem[1]}
                        sx={{
                          "&:first-of-type": { border: "none" },
                          borderTop: "2px solid",
                          borderColor: "grey.300",
                        }}
                      >
                        <TableCell
                          sx={{ fontWeight: "bold" }}
                        >{`${elem[1]}:`}</TableCell>
                        <TableCell align="right">{`${elem[0] ? elem[0] : ""} ${
                          elem[0] && elem[2] ? elem[2] : ""
                        }`}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {inspection.fk_inspector === null &&
            inspection.status !== INSPECTION_FINISHED && (
              <Link to={`/make-inspection/${inspection.id}`}>
                <Box p="10px" width="100%">
                  <Button fullWidth variant="outlined" color="primary">
                    Inspekcja
                  </Button>
                </Box>
              </Link>
            )}
        </FlexBox>
      </Card>
      {inspectionStepData && (
        <Card
          sx={{
            padding: 1,
            marginTop: 2,
          }}
        >
          <Typography mb={1} variant="h5" fontWeight={500}>
            Podgląd Inspekcji
          </Typography>
          <Divider />
          <PublicInspectionPreview inspection={inspectionStepData} />
        </Card>
      )}
    </Fragment>
  );
};

const inspection_wanted_attributes = (inspection) => {
  return [
    [inspection.scheduled_date.split("T")[0], "Data Inspekcji"],
    [inspection.is_public ? "TAK" : "NIE", "Inspekcja Opublikowana"],
    [determineInspectionStatus(inspection.status), "Status Inspekcji"],
    [inspection.price_inspection, "Cena Inspekcji", "PLN"],
    [inspection.price_transport, "Cena Dojazdu", "PLN"],
  ];
};
