import { sortProductImages } from "utils/utils";
import apiClient from "./apiClient";

export const getSearchProducts = async (payload, page = 1) => {
  let [data, validations, error, meta] = await apiClient.post(
    "/search-products?page=" + page,
    payload
  );

  if (data) {
    data = data.map((product) => {
      return sortProductImages(product);
    });
  }

  return [data, validations, error, meta];
};

export const getFilterRanges = async () => {
  const response = await apiClient.get("/filter-ranges");
  return response;
};

export const getFeaturedProducts = async () => {
  let [data, validations, error] = await apiClient.get(
    "/search-products/featured"
  );
  if (data) {
    data = data.map((product) => {
      return sortProductImages(product);
    });
  }

  return [data, validations, error];
};
