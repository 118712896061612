import { Card, Paper, Box, Typography, Button, Tooltip } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import { orderInspection } from "clients/inspections";
import navigationService from "utils/navigationService";
import { Link, createSearchParams } from "react-router-dom";
import { publishProduct } from "clients/products";
import { Fragment, useState } from "react";
import { LoadingButton } from "@mui/lab";
import ProductSettingsDialog from "./ProductSettingsDialog";
import AuctionDialog from "./AuctionDialog";

const ProductManagement = ({ product, setProduct }) => {
  const [loadingMap, setLoadingMap] = useState({});
  const [productSettingsDialogOpen, setProductSettingsDialogOpen] =
    useState(false);

  const [auctionDialogOpen, setAuctionDialogOpen] = useState(false);

  return (
    <Fragment>
      <Card
        sx={{
          flex: 1,
          padding: 0,
          minWidth: "300px",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: "10px",
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ConstructionIcon color="primary" />
          <Typography fontWeight="bold" color="gray.500">
            Akcje
          </Typography>
        </Paper>
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <LoadingButton
            variant="contained"
            color="primary"
            loading={!!loadingMap.inspection}
            onClick={async () => {
              setLoadingMap((loadingMap) => ({
                ...loadingMap,
                inspection: true,
              }));
              let response = await orderInspection({
                fk_product: product.id,
              });
              const [data] = response;
              setLoadingMap((loadingMap) => ({
                ...loadingMap,
                inspection: false,
              }));
              navigationService.navigation(`/make-inspection/${data.id}`);
            }}
            fullWidth
          >
            Zrób samodzielnie inspekcję podstawową
          </LoadingButton>
          <Link
            to={{
              pathname: "/inspections/order",
              search: `?${createSearchParams({
                product_slug: product.slug,
              })}`,
            }}
          >
            <Button variant="contained" color="primary" fullWidth>
              Zlecenie inspekcji rozszerzonej
            </Button>
          </Link>
          <Tooltip
            title={
              product?.is_publish ? "Ogłoszenie już jest opublikowane" : ""
            }
          >
            <span>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={!!loadingMap.publish}
                onClick={async () => {
                  setLoadingMap((loadingMap) => ({
                    ...loadingMap,
                    publish: true,
                  }));
                  let response = await publishProduct(product.id);
                  const [data] = response;
                  setLoadingMap((loadingMap) => ({
                    ...loadingMap,
                    publish: false,
                  }));
                  setProduct((product) => ({ ...product, ...data }));
                }}
                disabled={product?.is_publish ? true : false}
              >
                Publikuj
              </LoadingButton>
            </span>
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setProductSettingsDialogOpen(true);
            }}
            fullWidth
          >
            Ustawienia Oferty
          </Button>
          {!!product.is_publish && (
            <Button
              variant="contained"
              color="primary"
              disabled={!!product.auction}
              onClick={() => {
                setAuctionDialogOpen(true);
              }}
            >
              Utwórz aukcję pojazdu
            </Button>
          )}
        </Box>
      </Card>
      <ProductSettingsDialog
        open={productSettingsDialogOpen}
        onClose={() => {
          setProductSettingsDialogOpen(false);
        }}
        product={product}
        setProduct={setProduct}
      />
      <AuctionDialog
        open={auctionDialogOpen}
        onClose={(auction) => {
          if (auction) {
            setProduct((product) => ({
              ...product,
              auction: auction,
            }));
          }
          setAuctionDialogOpen(false);
        }}
        productId={product.id}
      />
    </Fragment>
  );
};
export default ProductManagement;
