import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBox from "components/FlexBox";
import { formatToHumanReadableNumber } from "utils/utils";
import OrderProductButton from "./OrderProductButton";

const ProductDetailsHeader = ({ product, setDialogOpen }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h5" fontWeight={700}>
          {product.year} {product.model.producer.name} {product.model.name}
        </Typography>
        <Typography fontWeight={600}>{product.mileage} km</Typography>
      </Box>
      <FlexBox>
        <Box>
          <Typography variant="h5" fontWeight={700} color="primary">
            {formatToHumanReadableNumber(
              product.offer?.price || product.attributes.price
            )}{" "}
            PLN
          </Typography>
          {product.offer && (
            <Typography
              variant="caption"
              sx={{
                position: "relative",
                top: -10,
              }}
            >
              Twoja cena
            </Typography>
          )}
        </Box>
        {!isMobile && (
          <Box ml={2}>
            <OrderProductButton
              setDialogOpen={setDialogOpen}
              product={product}
            />
          </Box>
        )}
      </FlexBox>
    </Box>
  );
};
export default ProductDetailsHeader;
