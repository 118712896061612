import { Fragment } from "react";
import { TableRow, TableCell, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { determineStatus } from "clients/products";

const ProductList = ({ products }) => {
  return (
    <Fragment>
      {products.map((product) => (
        <TableRow
          key={product.id}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            borderTop: "2px solid",
            borderColor: "grey.300",
          }}
        >
          <TableCell>{product.model.producer.name}</TableCell>
          <TableCell align="right">{product.model.name}</TableCell>
          <TableCell align="right">{product.year}</TableCell>
          <TableCell align="right">{`${product.mileage} km`}</TableCell>
          <TableCell align="right">
            {`${product.price || product.seller_price} PLN`}
          </TableCell>
          <TableCell align="right">{renderStatusMessage(product)}</TableCell>
          <TableCell align="right">
            <Link to={`/products/${product.id}`}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};
const renderStatusMessage = (product) => {
  const [status, color] = determineStatus(
    product.is_publish,
    product.status,
    product.is_blocked
  );
  return <Typography color={color}>{status}</Typography>;
};
export default ProductList;
