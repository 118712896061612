const navbarNavigations = [
  {
    title: "Home",
    list_1_title: "Home 1",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
      {
        title: "Super Store 2",
        href: "/superstore-shop2",
      },
      {
        title: "Furniture2",
        href: "/furniture-shop2",
      },
      {
        title: "Grocery-v12",
        href: "/grocery12",
      },
      {
        title: "Grocery-v22",
        href: "/grocery22",
      },
      {
        title: "Grocery-v32",
        href: "/grocery32",
      },
      {
        title: "Health and Beauty2",
        href: "/healthbeauty-shop2",
      },
      {
        title: "Fashion2",
        href: "/fashion-shop2",
      },
      {
        title: "Gift Store2",
        href: "/gift-shop2",
      },
      {
        title: "Gadget2",
        href: "/gadget-shop2",
      },
    ],
    list_2_title: "Lista 2",
    list_2: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
  {
    title: "Home2",
    list_1_title: "Home 2",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
  {
    title: "Home3",
    list_1_title: "Home 3",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
  {
    title: "Home4",
    list_1_title: "Home 4",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
    list_2_title: "Lista 2",
    list_2: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
  {
    title: "Home5",
    list_1_title: "Home 2",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
  {
    title: "Home6",
    list_1_title: "Home 3",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
  {
    title: "Home7",
    list_1_title: "Home 3",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
  {
    title: "Home8",
    list_1_title: "Home 3",
    list_1: [
      {
        title: "Super Store",
        href: "/superstore-shop",
      },
      {
        title: "Furniture",
        href: "/furniture-shop",
      },
      {
        title: "Grocery-v1",
        href: "/grocery1",
      },
      {
        title: "Grocery-v2",
        href: "/grocery2",
      },
      {
        title: "Grocery-v3",
        href: "/grocery3",
      },
      {
        title: "Health and Beauty",
        href: "/healthbeauty-shop",
      },
      {
        title: "Fashion",
        href: "/fashion-shop",
      },
      {
        title: "Gift Store",
        href: "/gift-shop",
      },
      {
        title: "Gadget",
        href: "/gadget-shop",
      },
    ],
  },
];
export default navbarNavigations;
