import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import cuid from "cuid";

const SpecificationTable = ({ definition }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {definition.columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {definition.value.map((elem) => (
            <TableRow key={cuid()}>
              {Object.values(elem).map((value) => (
                <TableCell key={cuid()}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default SpecificationTable;
