import CustomerDashboardLayout from "components/layout/CustomerDashboardLayout";
import MainProfileViewEditor from "components/profile/MainProfileViewEditor";
import React from "react";

const ProfileEditor = () => {
  return (
    <CustomerDashboardLayout>
      <MainProfileViewEditor />
    </CustomerDashboardLayout>
  );
};

export default ProfileEditor;
