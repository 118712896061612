import axiosClient from "./axiosClient";
import dispatchService from "utils/dispatchService";

class apiClientClass {
  flatten(data) {
    const flatten_attributes = (data) => {
      if (Array.isArray(data)) {
        data.forEach((elem) => {
          Object.entries(elem.attributes).forEach(([key, val]) => {
            // check for key conflict
            if (!Object.keys(data).includes(key)) {
              elem[key] = val;
            }
          });
          // if has relationships flatten relationships
          if (elem.hasOwnProperty("relationships")) {
            Object.entries(elem.relationships).forEach(
              ([relationshipKey, relationshipVal]) => {
                // check for key conflict
                if (!Object.keys(elem).includes(relationshipKey)) {
                  if (relationshipVal) {
                    elem[relationshipKey] = flatten_attributes(relationshipVal);
                  }
                }
              }
            );
          }
        });
      } else {
        Object.entries(data.attributes).forEach(([key, val]) => {
          // check for key conflict
          if (!Object.keys(data).includes(key)) {
            data[key] = val;
          }
        });
      }
      // if has relationships flatten relationships
      if (data.hasOwnProperty("relationships")) {
        Object.entries(data.relationships).forEach(([key, val]) => {
          // check for key conflict
          if (!Object.keys(data).includes(key)) {
            if (val) {
              data[key] = flatten_attributes(val);
            }
          }
        });
      }
      return data;
    };
    flatten_attributes(data);
    return data;
  }

  handleApiResponse(response) {
    if (response.hasOwnProperty("data")) {
      // Success
      return [this.flatten(response.data), null, null, response.meta || null];
    } else if (response.hasOwnProperty("validations")) {
      // Return Validations
      return [null, response.validations, null, null];
    } else if (response.hasOwnProperty("error")) {
      // Return Error
      // show error snackbar
      this.showSnackbar(response.error.title, "error");
      return [null, null, response.error, null];
    }
  }

  showSnackbar(text, type) {
    dispatchService.dispatch({
      type: "SHOW_SNACKBAR",
      payload: {
        text: text,
        type: type,
      },
    });
  }

  // --- Following code is esentially bindings for axios client with error handling ---
  async get(url, config = {}) {
    try {
      let response = await axiosClient.get(url, config);
      return this.handleApiResponse(response.data);
    } catch (err) {
      this.showSnackbar(err.message, "error");
      return [null, null, null, null];
    }
  }

  async post(url, data, config = {}) {
    try {
      let response = await axiosClient.post(url, JSON.stringify(data), config);
      return this.handleApiResponse(response.data);
    } catch (err) {
      this.showSnackbar(err.message, "error");
      return [null, null, null, null];
    }
  }

  async put(url, data, config = {}) {
    try {
      let response = await axiosClient.put(url, JSON.stringify(data), config);
      return this.handleApiResponse(response.data);
    } catch (err) {
      this.showSnackbar(err.message, "error");
      return [null, null, null, null];
    }
  }

  async patch(url, data, config = {}) {
    try {
      let response = await axiosClient.patch(url, JSON.stringify(data), config);
      return this.handleApiResponse(response.data);
    } catch (err) {
      this.showSnackbar(err.message, "error");
      return [null, null, null, null];
    }
  }

  async delete(url, config = {}) {
    try {
      let response = await axiosClient.delete(url, config);
      return this.handleApiResponse(response.data);
    } catch (err) {
      this.showSnackbar(err.message, "error");
      return [null, null, null, null];
    }
  }
}
const apiClient = new apiClientClass();
export default apiClient;
