import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Fragment } from "react";
import QuestionHandler from "./QuestionHandler";
import cuid from "cuid";
import { groupBy } from "utils/utils";

const PublicInspectionPreview = ({ inspection }) => {
  const inspection_confirmed_details = inspection.result;
  const { steps } = inspection;

  const steps_grouped = groupBy(steps, "group_name");

  const step_0_wanted_attributes = [
    ["VIN", inspection_confirmed_details.vin],
    ["Numer Rejestracyjny", inspection_confirmed_details.registration_number],
    [
      "Data pierwszej rejestracji",
      inspection_confirmed_details.first_registration_date,
    ],
    [
      "Pojazd Zadłużony",
      inspection_confirmed_details.is_pawned ? "Tak" : "Nie",
    ],
    [
      "Pojazd Kradziony",
      inspection_confirmed_details.is_stolen ? "Tak" : "Nie",
    ],
    [
      "Dane Potwierdzone",
      inspection_confirmed_details.is_confirmed ? "Tak" : "Nie",
    ],
  ];

  return (
    <Box>
      <Typography variant="h6">Dane Pojazdu</Typography>
      <TableContainer component={Box}>
        <Table sx={{ width: "unset !important" }}>
          <TableBody>
            {step_0_wanted_attributes.map((row) => (
              <TableRow
                key={row[0]}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row[0]}
                </TableCell>
                <TableCell align="left">
                  <Typography fontWeight="bold">{row[1]}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {Object.entries(steps_grouped).map(([key, val]) => (
        <Fragment key={key}>
          <Typography mt={2} variant="h6">
            {key}
          </Typography>
          <TableContainer component={Box}>
            <Table sx={{ width: "unset !important" }}>
              <TableBody>
                {val.map((question) => (
                  <QuestionHandler key={cuid()} question={question} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      ))}
    </Box>
  );
};
export default PublicInspectionPreview;
