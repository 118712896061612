import DashboardLayout from "components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import PaymentIcon from "@mui/icons-material/Payment";
import { useEffect, useState } from "react";
import { getCustomerPaymentList } from "clients/payments";
import PaymentsList from "components/payments/PaymentsList";

const PaymentsPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getCustomerPaymentList()
      .then((response) => {
        const [data] = response;
        if (data !== null) {
          setData(data);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        title="Moje płatności"
        icon={PaymentIcon}
        navigation={<CustomerDashboardNavigation />}
      />
      <PaymentsList payments={data} />
    </DashboardLayout>
  );
};

export default PaymentsPage;
