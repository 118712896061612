import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { InspectionDetails } from "../../components/inspections/InspectionDetails";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getInspectionDetails } from "clients/inspections";
import { Card, CircularProgress } from "@mui/material";
import { getInspectionStepDetails } from "clients/inspection-results";

const InspectionDetailsPage = () => {
  // get id from url
  const { id } = useParams();
  const [inspectionData, setInspectionData] = useState();

  useEffect(() => {
    if (id !== undefined) {
      getInspectionDetails(id).then((response) => {
        const [data] = response;
        setInspectionData(data);
      });
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        icon={FactCheckOutlinedIcon}
        title={"Widok inspekcji"}
        navigation={<CustomerDashboardNavigation />}
      />
      {!inspectionData && (
        <Card
          sx={{
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
      {inspectionData && <InspectionDetails inspection={inspectionData} />}
    </DashboardLayout>
  );
};

export default InspectionDetailsPage;
