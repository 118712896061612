import CustomerDashboardLayout from "components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import Favorite from "@mui/icons-material/Favorite";
import {
  Card,
  Dialog,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getFavoriteProducts } from "clients/favorite-products";
import ProductCard14 from "components/product-cards/ProductCard14";
import ProductCardSkeleton from "components/products/ProductCardSkeleton";
import { useAppContext } from "contexts/app/AppContext";
import { Button } from "@mui/material";
import { groupBy } from "utils/utils";
import { saveOffer } from "clients/offers";
import * as yup from "yup";
import { errorMessages } from "utils/constants";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import navigationService from "utils/navigationService";

const WishList = () => {
  const { state, dispatch } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [selectedProducts, setSelectedProducts] = useState({});
  const [showDialogForCompany, setShowDialogForCompany] = useState();

  const favouriteProducts = state?.user?.user?.attributes.favorite_products;

  const handleOnChange = (item, eventStatus) => {
    if (eventStatus) {
      setSelectedProducts((selectedProducts) => ({
        ...selectedProducts,
        [item.company_name]: [
          ...(selectedProducts[item.company_name] || []),
          item,
        ],
      }));
    } else {
      setSelectedProducts((selectedProducts) => ({
        ...selectedProducts,
        [item.company_name]: selectedProducts[item.company_name].filter(
          (elem) => elem.id !== item.id
        ),
      }));
    }
  };

  const formik = useFormik({
    initialValues: {
      price: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.fk_products = selectedProducts[showDialogForCompany]?.map(
        (elem) => elem.id
      );
      saveOffer(values).then((response) => {
        const [responseData] = response;
        if (responseData) {
          setShowDialogForCompany(null);
          formik.setFieldValue("price", "");
          formik.setFieldTouched("price", false);
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: { text: "Oferta została przesłana", type: "success" },
          });
          navigationService.navigation("/offers");
          formik.setSubmitting(false);
        }
      });
    },
  });

  // Fecth favourite products
  useEffect(() => {
    setIsLoading(true);
    setSelectedProducts({});
    getFavoriteProducts().then((response) => {
      const [responseData] = response;
      if (responseData) {
        setData(groupBy(responseData, "company_name"));
      }
      setIsLoading(false);
    });
  }, [favouriteProducts]);

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        title="Ulubione"
        icon={Favorite}
        navigation={<CustomerDashboardNavigation />}
      />
      <Grid>
        <Grid container spacing={3}>
          {isLoading &&
            [1, 2, 3].map((item) => (
              <Grid item lg={4} sm={6} xs={12} key={item.toString()}>
                <ProductCardSkeleton />
              </Grid>
            ))}
        </Grid>

        {!isLoading &&
          !!data &&
          Object.entries(data).map(([key, value]) => (
            <Grid item key={key.toString()}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  alignContent="space-between"
                  spacing={3}
                  alignItems="center"
                  mt={2}
                >
                  <Grid item>
                    <Typography sx={{ fontWeight: "bold" }} variant="h5">
                      {key}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {selectedProducts[key]?.length >= 2 && (
                      <Button
                        type="submit"
                        onClick={() => {
                          setShowDialogForCompany(key);
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Złóż ofertę hurtową
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3} key={key.toString()} mt={1}>
                {value.map((item) => {
                  return (
                    <Grid item lg={4} sm={6} xs={12} key={item.id.toString()}>
                      <ProductCard14
                        item={item}
                        showCheckBox={true}
                        handleOnChange={handleOnChange}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          ))}
      </Grid>

      {!isLoading && data?.length == 0 && (
        <Card
          sx={{
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <Typography color="grey.600" variant="h6">
            Nie masz żadnych ulubionych produktów. Możesz je dodać klikając
            ikonę serca.
          </Typography>
        </Card>
      )}

      {!!showDialogForCompany && (
        <Dialog
          open={!!showDialogForCompany}
          onClose={() => {
            setShowDialogForCompany(null);
            formik.setFieldValue("price", "");
            formik.setFieldTouched("price", false);
          }}
          sx={{ zIndex: 5000 }}
        >
          <Card
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField
              label="Aktualna suma cen wybranych pojazdów"
              value={
                calculateSumPriceFromArray(
                  selectedProducts[showDialogForCompany]
                ).toFixed(2) || ""
              }
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">PLN</InputAdornment>
                ),
              }}
            />
            <TextField
              name="price"
              label="Propozycja ceny"
              value={formik.values.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.price && !!formik.errors.price}
              helperText={!!formik.touched.price && formik.errors.price}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">PLN</InputAdornment>
                ),
              }}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
            >
              Złóż ofertę
            </LoadingButton>
          </Card>
        </Dialog>
      )}
    </CustomerDashboardLayout>
  );
};

const calculateSumPriceFromArray = (array) => {
  let sum = 0;
  array.forEach((elem) => {
    sum += elem.price;
  });
  return sum;
};

const validationSchema = yup.object({
  price: yup
    .number()
    .required(errorMessages.required)
    .min(0, errorMessages.greater_then_0)
    .typeError(errorMessages.numeric),
});

export default WishList;
