import { Card, Divider, TextField, MenuItem, Typography } from "@mui/material";
import FlexBox from "../FlexBox";
import { layoutConstant } from "../../utils/constants";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getAvilableInspectors,
  orderInspection,
} from "../../clients/inspections";
import * as yup from "yup";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect } from "react";
import { getProductDetails } from "../../clients/products";
import { useNavigate } from "react-router-dom";

// Make inspector menu items from inspectors list - fetched from api on every date change
const makeInspectorMenuItems = (inspectors) => {
  return inspectors.map((inspector) => (
    <MenuItem
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr);",
      }}
      key={`${inspector.type}${inspector.id}`}
      value={inspector.id.toString()}
    >
      <Typography
        sx={{ width: "100%" }}
      >{`${inspector.first_name} ${inspector.last_name}`}</Typography>
      <Typography
        sx={{ width: "100%" }}
      >{`Cena: ${inspector.price}`}</Typography>
      <Typography sx={{ width: "100%" }}>{`Cena dojazdu: ${
        inspector.price_km ? inspector.price_km : "Nie można określić"
      }`}</Typography>
    </MenuItem>
  ));
};

export const OrderInspection = (props) => {
  const { product_slug } = props;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingInspectors, setIsFetchingInspectors] = useState(true);
  const [avilableInspectors, setAvilableInspectors] = useState([]);
  const [productData, setProductData] = useState();

  // fetch product data by id passed in query params
  useEffect(() => {
    if (product_slug) {
      getProductDetails(product_slug).then((response) => {
        const [data] = response;
        setProductData(data);
      });
    }
  }, [product_slug]);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    values.fk_product = productData.id;
    values.price_inspection = values.price_inspection.toFixed(2);
    values.price_transport = values.price_transport.toFixed(2);

    const response = await orderInspection(values);
    const [data] = response;
    if (data) {
      navigate(`/inspections/${data.id}`);
    }
    setIsLoading(false);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema,
  });

  // On every date change fetch avilable inspectors
  const handleDateChange = (event) => {
    // Disable select field while fetching data
    setIsFetchingInspectors(true);
    getAvilableInspectors(event.target.value, productData.id).then(
      (response) => {
        const [inspectors] = response;
        // reset select field after date change
        setFieldValue("fk_inspector", "");
        setFieldValue("price_inspection", "");
        setFieldValue("price_transport", "");
        setAvilableInspectors(inspectors || []);
        setIsFetchingInspectors(false);
      }
    );
  };
  const makeDisplayPrice = () => {
    try {
      return (values.price_inspection + values.price_transport).toFixed(2);
    } catch {
      return "";
    }
  };

  // Show loading screen before loaded
  if (!productData) {
    return (
      <Card
        sx={{
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card
      sx={{
        top: layoutConstant.topbarHeight + 50,
      }}
    >
      <FlexBox
        sx={{
          padding: "20px",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <FlexBox
          sx={{
            flexDirection: "row",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            label="Produkt"
            variant="outlined"
            size="small"
            value={`${productData.model.producer.name} ${productData.model.name}`}
            onBlur={handleBlur}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              flex: 1,
              minWidth: "15ch",
            }}
          />
          <TextField
            label="VIN"
            variant="outlined"
            size="small"
            value={productData.vin}
            onBlur={handleBlur}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              flex: 1,
              minWidth: "10ch",
            }}
          />
        </FlexBox>
        <Divider />
        <TextField
          name="scheduled_date"
          label="Data inspekcji"
          onBlur={(e) => {
            e.target.type = "text";
            handleBlur(e);
          }}
          onFocus={(e) => {
            e.target.type = "date";
          }}
          value={values.scheduled_date || ""}
          error={!!touched.scheduled_date && !!errors.scheduled_date}
          helperText={touched.scheduled_date && errors.scheduled_date}
          onChange={(e) => {
            handleDateChange(e);
            handleChange(e);
          }}
          variant="outlined"
          size="small"
          placeholder=""
          InputProps={{
            inputProps: {
              min: new Date(Date.now() + 3600 * 1000 * 24)
                .toISOString()
                .split("T")[0],
            },
          }}
        />
        <TextField
          name="fk_inspector"
          label="Inspektor"
          variant="outlined"
          size="small"
          placeholder="Inspektor"
          value={values.fk_inspector || ""}
          onBlur={handleBlur}
          error={!!touched.fk_inspector && !!errors.fk_inspector}
          helperText={touched.fk_inspector && errors.fk_inspector}
          onChange={(e) => {
            handleChange(e);
            let inspector = avilableInspectors.find(
              (elem) => elem.id == e.target.value
            );
            setFieldValue("price_inspection", inspector.price);
            setFieldValue("price_transport", inspector.price_km);
          }}
          select
          disabled={isFetchingInspectors}
        >
          {makeInspectorMenuItems(avilableInspectors)}
        </TextField>
        <TextField
          name="price_inspection"
          label="Cena"
          variant="outlined"
          size="small"
          value={makeDisplayPrice()}
          onBlur={handleBlur}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
        />
        <LoadingButton
          size="normal"
          color="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isFetchingInspectors}
          loadingPosition="center"
          variant="contained"
        >
          Zleć inspekcję
        </LoadingButton>
      </FlexBox>
    </Card>
  );
};
const initialValues = {
  fk_product: "",
  fk_inspector: "",
  scheduled_date: "",
  price_inspection: "",
  price_transport: "",
};

const formSchema = yup.object().shape({
  fk_inspector: yup.number().required("Wybranie inpektora jest wymagane"),
  scheduled_date: yup.date().required("Data inspekcji jest wymagana"),
  price_inspection: yup.number().required("Cena jest wymagana"),
});
