import DashboardLayout from "../../components/layout/CustomerDashboardLayout";
import CustomerDashboardNavigation from "../../components/layout/CustomerDashboardNavigation";
import DashboardPageHeader from "../../components/layout/DashboardPageHeader";
import ShoppingBag from "@mui/icons-material/ShoppingBag";
import { Card, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getOrderDetails } from "../../clients/orders";
import { useParams } from "react-router-dom";

import OrderDetails from "components/orders/OrderDetails";

const OrderDetailsPage = () => {
  const [orderData, setOrderData] = useState();
  const { id } = useParams();

  // Get Product by id from url param
  useEffect(() => {
    if (id !== undefined) {
      getOrderDetails(id).then((response) => {
        const [data] = response;
        setOrderData(data);
      });
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardPageHeader
        title="Szczegóły zamówienia"
        icon={ShoppingBag}
        navigation={<CustomerDashboardNavigation />}
      />
      {!orderData && (
        <Card
          sx={{
            minHeight: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
      {!!orderData && <OrderDetails orderData={orderData} />}
    </DashboardLayout>
  );
};

export default OrderDetailsPage;
