import React, { Fragment, useRef } from "react";
// import useDrag and useDrop hooks from react-dnd
import { useDrag, useDrop } from "react-dnd";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppContext } from "../../contexts/app/AppContext";
import { deleteProductImage } from "clients/products";

const type = "Image"; // Need to pass which type element can be draggable, its a simple string or Symbol. This is like an Unique ID so that the library know what type of element is dragged or dropped on.

// Styles for img, and dropzone
const imageStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
};

const ImageList = ({
  images,
  moveImage,
  setImages,
  onDragEnd,
  makeImageOverride = null,
}) => {
  const { dispatch } = useAppContext();

  const Image = ({ image, index }) => {
    const ref = useRef(null); // Initialize the reference

    // useDrop hook is responsible for handling whether any item gets hovered or dropped on the element
    const [, drop] = useDrop({
      // accept receives a definition of what must be the type of the dragged item to be droppable
      accept: type,
      // This method is called when we hover over an element while dragging
      hover(item) {
        // item is the dragged element
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        // current element where the dragged element is hovered on
        const hoverIndex = index;
        // If the dragged element is hovered in the same place, then do nothing
        if (dragIndex === hoverIndex) {
          return;
        }
        // If it is dragged around other elements, then move the image and set the state with position changes
        moveImage(dragIndex, hoverIndex);
        /*
              Update the index for dragged item directly to avoid flickering
              when the image was half dragged into the next
            */
        item.index = hoverIndex;
      },

      drop() {
        onDragEnd(images);
      },
    });

    // useDrag will be responsible for making an element draggable. It also expose, isDragging method to add any styles while dragging
    const [{ isDragging }, drag] = useDrag(() => ({
      // what type of item this to determine if a drop target accepts it
      type: type,
      // data of the item to be available to the drop methods
      item: { id: image.id, index },
      // method to collect additional data for drop handling like whether is currently being dragged
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }));

    /* 
          Initialize drag and drop into the element using its reference.
          Here we initialize both drag and drop on the same element (i.e., Image component)
        */
    drag(drop(ref));

    // Add the reference to the element
    return makeImageOverride
      ? makeImageOverride(image, isDragging, ref)
      : makeImage(image, isDragging, ref);
  };

  // Function for making image component with delte button
  const makeImage = (image_element, isDragging, ref) => {
    return (
      <Box
        className="file-item"
        ref={ref}
        key={image_element.id?.toString()}
        sx={{
          position: "relative",
          opacity: isDragging ? 0 : 1,
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            transform: "translate(40%, -40%)",
            backgroundColor: "grey.400",
          }}
          color="primary"
          onClick={createImageDeleteFunction(image_element)}
        >
          <DeleteIcon />
        </IconButton>
        <Box
          sx={{
            width: "200px",
            height: "170px",
            borderRadius: "10px",
            border: "solid 1px",
            borderColor: "grey.400",
            padding: "10px",
          }}
        >
          <img
            alt={`img - ${image_element.id}`}
            className="file-img"
            style={imageStyle}
            src={image_element.s}
          />
        </Box>
      </Box>
    );
  };

  // returns a function to delete an image and send a delete request
  const createImageDeleteFunction = (image_element) => {
    return () => {
      // disallow deleteing all photos
      if (images.length > 1) {
        setImages((images) =>
          images.filter(
            (elem) => JSON.stringify(elem) !== JSON.stringify(image_element)
          )
        );
        deleteProductImage(image_element.id);
      } else {
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: {
            text: "Produkt musi posiadać co najmniej 1 zdjęcie",
            type: "error",
          },
        });
      }
    };
  };

  const renderImage = (image, index) => {
    return image ? (
      <Image
        image={image}
        index={index}
        key={`${image.id}-image`}
        moveImage={moveImage}
      />
    ) : null;
  };
  return <Fragment>{images.map(renderImage)}</Fragment>;
};

export default ImageList;
