import SalePage1 from "pages/sale-page-1";
import Error404 from "pages/404";
import { Routes, Route, useLocation } from "react-router-dom";
import LoadingWrapper from "components/LoadingWrapper";
import LoginPage from "pages/login";
import SignUpPage from "pages/signup";
import ProfilPage from "pages/profile/index";
import AddressList from "pages/address/index";
import { useNavigate } from "react-router-dom";
import navigationService from "utils/navigationService";
import dispatchService from "utils/dispatchService";
import { useEffect } from "react";
import LogoutPage from "pages/logout";
import { useAppContext } from "contexts/app/AppContext";
import AddressEdit from "pages/address/edit";
import AddressAdd from "pages/address/add";
import ProfileEditor from "pages/profile/edit";
import CarsCatalogPage from "pages/cars";
import EmailVerificationPage from "pages/email-verification/token";
import AddProductPage from "pages/products/add";
import ProductsListPage from "pages/products";
import WishList from "pages/wish-list";
import ProductDetailPage from "pages/car/[slug]";
import OffersPage from "pages/offers";
import InspectorAvailabilitiesPage from "pages/inspector-availabilities";
import InspectionsPage from "pages/inspections";
import OrderInspectionPage from "pages/inspections/order";
import InspectionDetailsPage from "pages/inspections/[id]";
import MakeInspection from "pages/make-inspection/[id]";
import InspectorInspectionsPage from "pages/inspector-inspections";
import InspectorInspectionsHistory from "pages/inspector-inspections/history";
import WelcomePage from "pages/auth/WelcomePage";
import SavingsPage from "pages/savings/index";
import SavingsPrintPage from "pages/savings/print";
import OrdersPage from "pages/orders";
import OrderDetailsPage from "pages/orders/[id]";
import ProductPage from "pages/products/[id]";
import AboutPages from "pages/about/[page]";
import PaymentsPage from "pages/payments";
import PaymentsDetailPage from "pages/payments/[id]";

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useAppContext();

  useEffect(() => {
    navigationService.navigation = navigate;
    navigationService.location = location;
    dispatchService.dispatch = dispatch;
    window.scrollTo({ top: 0 });
  }, [navigate, location]);

  return <Routes>{makeRoutes(routes)}</Routes>;
};
const makeRoutes = (routes_list = []) => {
  return routes_list.map((route, idx) => (
    <Route key={idx.toString()} path={route.path} element={route.element}>
      {makeRoutes(route.children)}
    </Route>
  ));
};

const routes = [
  {
    path: "/",
    element: <SalePage1 />,
    children: [],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  {
    path: "/auth/welcome",
    element: <WelcomePage />,
  },
  {
    path: "/profile",
    element: <ProfilPage />,
  },
  {
    path: "/profile/edit",
    element: <ProfileEditor />,
  },
  {
    path: "/address",
    element: <AddressList />,
  },
  {
    path: "/payments",
    element: <PaymentsPage />,
  },
  {
    path: "/payments/:id",
    element: <PaymentsDetailPage />,
  },
  {
    path: "/address/:id",
    element: <AddressEdit />,
  },
  {
    path: "/address/add",
    element: <AddressAdd />,
  },
  {
    path: "/email-verification/:token",
    element: <EmailVerificationPage />,
  },
  {
    path: "/cars",
    children: [
      {
        path: "",
        element: <CarsCatalogPage />,
      },
      {
        path: ":paramProducer",
        element: <CarsCatalogPage />,
      },
    ],
  },
  {
    path: "/products",
    children: [
      {
        path: "",
        element: <ProductsListPage />,
      },
      {
        path: "add",
        element: <AddProductPage />,
      },
      {
        path: ":id",
        element: <ProductPage />,
      },
    ],
  },
  {
    path: "/wish-list",
    element: <WishList />,
  },
  {
    path: "/car/:slug",
    element: <ProductDetailPage />,
  },
  {
    path: "/offers",
    element: <OffersPage />,
  },
  {
    path: "/inspector-availabilities",
    element: <InspectorAvailabilitiesPage />,
  },
  {
    path: "/inspections",
    children: [
      {
        path: "",
        element: <InspectionsPage />,
      },
      {
        path: "order",
        element: <OrderInspectionPage />,
      },
      {
        path: ":id",
        element: <InspectionDetailsPage />,
      },
    ],
  },
  {
    path: "/make-inspection/:id",
    element: <MakeInspection />,
  },
  {
    path: "/inspector-inspections",
    children: [
      {
        path: "",
        element: <InspectorInspectionsPage />,
      },
      {
        path: "history",
        element: <InspectorInspectionsHistory />,
      },
    ],
  },
  {
    path: "/savings",
    children: [
      {
        path: "",
        element: <SavingsPage />,
      },
      {
        path: "print",
        element: <SavingsPrintPage />,
      },
    ],
  },
  {
    path: "/orders",
    children: [
      {
        path: "",
        element: <OrdersPage />,
      },
      {
        path: ":id",
        element: <OrderDetailsPage />,
      },
    ],
  },
  {
    path: "/about/:page",
    element: <AboutPages />,
  },
  { path: "*", element: <Error404 /> },
];
export default Router;
