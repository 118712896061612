import Card1 from "components/Card1";
import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { saveCompanyAddress } from "../../clients/company-addresses";
import { useAppContext } from "../../contexts/app/AppContext";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Checkbox } from "@mui/material";
import { errorMessages } from "utils/constants";
import { useEffect } from "react";
import { useState } from "react";
import { getCountriesList } from "clients/countries";

const AddressEditor = ({ values, onCloseHandler }) => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const [contriesList, setCountriesList] = useState([]);

  useEffect(() => {
    getCountriesList().then((response) => {
      const [data] = response;
      setCountriesList(data || []);
    });
  }, []);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required(errorMessages.required),
    address1: yup.string().required(errorMessages.required),
    post_code: yup.string().required(errorMessages.required),
    place: yup.string().required(errorMessages.required),
    country: yup
      .string()
      .required(errorMessages.required)
      .typeError(errorMessages.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      saveCompanyAddress(values, values.id)
        .then((response) => {
          const [data, validations, errors] = response;

          if (validations !== null) {
            validations.forEach((error) => {
              formik.setFieldError(error.source, error.title);
            });
          }

          if (data !== null) {
            dispatch({
              type: "SHOW_SNACKBAR",
              payload: { text: "Adres został zapisany", type: "success" },
            });
            // Allow to overrride default redirect
            if (onCloseHandler === undefined) {
              navigate("/address");
            } else {
              onCloseHandler();
            }
          }

          formik.setSubmitting(false);
        })
        .catch((error) => {
          console.warn(error);
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: { text: "Wystąpił błąd", type: "error" },
          });
          //.catch((error) => console.error(error));
        });
    },
  });

  return (
    <Card1>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Grid item md={6} xs={12}>
            Adres główny:
            <Checkbox
              name="is_main"
              size="small"
              color="secondary"
              onBlur={formik.handleBlur}
              checked={!!formik.values.is_main}
              disabled={!!values.is_main}
              onChange={(e, new_value) => {
                formik.setFieldValue("is_main", new_value);
              }}
            />
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                name="name"
                label="Nazwa adresu"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name || ""}
                error={!!formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                name="address1"
                label="Ulica i nr"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address1 || ""}
                error={!!formik.touched.address1 && !!formik.errors.address1}
                helperText={formik.touched.address1 && formik.errors.address1}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="address2"
                label="Dodatkowe pole adresu"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address2 || ""}
                error={!!formik.touched.address2 && !!formik.errors.address2}
                helperText={formik.touched.address2 && formik.errors.address2}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="post_code"
                label="Kod pocztowy"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.post_code || ""}
                error={!!formik.touched.post_code && !!formik.errors.post_code}
                helperText={formik.touched.post_code && formik.errors.post_code}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="place"
                label="Miejscowość"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.place || ""}
                error={!!formik.touched.place && !!formik.errors.place}
                helperText={formik.touched.place && formik.errors.place}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                name="country"
                label="Kraj"
                value={formik.values.country || null}
                options={contriesList.map((country) => country.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Kraj"
                    error={!!formik.touched.country && !!formik.errors.country}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(e, new_value) => {
                  formik.setFieldValue("country", new_value);
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
        >
          Zapisz
        </Button>
      </form>
    </Card1>
  );
};
export default AddressEditor;
