import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  SVGOverlay,
  CircleMarker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import cuid from "cuid";

const icon = L.icon({
  iconUrl: "/assets/images/leaflet/images/marker-icon.png",
  iconSize: [25, 41], // size of the icon
  iconAnchor: [13, 41],
});

const lineOptionsGray = {
  color: "#2880ca",
};

const TransportMap = ({ markers, paths, currentPosition }) => {
  return (
    <MapContainer
      style={{ height: "400px" }}
      center={markers[0].position}
      zoom={6}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {markers.map((marker) => (
        <Marker key={cuid()} position={marker.position} icon={icon}>
          <Popup>{marker.label}</Popup>
        </Marker>
      ))}

      {paths.map((path) => (
        <Polyline key={cuid()} positions={path} pathOptions={lineOptionsGray} />
      ))}
      {!!currentPosition && (
        <CircleMarker center={currentPosition} radius={15}>
          <Popup>Pozycja Transportu</Popup>
        </CircleMarker>
      )}
    </MapContainer>
  );
};
export default TransportMap;
