import FlexBox from "../FlexBox";
import BazarCard from "components/BazarCard";
import { H3 } from "components/Typography";
import { useAppContext } from "contexts/app/AppContext";
import { Box, styled, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import FavouriteBox from "./FavouriteBox";
import useLoaded from "utils/useLoaded";
import CheckBoxOffer from "./CheckBoxOffer";
import { formatToHumanReadableNumber } from "utils/utils";
import IsEcoChip from "./IsEcoChip";

const StyledBazarCard = styled(BazarCard)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  margin: "auto",
  overflow: "hidden",
  transition: "all 250ms ease-in-out",
  borderRadius: "10px 10px 10px 10px",
  "&:hover": {
    boxShadow: theme.shadows[2],
    "& .css-1i2n18j": {
      display: "flex",
    },
    "& .controlBox": {
      display: "block",
    },
  },
}));
const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));
const ImageBox = styled(Box)(({ theme }) => ({
  padding: 0,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  "& img": {
    borderRadius: "10px 10px 0 0",
  },
}));
const ContentWrapper = styled(Box)(() => ({
  padding: "1rem",
  "& .title, & .categories": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
const ButtonBox = styled(FlexBox)(({ theme }) => ({
  marginTop: "15px",
  justifyContent: "space-between",
  "& button": {
    color: "#fff",
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary[400],
    },
  },
}));

const ProductCard14 = (props) => {
  const { item, showCheckBox, handleOnChange } = props;

  const { state } = useAppContext();
  const isLoaded = useLoaded();

  const img_url = item.attributes?.images[0]?.m || "/assets/images/img_404.png";

  return (
    <StyledBazarCard>
      <ImageWrapper>
        <ImageBox>
          <Link to={`/car/${item.attributes.slug}`}>
            <img
              src={img_url}
              style={{
                height: "270px",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Link>
        </ImageBox>
      </ImageWrapper>

      <Box pl={2} pr={2} pt={1}>
        {item.hasOwnProperty("is_eco") && (
          <IsEcoChip is_eco={item.is_eco} sx={{}} />
        )}
      </Box>

      <ContentWrapper>
        <Box flex="1 1 0" minWidth="0px" mr={1}>
          <Link to={"/car/" + item.attributes.slug}>
            <H3
              className="title"
              fontSize="14px"
              textAlign="left"
              fontWeight="600"
              color="text.secondary"
              mb={1}
              title={item.attributes.producer_name}
            >
              {item.attributes.producer_name} / {item.attributes.model_name}
            </H3>

            <H3
              className="subtitle1"
              fontSize="13px"
              textAlign="left"
              fontWeight="100"
              color="text.secondary"
              mb={1}
            >
              {item.attributes.year} rok / {item.attributes.mileage}km
            </H3>
          </Link>

          <FlexBox alignItems="center" mt={0.5}>
            <Box pr={1} fontWeight="600" color="primary.main">
              {formatToHumanReadableNumber(item.attributes.price)} PLN
            </Box>
          </FlexBox>
        </Box>

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <ButtonBox>
              {isLoaded && state.user?.user && (
                <FavouriteBox product_id={item.id} />
              )}
            </ButtonBox>
          </Grid>
          <Grid item>
            <ButtonBox>
              {showCheckBox && (
                <CheckBoxOffer item={item} handleOnChange={handleOnChange} />
              )}
            </ButtonBox>
          </Grid>
        </Grid>
      </ContentWrapper>
    </StyledBazarCard>
  );
};

export default ProductCard14;
