import FlexBox from "components/FlexBox";
import AppLayout from "components/layout/AppLayout";
import Navbar from "components/navbar/Navbar";
import { useTheme } from "@mui/system";
import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getPageContentWithFallback } from "clients/cms";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Card, CardContent, Stack } from "@mui/material";

const WelcomePage = () => {
  const { palette } = useTheme();

  const [pagecontent, set_pagecontent] = React.useState();

  const navigate = useNavigate();

  // Get page content from page api
  useEffect(() => {
    getPageContentWithFallback("welcome")
      .then((response) => {
        const [data] = response;
        set_pagecontent(data);
      })
      .catch((error) => {
        set_pagecontent(error);
        console.error(error);
      });
  }, []);
  if (pagecontent == null || pagecontent instanceof Error) {
    return (
      <AppLayout navbar={<Navbar />}>
        <FlexBox
          flexDirection="column"
          minHeight="40vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </FlexBox>
      </AppLayout>
    );
  }

  // Encase signup flexbox in SaleLayout template
  return (
    <AppLayout navbar={<Navbar />}>
      <FlexBox flexDirection="column" minHeight="40vh" alignItems="center">
        <Card
          sx={{
            marginTop: 5,
          }}
        >
          <CardContent>
            <h2 style={{ color: palette.primary[700] }}>{pagecontent.title}</h2>
            <Typography variant="body1" gutterBottom>
              {pagecontent.content}
            </Typography>
            <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate("/");
                }}
              >
                Strona Główna
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Zaloguj się
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </FlexBox>
      ;
    </AppLayout>
  );
};
export default WelcomePage;
