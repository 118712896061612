export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};
export const layoutConstant = {
  topbarHeight: 40,
  grocerySidenavWidth: 280,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 80,
  mobileHeaderHeight: 64,
};

export const errorMessages = {
  required: "Pole wymagane",
  numeric: "Proszę podać wartość numeryczną",
  invalid_email: "Niepoprawny adres email",
  greater_then_0: "Wartość musi być większa bądź równa niż 0",
  min_2_weeks_ahead: "Data nie może być wcześniejsza niż 2 tygodnie od dzisiaj",
  min_tomorrow: "Data nie może być wcześniejsza niż teraz",
  invalid_date: "Niepoprawna data",
  auction_min: "Licytowana cena nie może być niższa niż akutalna cena",
};

export const contact_mail = "kontakt@carteon.eu";
export const contact_phone = "609709642";
