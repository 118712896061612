import AppLayout from "components/layout/AppLayout";
import Logout from "components/sessions/Logout";

const LogoutPage = () => {
  return (
    <AppLayout>
      <Logout />
    </AppLayout>
  );
};
export default LogoutPage;
