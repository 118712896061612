/* eslint-disable react-hooks/exhaustive-deps */
import BazarCard from "../BazarCard";
import { Fragment, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { H3, H6 } from "../Typography";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from "@mui/icons-material/Done";
import FlexBox from "../FlexBox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { saveOffer } from "../../clients/offers";
import { useAppContext } from "../../contexts/app/AppContext";
import SendIcon from "@mui/icons-material/Send";
import { errorMessages } from "utils/constants";
import ProductAuction from "./ProductAuction";
import { formatToHumanReadableNumber } from "utils/utils";

const StyledBazarCard = styled(BazarCard)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  margin: "auto",
  padding: "10px",
  overflow: "hidden",
  transition: "all 250ms ease-in-out",
  borderRadius: "10px 10px 10px 10px",
  "&:hover": {
    boxShadow: theme.shadows[2],
    "& .css-1i2n18j": {
      display: "flex",
    },
    "& .controlBox": {
      display: "block",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ProductOfferBox = ({ product, pageContent }) => {
  const { state, dispatch } = useAppContext();
  const { user } = state;
  const [isOfferSent, setIsOfferSent] = useState(false);

  const isInspector = user?.user?.claim?.includes("inspector");

  const offerSchema = yup.object().shape({
    price: yup
      .number()
      .required(errorMessages.required)
      .typeError(errorMessages.numeric),
  });

  const offerFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fk_product: product.id,
      price: null,
    },
    validationSchema: offerSchema,
    onSubmit: (values) => {
      saveOffer(values).then((response) => {
        const [data, validations, error] = response;

        if (validations) {
          validations.forEach((validation) => {
            offerFormik.setFieldError(validation.source, validation.title);
          });
        }

        if (data) {
          setIsOfferSent(true);
        }

        offerFormik.setSubmitting(false);
      });
    },
  });

  const make_card_content = () => {
    switch (product.status) {
      case 0: {
        if (user?.user) {
          return (
            <Box>
              {!isOfferSent && (
                <FlexBox alignItems="center" mb={1}>
                  <form
                    onSubmit={offerFormik.handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <FormControl sx={{ mb: 1.5 }} variant="outlined">
                      <TextField
                        type="text"
                        label="Twoja propozycja ceny"
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              PLN
                            </InputAdornment>
                          ),
                        }}
                        name="price"
                        onBlur={offerFormik.handleBlur}
                        onChange={offerFormik.handleChange}
                        value={offerFormik.values.price || ""}
                        error={
                          !!offerFormik.touched.price &&
                          !!offerFormik.errors.price
                        }
                        helperText={
                          offerFormik.touched.price && offerFormik.errors.price
                        }
                      />
                    </FormControl>
                    <LoadingButton
                      loading={offerFormik.isSubmitting}
                      loadingPosition="start"
                      startIcon={<SendIcon />}
                      type="submit"
                      variant="outlined"
                      color="primary"
                    >
                      Wyślij propozycje do sprzedającego
                    </LoadingButton>
                  </form>
                </FlexBox>
              )}

              {isOfferSent && (
                <FlexBox alignItems="center" mb={1} maxWidth={300}>
                  <DoneIcon fontSize="large" color="primary" />
                  <Box ml={1}>
                    <H6 color="grey.700" mb={0.5} sx={{ fontWeight: "normal" }}>
                      {pageContent?.offer_sent}
                    </H6>
                  </Box>
                </FlexBox>
              )}
            </Box>
          );
        } else {
          return (
            <Typography textAlign="center" variant="h6" color="grey.600">
              {pageContent.login_to_see_more}
            </Typography>
          );
        }
      }
      case 1: {
        return (
          <Typography textAlign="center" variant="h4" color="warning.main">
            Zarezerwowane
          </Typography>
        );
      }
      case 2: {
        return (
          <Typography textAlign="center" variant="h4" color="error.800">
            Sprzedane
          </Typography>
        );
      }
    }
  };

  return (
    <StyledBazarCard>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <H6 color="primary.400" mb={0.5} lineHeight="1">
            Aktualna cena
          </H6>
          <H3 color="primary.main" mb={0.5} lineHeight="1">
            {formatToHumanReadableNumber(product.price)} PLN
          </H3>
          <H6
            color="grey.700"
            mb={2}
            sx={{ fontWeight: "normal" }}
            lineHeight="1"
          >
            {pageContent?.price_info}
          </H6>
        </Box>
      </Box>

      <FlexBox alignItems="center" mb={1}>
        <InfoIcon fontSize="large" color="secondary" />
        <Box ml={1} maxWidth={300}>
          <H6 color="grey.700" mb={0.5} sx={{ fontWeight: "normal" }}>
            {pageContent?.price_offer_info}
          </H6>
        </Box>
      </FlexBox>

      {!isInspector && !isOwnProduct(user?.user, product) && (
        <Divider sx={{ my: "0.5rem" }} />
      )}

      {!isInspector && !isOwnProduct(user?.user, product) && (
        <Fragment>
          {make_card_content()}
          {!!product.auction && !!user.user && (
            <ProductAuction product={product} />
          )}
        </Fragment>
      )}
    </StyledBazarCard>
  );
};

const isOwnProduct = (user, product) => {
  return user?.id == product?.fk_customer_seller;
};

export default ProductOfferBox;
