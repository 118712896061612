import { differenceInMinutes } from "date-fns";
import ceil from "lodash/ceil";
export const getDateDifference = (date) => {
  let diff = differenceInMinutes(new Date(), new Date(date));
  if (diff < 60) return diff + " minutes ago";
  diff = ceil(diff / 60);
  if (diff < 24) return `${diff} hour${diff === 0 ? "" : "s"} ago`;
  diff = ceil(diff / 24);
  if (diff < 30) return `${diff} day${diff === 0 ? "" : "s"} ago`;
  diff = ceil(diff / 30);
  if (diff < 12) return `${diff} month${diff === 0 ? "" : "s"} ago`;
  diff = diff / 12;
  return `${diff.toFixed(1)} year${ceil(diff) === 0 ? "" : "s"} ago`;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const sortProductImages = (product) => {
  const sortFunction = (a, b) => {
    if (a.image_order < b.image_order) {
      return -1;
    } else if (a.image_order > b.image_order) {
      return 1;
    }
    return 0;
  };
  product.attributes.images = product.attributes.images.sort(sortFunction);
  product.images = product.attributes.images.sort(sortFunction);
  return product;
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const formatToHumanReadableNumber = (number) => {
  // in case of any error just return the number
  try {
    return new Intl.NumberFormat("pl-PL", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
  } catch {
    return number;
  }
};
