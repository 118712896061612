import { Typography, Card, Button } from "@mui/material";
import FlexBox from "components/FlexBox";
import { useAppContext } from "contexts/app/AppContext";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";

const Logout = () => {
  const { state, dispatch } = useAppContext();
  const { user } = state;

  const handleLogout = () => {
    if (user.user != null) {
      dispatch({
        type: "LOGOUT_USER",
        payload: {},
      });
      Cookies.remove("userAuth");
      window.localStorage.removeItem("user");
    }
  };
  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <FlexBox
      sx={{
        minHeight: "40vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          padding: "10px",
        }}
      >
        <Typography variant="h4">Zostałeś poprawnie wylogowany</Typography>
        <Link to="/">
          <Button
            sx={{ marginTop: "20px" }}
            color="primary"
            variant="outlined"
            fullWidth
          >
            Strona Główna
          </Button>
        </Link>
      </Card>
    </FlexBox>
  );
};
export default Logout;
