import { Box, Button, Typography } from "@mui/material";
import { Fragment } from "react";
import navigationService from "utils/navigationService";

const NavbarColumn = ({ title = null, links }) => {
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 3,
        }}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{
            padding: "6px 8px",
            visibility: title ? "visible" : "hidden",
          }}
        >
          {title || "placeholder"}
        </Typography>
        {links.map((link) => (
          <Button
            key={link.title}
            sx={{
              justifyContent: "flex-start",
            }}
            onClick={() => {
              navigationService.navigation(link.href);
            }}
          >
            {link.title}
          </Button>
        ))}
      </Box>
    </Fragment>
  );
};
export default NavbarColumn;
