import { Typography } from "@mui/material";

const SpecificationHeader = ({ definition }) => {
  return (
    <Typography variant="h6" fontWeight={600}>
      {definition.value}
    </Typography>
  );
};
export default SpecificationHeader;
