import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { H5 } from "../Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getVehicleHistory } from "../../clients/products";

const ProductVehicleHistory = ({ slug }) => {
  const [vehicleHistory, setVehicleHistory] = useState();

  useEffect(() => {
    getVehicleHistory(slug).then((response) => {
      const [data] = response;
      setVehicleHistory(data);
    });
  }, [slug]);

  const makeTextList = (data) => {
    return data.map(
      ([key, val], idx) =>
        val && (
          <Box key={idx.toString()}>
            <Typography component="span">
              {key}:{" "}
              <Typography component="span" fontWeight="bold">
                {val}
              </Typography>
            </Typography>
          </Box>
        )
    );
  };

  const makeVechicleDataList = () => {
    return makeTextList(Object.entries(vehicleHistory.vehicle_history.Pojazd));
  };

  const makeTechnicalDataList = () => {
    return makeTextList(
      Object.entries(
        vehicleHistory.attributes.vehicle_history["Dane techniczne"]
      )
    );
  };

  const time = () => {
    return Object.entries(
      vehicleHistory.attributes.vehicle_history["Oś czasu"]
    ).map(
      ([date, date_value], idx) =>
        date_value && (
          <Fragment key={idx.toString()}>
            <H5 mt={1}>{date}</H5>
            {makeTextList(Object.entries(date_value))}
          </Fragment>
        )
    );
  };

  function makeHistoryAccoridons() {
    return (
      <Fragment>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="vechice-history"
          >
            <Typography>Pojazd</Typography>
          </AccordionSummary>
          <AccordionDetails>{makeVechicleDataList()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="vechice-history-specification"
          >
            <Typography>Dane techniczne</Typography>
          </AccordionSummary>
          <AccordionDetails>{makeTechnicalDataList()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="vechice-history-time"
          >
            <Typography>Oś czasu</Typography>
          </AccordionSummary>
          <AccordionDetails>{time()}</AccordionDetails>
        </Accordion>
      </Fragment>
    );
  }

  if (!vehicleHistory) {
    return (
      <Box>
        <Skeleton variant="rectangular" height="40px" />
      </Box>
    );
  }

  if (!vehicleHistory.attributes.vehicle_history) {
    return (
      <Box>
        <Typography>Brak danych dla pojazdu</Typography>
      </Box>
    );
  }

  return <Box>{makeHistoryAccoridons()}</Box>;
};
export default ProductVehicleHistory;
