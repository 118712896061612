import { sortProductImages } from "utils/utils";
import apiClient from "./apiClient";
import axiosClient from "./axiosClient";

export const getProducts = async () => {
  return await apiClient.get("products");
};

export const getSingleProduct = async (id) => {
  return await apiClient.get("/products/" + id);
};

export const getProductDirectories = async () => {
  return await apiClient.get("/product-directories");
};

export const createProduct = async (data) => {
  return await apiClient.post("/products", data);
};

export const addProductImage = async (data) => {
  return await apiClient.post("/product-images", data);
};

export const updateProductPhotosOrder = async (data) => {
  return await apiClient.patch("/product-images", data);
};

export const deleteProductImage = async (id) => {
  return await axiosClient.delete(`/product-images/${id}`);
};

export const getProductDetails = async (slug) => {
  let [data, validations, error] = await apiClient.get(
    `detail-products/${slug}`
  );
  if (data) {
    data = sortProductImages(data);
  }
  return [data, validations, error];
};

export const getVehicleHistory = async (slug) => {
  return await apiClient.get("/vehicle-histories/" + slug);
};

export const changePrice = async (idProduct, price) => {
  return await apiClient.put(`/products/${idProduct}/change-price`, {
    price: price,
  });
};

export const publishProduct = async (id) => {
  let [data, validations, error] = await apiClient.put(
    `/products/${id}/publish`
  );
  if (data) {
    data = sortProductImages(data);
  }
  return [data, validations, error];
};

export const unpublishProduct = async (id) => {
  let [data, validations, error] = await apiClient.put(
    `/products/${id}/unpublish`
  );
  if (data) {
    data = sortProductImages(data);
  }
  return [data, validations, error];
};

export const deleteProduct = async (id) => {
  return await apiClient.delete(`/products/${id}`);
};

export const getProductPledge = async (id) => {
  return await apiClient.get(`vehicle-pledges/${id}`);
};

export const determineStatus = (is_publish, status, is_blocked) => {
  if (is_blocked) {
    return ["zablokowana", "error"];
  }
  switch (is_publish) {
    case 0:
      return ["nieaktywna", "warning.main"];
    case 1:
      switch (status) {
        case 0:
          return ["aktywna", "success.800"];
        case 1:
          return ["zarezerwowana", "text"];
        case 2:
          return ["sprzedana", "text"];
        case 5:
          return ["niedostepna", "error"];
      }
  }
  return ["Błąd", "error"];
};
