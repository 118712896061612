import { Card, Box, Paper, Typography, Button } from "@mui/material";
import Carousel from "../carousel/Carousel";
import ImageIcon from "@mui/icons-material/Image";
import cuid from "cuid";
import FlexBox from "components/FlexBox";
import { Fragment, useState } from "react";
import { PhotoEditorDialog } from "./PhotoEditorDialog";

const ProductImagesManagement = ({ product, setImages }) => {
  const [editPhotosDialogOpen, setEditPhotosDialogOpen] = useState(false);

  const handleEditPhotosDialogClose = (new_images) => {
    if (new_images) {
      setImages(new_images);
    }
    setEditPhotosDialogOpen(false);
  };

  return (
    <Fragment>
      <Card
        sx={{
          flex: 1,
          padding: 0,
          minWidth: "300px",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: "10px",
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlexBox alignItems="center">
            <ImageIcon color="primary" />
            <Typography fontWeight="bold" color="gray.500">
              Zdjęcia
            </Typography>
          </FlexBox>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditPhotosDialogOpen(true)}
          >
            Edycja
          </Button>
        </Paper>
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Carousel
            naturalSlideWidth={800}
            naturalSlideHeight={600}
            orientation="horizontal"
            visibleSlides={1}
            totalSlides={product.images.length}
          >
            {product.images.map((elem) => {
              return (
                <Box key={cuid()}>
                  <img width="100%" src={elem.l} />
                </Box>
              );
            })}
          </Carousel>
        </Box>
      </Card>
      <PhotoEditorDialog
        product={product}
        open={editPhotosDialogOpen}
        onClose={handleEditPhotosDialogClose}
      />
    </Fragment>
  );
};
export default ProductImagesManagement;
