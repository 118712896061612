import Close from "@mui/icons-material/Close";
import { Dialog, IconButton, Typography, Button, Box } from "@mui/material";
import FlexBox from "components/FlexBox";
import { useNavigate } from "react-router-dom";

const StartUnpaidInspectionDialog = ({ open, onClose, IdInspection }) => {
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={onClose}>
      <FlexBox>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </FlexBox>
      <Box sx={{ padding: 2 }}>
        <Typography>
          Ta inspekcja nie została jeszcze opłacona, czy na pewno chcesz
          rozpocząć inspekcję?
        </Typography>
        <Button
          sx={{ mt: 2 }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(`/make-inspection/${IdInspection}`);
          }}
        >
          Rozpocznij Inspekcję
        </Button>
      </Box>
    </Dialog>
  );
};
export default StartUnpaidInspectionDialog;
