import { Box, Card, Typography, Button, Chip } from "@mui/material";
import FlexBox from "components/FlexBox";
import SimplifiedProductCard from "./SimplifiedProductCard";
import { saveOffer } from "clients/offers";
import { formatToHumanReadableNumber } from "utils/utils";

const OfferCard = ({ offer, setOffers, showAcceptDecineButtons = false }) => {
  const acceptOffer = async () => {
    const response = await saveOffer({ status: 2 }, offer.id);
    const [data] = response;
    setOffers((offers) =>
      offers.map((offer_g) => {
        if (offer_g.id === data.id) {
          return {
            ...offer,
            ...data,
          };
        }
        return offer_g;
      })
    );
  };
  const rejectOffer = async () => {
    const response = await saveOffer({ status: 1 }, offer.id);
    const [data] = response;
    setOffers((offers) =>
      offers.map((offer_g) => {
        if (offer_g.id === data.id) {
          return {
            ...offer,
            ...data,
          };
        }
        return offer_g;
      })
    );
  };
  return (
    <Card
      sx={{
        padding: 1,
      }}
    >
      <Box>
        <FlexBox flexWrap="wrap" justifyContent="space-between">
          <Typography component="span">
            oferta:{" "}
            <Typography component="span" fontWeight={600}>
              #{offer.id}
            </Typography>
          </Typography>
          <Chip
            size="small"
            label={getStatusLabel(offer.status)}
            sx={{
              p: "0.25rem 0.5rem",
              fontSize: 12,
              color: !!getStatusColor(offer.status)
                ? `${getStatusColor(offer.status)}.900`
                : "inherit",
              backgroundColor: !!getStatusColor(offer.status)
                ? `${getStatusColor(offer.status)}.100`
                : "none",
            }}
          />
        </FlexBox>

        <Typography sx={{ marginTop: 2 }}>
          Produkty których dotyczy oferta:
        </Typography>
        <FlexBox flexWrap="wrap" gap={1} mt={1}>
          {offer.products.map((product) => (
            <SimplifiedProductCard
              key={product.id.toString()}
              product={product}
            />
          ))}
        </FlexBox>

        <Box mt={2}>
          <Box>
            <Typography component="span">
              Aktualna cena:{" "}
              <Typography fontWeight={500} component="span">
                {formatToHumanReadableNumber(
                  offer.products
                    .map((product) => product.price)
                    .reduce((partial, elem) => partial + elem)
                )}{" "}
                PLN
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography component="span">
              Proponowana cena:{" "}
              <Typography fontWeight={500} component="span">
                {formatToHumanReadableNumber(offer.price)} PLN
              </Typography>
            </Typography>
          </Box>

          {offer.status === 0 && showAcceptDecineButtons && (
            <FlexBox mt={1} gap={1}>
              <Button
                variant="outlined"
                color="success"
                onClick={acceptOffer}
                sx={{ flex: 1 }}
              >
                Akceptuj
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={rejectOffer}
                sx={{ flex: 1 }}
              >
                Odrzuć
              </Button>
            </FlexBox>
          )}
        </Box>
      </Box>
    </Card>
  );
};
const getStatusColor = (status) => {
  switch (status) {
    case 0:
      return "secondary";
    case 1:
      return "error";
    case 2:
      return "success";
    case 3:
      return "warning";
    case 4:
      return "success";
    case 5:
      return "success";

    default:
      return "";
  }
};

const getStatusLabel = (status) => {
  switch (status) {
    case 0:
      return "W oczekiwaniu";
    case 1:
      return "Oferta odrzucona";
    case 2:
      return "Oferta zaakceptowana";
    case 3:
      return "Nieaktualna";
    case 4:
      return "Zamówienie";
    case 5:
      return "Zakończone";
    default:
      return "";
  }
};
export default OfferCard;
