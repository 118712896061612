export const pageApiFallbacks = {
  signup: {
    title: "Rejestracja",
    registration_mesage: "",
  },
  welcome: {
    title: "Dziękujemy za rejestrację w naszym serwisie",
    content:
      "Twoje dane zostaną przez nas zweryfikowane. Jeśli weryfikacja zakończy sie sukcesem wówczas zostanie aktywowany i otrzymasz informacje mailem.",
  },
  logout: {
    title: "Zostałeś poprawnie wylogowany/a",
    content: "Dziękujemy za korzystanie z usług NAZWA FIRMY",
    timer: "5",
  },
  "product-details": {
    price_info: "cena netto bez transportu",
    price_offer_info:
      "Ta cena jest zatwierdzona przez sprzedajacego i pozwala na szybki zakup",
    offer_sent: "Oferta została wysłana. Czekaj na decyzje sprzedającego.",
    offers_count: "Tą ofertą jest zainteresowanych $count użytkowników",
    login_to_see_more: "Zaloguj się aby zobaczyć więcej",
    order_inspection: "Dla tego produnktu nie ma zleconej inspekcji",
  },
};
