import { getOffers } from "clients/offers";
import { useEffect, useState } from "react";
import DashboardPageHeader from "components/layout/DashboardPageHeader";
import CustomerDashboardNavigation from "components/layout/CustomerDashboardNavigation";
import CustomerDashboardLayout from "components/layout/CustomerDashboardLayout";
import PercentIcon from "@mui/icons-material/Percent";
import OffersLayout from "components/offers/OffersLayout";
import { CircularProgress, Card } from "@mui/material";

const OffersPage = () => {
  const [offersData, setOffersData] = useState();

  useEffect(() => {
    getOffers().then((response) => {
      const [data] = response;
      setOffersData(data);
    });
  }, []);

  return (
    <CustomerDashboardLayout>
      <DashboardPageHeader
        title="Oferty"
        icon={PercentIcon}
        navigation={<CustomerDashboardNavigation />}
      />
      {!!offersData && (
        <OffersLayout offers={offersData} setOffers={setOffersData} />
      )}
      {!offersData && (
        <Card
          sx={{
            minHeight: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Card>
      )}
    </CustomerDashboardLayout>
  );
};
export default OffersPage;
