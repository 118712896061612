import FavoriteIconFilled from "@mui/icons-material/Favorite";
import {
  addFavouriteProduct,
  removeFavouriteProduct,
} from "clients/favorite-products";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import { Button, styled } from "@mui/material";
import { useAppContext } from "../../contexts/app/AppContext";
import { useState, useEffect } from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  background: theme.palette.primary.main,
  "&:hover": {
    background: theme.palette.primary[400],
  },
}));

const FavouriteBox = ({ product_id }) => {
  const { state, dispatch } = useAppContext();

  const favouriteProducts = state.user.user.attributes.favorite_products;

  const determineFavourite = () => {
    return favouriteProducts.includes(product_id);
  };

  const [isFavourite, setIsFavourite] = useState(determineFavourite());

  useEffect(() => {
    setIsFavourite(determineFavourite());
  }, [favouriteProducts]);

  const handleFavouriteClick = async () => {
    setIsFavourite((isFavourite) => !isFavourite);
    if (isFavourite) {
      // todo handle favourite items add / remove
      let response = await removeFavouriteProduct(product_id);
      dispatch({
        type: "SET_FAVOURITES",
        payload: response[0].favorite_products,
      });
      const items = JSON.parse(localStorage.getItem("user"));
      items.attributes.favorite_products = response[0].favorite_products;
      items.favorite_products = response[0].favorite_products;
      const newItems = JSON.stringify(items);
      localStorage.setItem("user", newItems);
    } else {
      let response = await addFavouriteProduct(product_id);
      dispatch({
        type: "SET_FAVOURITES",
        payload: response[0].favorite_products,
      });
      const items = JSON.parse(localStorage.getItem("user"));
      items.attributes.favorite_products = response[0].favorite_products;
      items.favorite_products = response[0].favorite_products;
      const newItems = JSON.stringify(items);
      localStorage.setItem("user", newItems);
    }
  };

  return (
    <StyledButton
      variant="outlined"
      sx={{ p: "6px 8px" }}
      onClick={handleFavouriteClick}
    >
      {isFavourite ? (
        <FavoriteIconFilled sx={{ fontSize: "16px" }} />
      ) : (
        <FavoriteIcon sx={{ fontSize: "16px" }} />
      )}
    </StyledButton>
  );
};
export default FavouriteBox;
