import {
  Box,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
} from "@mui/material";
import { getNotification } from "clients/notifications";
import React, { useState, useEffect, useCallback } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NotificationPopup = ({ idMessage, status_open }) => {
  const [dataDetailNotification, setDataDetailNotification] = useState([]);
  const [open, setOpen] = useState(status_open);

  const handleClose = () => {
    setOpen(false);
  };

  const notification = useCallback(() => {
    getNotification(idMessage)
      .then((response) => {
        const [data] = response;
        if (data !== null) {
          setDataDetailNotification(data);
        }
      })
      .catch((error) => console.error(error));
  }, [idMessage]);

  useEffect(() => {
    notification();
  }, [notification]);

  if (dataDetailNotification.data !== undefined) {
    return (
      <div data-backdrop="false">
        {dataDetailNotification ? (
          <Backdrop
            open={open}
            onClick={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Powiadomienie
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {dataDetailNotification.data.message}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {dataDetailNotification.data.detail}
              </Typography>
              <Button onClick={handleClose}>Zamknij</Button>
            </Box>
          </Backdrop>
        ) : (
          <Box
            width="380px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    );
  }
};

export default NotificationPopup;
