import { Box, Card, IconButton, TextField, Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import { useState } from "react";
import InspectorCalendar from "./InspectorCalendar";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const InspectorAvailabilitiesForm = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Card
        sx={{
          padding: 1,
        }}
      >
        <FlexBox justifyContent="center" alignItems="center" flexWrap="wrap">
          <Box>
            <IconButton
              onClick={() => {
                setYear((year) => year - 1);
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <TextField
              name="year"
              value={year}
              inputProps={{
                readOnly: true,
                style: {
                  width: "4ch",
                },
              }}
            />
            <IconButton
              onClick={() => {
                setYear((year) => year + 1);
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setMonth((month) => {
                  if (month > 0) {
                    return month - 1;
                  }
                  return month;
                });
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <TextField
              name="month"
              value={months_labels[month]}
              inputProps={{
                readOnly: true,
                style: {
                  width: "11ch",
                  textAlign: "center",
                },
              }}
            />
            <IconButton
              onClick={() => {
                setMonth((month) => {
                  if (month < 11) {
                    return month + 1;
                  }
                  return month;
                });
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </FlexBox>
      </Card>
      <InspectorCalendar year={year} month={month} />
      <FlexBox flexDirection="column" gap="5px">
        <FlexBox>
          <Box
            sx={{
              width: "3ch",
              border: "2px solid",
              borderColor: "grey.500",
              backgroundColor: "success.200",
            }}
          ></Box>
          <Typography ml="1ch">- Oznaczone jako dostępny</Typography>
        </FlexBox>
        <FlexBox>
          <Box
            sx={{
              width: "3ch",
              border: "2px solid",
              borderColor: "grey.500",
              backgroundColor: "secondary.200",
            }}
          ></Box>
          <Typography ml="1ch">- Zlecona inspekcja na ten dzień</Typography>
        </FlexBox>
      </FlexBox>
    </Box>
  );
};
const months_labels = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];
export default InspectorAvailabilitiesForm;
