import CustomerDashboardLayout from "components/layout/CustomerDashboardLayout";
import React from "react";
import ShowProfile from "components/profile/ShowProfile";

const ProfilPage = () => {
  return (
    <CustomerDashboardLayout>
      <ShowProfile />
    </CustomerDashboardLayout>
  );
};

export default ProfilPage;
